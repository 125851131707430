import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Menu} from "@headlessui/react";
import {ArrowLeftOnRectangleIcon, FolderArrowDownIcon} from "@heroicons/react/24/outline";
import SwatchIcon from "@heroicons/react/24/outline/SwatchIcon";
import {checkPerm, classNames, openInNewTab} from "../../util/util-helpers";
import {logoutClear} from "../../../data/actions/user";
import {clearCookies} from "../../util/util-auth";
import LocalStorage from "../../../util/localStorage";
import {
    AcademicCapIcon,
    BuildingOfficeIcon,
    ClipboardDocumentCheckIcon,
    Cog6ToothIcon,
    CursorArrowRaysIcon,
    DevicePhoneMobileIcon,
    DocumentDuplicateIcon,
    InformationCircleIcon,
    LifebuoyIcon,
    LockClosedIcon,
    PuzzlePieceIcon,
    TicketIcon,
    UserCircleIcon
} from "@heroicons/react/20/solid";
import {showGlobalModal} from "../../../data/actions/ui";
import {READ_PERM} from "../../../util/util-constants";
import Resources from "../../../data/services/resources";
import {checkFeatureFlag} from "../feature-flags";
import Button from "../button";
import userNavigation from "../../../assets/menus/menu-user.json"
import Env from "../../../util/env";

export default function UserMenu({setIsThemeDialogOpen}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    // const ui = useSelector((state) => state.ui);

    const userIcons = {
        SwatchIcon: SwatchIcon,
        ArrowLeftOnRectangleIcon: ArrowLeftOnRectangleIcon,
        UserCircleIcon: UserCircleIcon,
        BuildingOfficeIcon: BuildingOfficeIcon,
        Cog6ToothIcon: Cog6ToothIcon,
        ClipboardDocumentCheckIcon: ClipboardDocumentCheckIcon,
        DevicePhoneMobileIcon: DevicePhoneMobileIcon,
        DocumentDuplicateIcon: DocumentDuplicateIcon,
        FolderArrowDownIcon: FolderArrowDownIcon,
        InformationCircleIcon: InformationCircleIcon,
        AcademicCapIcon: AcademicCapIcon,
        LifebuoyIcon: LifebuoyIcon,
        TicketIcon: TicketIcon,
        LockClosedIcon: LockClosedIcon,
        PuzzlePieceIcon: PuzzlePieceIcon,
        CursorArrowRaysIcon: CursorArrowRaysIcon
    };


    function handleUserMenuClick(item) {
        if ("Themes" === item.name) {
            setIsThemeDialogOpen(true);
        } else if (item.url) {
            history.push(item.url)
        } else if (item.support) {
           openInNewTab(Env.getSupportUrl("/"))
        }
        else if (item.dispatch) {
            dispatch(showGlobalModal(item.dispatch, item?.dispatchData ?? {}));
        } else if ("Logout" === item.name) {
            setIsLoggingOut(true);
        }
    }

    function handleMiddleButtonClick(item) {
        if ("Themes" === item.name) {
            setIsThemeDialogOpen(true);
        } else if (item.url) {
            openInNewTab(item.url)
        } else if (item.dispatch) {
            dispatch(showGlobalModal(item.dispatch, item?.dispatchData ?? {}));
        } else if ("Logout" === item.name) {
            setIsLoggingOut(true);
        }
    }

    useEffect(() => {
        if (isLoggingOut) {
            LocalStorage.remove('user');
            LocalStorage.remove('lookup');
            dispatch(logoutClear());
            history.push('/login');
            clearCookies();
        }
    }, [isLoggingOut, history, dispatch])

    return (
        <>
            {userNavigation.filter(it => it.isVisible !== false).map((item) => {
                const Icon = userIcons[item.icon];
                const hasPerm = !item?.perm?.length
                    ? true
                    : !!(item?.perm ?? []).find(perm => {
                        return Resources[perm]
                            ? checkPerm(Resources[perm], READ_PERM)
                            : false
                    });

                const isAuthorized = !item?.authorizedFlags?.length
                    ? true
                    : (item?.authorizedFlags ?? []).find(flag => {
                        return checkFeatureFlag(flag);
                    });

                if (!isAuthorized) {
                    return false;
                }

                return (
                    <Menu.Item key={item.name}>
                        {({active}) => {
                            return (
                                <Button
                                    onClick={() => handleUserMenuClick(item)}
                                    onMouseDown={(e) => {
                                        if (e.button === 1) {
                                            handleMiddleButtonClick(item)
                                        }
                                    }}
                                    hasPerm={hasPerm !== false}
                                    disabledIconClass={hasPerm === false ? "w-4 h-4 text-red-500 ml-auto" : undefined}
                                    className={classNames(
                                        active ? 'bg-tm-gray-100' : '',
                                        hasPerm === false ? 'w-full bg-tm-gray-50' : "hover:text-primary",
                                        'w-full block py-2 px-4 text-sm text-tm-gray-900 bg'
                                    )}
                                >
                                    <div
                                        className="flex items-center">
                                        {!!item.icon && (
                                            <Icon
                                                className="h-5 w-5 mr-2 text-tm-gray-700 opacity-75"
                                                aria-hidden="true"/>
                                        )}

                                        {item.name}
                                    </div>
                                </Button>
                            )
                        }}
                    </Menu.Item>
                )
            })
            }
        </>
    )
}