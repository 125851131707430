import React, {useEffect, useState} from "react";
import MagnifyingGlassIcon from "@heroicons/react/20/solid/MagnifyingGlassIcon";
import {FieldClearIcon} from "../../../../data/themes/icons";
import {classNames} from "../../../util/util-helpers";
import {scrollIntoView} from "../../../util/util-vanilla";

export default function FieldSearch({
                                        id,
                                        innerRef,
                                        type,
                                        onChange,
                                        readOnly,
                                        autoFocus,
                                        onBlur,
                                        onFocus,
                                        onKeyDown,
                                        name,
                                        value = "",
                                        placeholder,
                                        disabled,
                                        errorMessage,
                                        min,
                                        max,
                                        step,
                                        autocomplete,
                                        addClass,
                                        translate,
                                        icon
                                    }) {
    const [inputValue, setInputValue] = useState(value);
    const hasIcon = icon !== false;

    const submitChange = () => onChange(name, inputValue);

    const onSearchChange = (event) => {
        setInputValue(event.target.value);
    }

    useEffect(() => {
        if (!value && !!inputValue) {
            setInputValue("");
        } else if (value && !inputValue) {
            setInputValue(value);
        }
    }, [value])

    useEffect(() => {
        let debounceTimeout;
        if (inputValue !== undefined && value !== inputValue) { // prevent submitting on initial load
            debounceTimeout = setTimeout(submitChange, 300);
        }

        if (inputValue === undefined && value) {
            setInputValue(value)
        }

        return () => {
            clearTimeout(debounceTimeout);
        }
    }, [inputValue])

    const input = (
        <input
            id={id}
            ref={innerRef}
            type={type}
            readOnly={readOnly}
            autoFocus={autoFocus}
            onChange={onSearchChange}
            onBlur={onBlur}
            onFocus={(event) => {
                if (onFocus) {
                    onFocus(event);
                }

                scrollIntoView(event);
            }}
            onKeyDown={onKeyDown}
            name={name}
            value={inputValue}
            placeholder={placeholder}
            disabled={disabled}
            className={classNames("py-1", hasIcon ? "pl-9" : undefined, addClass, (errorMessage ? " is-invalid" : ""))}
            min={min}
            maxLength={max}
            step={step}
            autoComplete={autocomplete}
        />);

    return (
        <React.Fragment>
            <div className="relative">
                {hasIcon && (
                    <div
                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MagnifyingGlassIcon className="w-5 h-5 text-tm-gray-400 z-10"/>
                    </div>
                )}

                {!!value && (
                    <button
                        onClick={() => onChange(name, "")}
                        className="absolute z-10 inset-y-0 right-0 px-3 flex items-center cursor-pointer text-tm-gray-900 hover:text-tm-gray-400">
                        <FieldClearIcon/>
                    </button>
                )}

                {input}
            </div>

            {errorMessage && (
                <div className={"text-red-600 font-semibold"}>
                    {!!translate && (
                        errorMessage.reduce((memo, it) => {
                            return translate(it) + (memo ? "," + memo : "")
                        }, "")
                    )}
                    {!translate && (
                        <span>Field required*</span>
                    )}
                </div>
            )}
        </React.Fragment>

    )
}
