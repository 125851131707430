import {useEffect} from 'react';
import {useSelector} from "react-redux";
import {getGrabTaskResource} from "../../../../data/actions/taskResource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {getJWT, getUser, isTokenExpired} from "../../../util/util-auth";
import {useLocation} from "react-router-dom";

const TaskGrabData = ({
                          dispatch
                      }) => {
    const user = useSelector((state) => state.user)
    const ui = useSelector((state) => state.ui)
    const taskResource = useSelector((state) => state.taskResource)
    const location = useLocation();
    const isPublicRoute = checkIsPublicRoute(location);

    const fetchData = () => {
        dispatch(getGrabTaskResource({
            user: LocalStorage.get("user"),
            resource: Resources.DashboardTasks,
            request: 'GET'
        }))
    }

    function checkIsPublicRoute(location) {
        if (!location?.pathname) {
            return false;
        }

        const pathSegments = location.pathname.split("/");

        return pathSegments.includes("public");
    }

    useEffect(() => {
        if (!isPublicRoute && !!getJWT().access_token && ui?.grabUpdate) {
            fetchData()
        }
    }, [ui?.grabUpdate])

    useEffect(() => {
        // Fetch data after creating task from global dialog
        if (!isPublicRoute && !!getJWT().access_token && taskResource.create?.id) {
            fetchData();
        }
    }, [taskResource.create])

    useEffect(() => {
        if (!isPublicRoute && user.hasUserData) {
            fetchData();
        }
    }, [user.hasUserData])

    useEffect(() => {
        const token = getJWT().access_token;
        if (!isPublicRoute && !!token && token === getUser('access_token') && !isTokenExpired(token)) {
            fetchData();
        }
    }, [])

    return null
}

export default TaskGrabData;
