import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fieldsToHtml, fillFieldsFromData} from '../../../../util/util-fields';
import {Field, FieldsManager} from '../../../../../data/services/fields'
import Resources from '../../../../../data/services/resources'
import {getLookup} from '../../../../util/util-helpers'
import DialogTabs from "../../../tabs-navigation/dialog-tabs";
import {CurrencyDollarIcon, InformationCircleIcon, UsersIcon} from '@heroicons/react/24/outline'
import LocalStorage from '../../../../../util/localStorage'
import {createResource, updateResource} from '../../../../../data/actions/resource'
import {
    ACCOUNTING_DOCUMENT_ADDRESS,
    ACCOUNTING_DOCUMENT_LOGO,
    ACCOUNTING_DOCUMENT_NAME,
    COUNTRY_ID_USA,
    DISPATCH_DOCUMENT_ADDRESS,
    DISPATCH_DOCUMENT_LOGO,
    DISPATCH_DOCUMENT_NAME
} from "../../../../../util/util-constants";
import {getCountryCodes} from "../../../../util/countryCodes";
import ModalFooter from "../../../modal/modal-footer";
import ModalHeader from "../../../modal/modal-header";

class CreateOfficeDialog extends Component {

    constructor (props) {
        super(props)

        const tabs = [
            {
                id: 1,
                name: 'Info',
                resource: 'Info',
                icon: InformationCircleIcon,
                visible: true,
                invalid: false,
                current: true
            },
            {
                id: 2,
                name: 'Divisions',
                resource: 'Divisions',
                icon: UsersIcon,
                visible: true,
                invalid: false,
            },
            {
                id: 3,
                name: 'Accounting',
                resource: 'Accounting',
                icon: CurrencyDollarIcon,
                visible: true,
                invalid: false,
            },
        ]
        this.state = {
            InfoFields: this.getFields(),
            DispatchFields: this.getDispatchFields(),
            AccountingFields: this.getAccountingFields(),
            canSubmit: false,
            selectedTab: 'Info',
            tabs: tabs
        }
    }

    handleInputChange = (name, value, state) => {
        const fields = FieldsManager.updateField(this.state[state], name, value)
        if (name === "CountryID") {
            if (Number(value) === COUNTRY_ID_USA) {
                fields.StateID.type = 'select'
            } else {
                fields.StateID.type = 'hidden'
                fields.StateID.value = ''
            }
        }
        if (name === 'IsPaymentOverrideActive') {
            fields.AccountName.disabled = !value
            fields.AccountNumber.disabled = !value
            fields.RoutingNumber.disabled = !value
            fields.BankName.disabled = !value
        }
        this.setState({ canSubmit: true, [state]: fields })
    }

    submit = (event) => {
        event && event.preventDefault()

        this.setState({
            InfoFields: FieldsManager.validateFields(this.state.InfoFields),
            DispatchFields: FieldsManager.validateFields(this.state.DispatchFields),
            AccountingFields: FieldsManager.validateFields(this.state.AccountingFields),
        }, () => {

            if (!FieldsManager.checkFieldsForErrors(this.state.InfoFields)) {
                this.handleTabChange('Info')
            } else if (!FieldsManager.checkFieldsForErrors(this.state.DispatchFields)) {
                this.handleTabChange('Divisions')
            } else if (!FieldsManager.checkFieldsForErrors(this.state.AccountingFields)) {
                this.handleTabChange('Accounting')
            } else {
                if (this.props.selectedItem) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({},
                            FieldsManager.getFieldKeyValues(this.state.InfoFields),
                            FieldsManager.getFieldKeyValues(this.state.DispatchFields),
                            FieldsManager.getFieldKeyValues(this.state.AccountingFields),
                            {
                                OfficeID: this.props.selectedItem.OfficeID
                            }),
                        resource: Resources.Offices,
                        piggyResource: Resources.Offices,
                        query: this.props.query,
                        errorMessage: true, successMessage: `Office updated successfully!`,
                    }))
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({},
                            FieldsManager.getFieldKeyValues(this.state.InfoFields),
                            FieldsManager.getFieldKeyValues(this.state.DispatchFields),
                            FieldsManager.getFieldKeyValues(this.state.AccountingFields),
                        ),
                        resource: Resources.Offices,
                        piggyResource: Resources.Offices,
                        query: this.props.query,
                        errorMessage: true, successMessage: `Office created successfully!`,
                    }))
                }

                if (!!this.props.setDialogState) {
                    this.props.setDialogState(
                        Object.assign({},
                            this.props.selectedItem,
                            FieldsManager.getFieldKeyValues(this.state.InfoFields),
                            FieldsManager.getFieldKeyValues(this.state.DispatchFields),
                            FieldsManager.getFieldKeyValues(this.state.AccountingFields),
                        ))
                }
                this.hideModal()
            }
        })
    }

    getFields = () => {
        const fieldTemplates = {
            CountryID: new Field('CountryID', '1', ['empty'], false, 'select'),
            GooglePlaces: new Field('GooglePlaces', '', [], false, 'google-locations', {
                setLocations: this.setLocations,
                addContainerClass: 'col-span-2'
            }),
            AddressName: new Field('AddressName', '', ['empty'], false, 'text', { addContainerClass: 'col-span-full' }),
            AddressName2: new Field('AddressName2', '', [], false, 'text', { addContainerClass: 'col-span-full' }),
            CityName: new Field('CityName', '', ['empty']),
            StateID: new Field('StateID', '', ['empty'], false, 'select'),
            PostalCode: new Field('PostalCode', '', ['empty']),
            OfficeName: new Field('OfficeName', this.props.prefilled ?? '', ['empty'], false, 'text', { addContainerClass: 'col-span-full' }),
            OfficeNumber: new Field('OfficeNumber', '', ['empty'], false, 'text', { addContainerClass: 'col-span-full' }),
            AreaCode: new Field('AreaCode', '', [''], false, 'select', {}, {isClearable: true}),
            PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {}, {
                showMask: true,
                mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
            }),
            PhoneExtension: new Field('PhoneExtension', '', ['']),
            Notes: new Field('Notes', '', [''], false, 'textarea', { addContainerClass: 'col-span-full' }),
        }

        return this.props.selectedItem ? fillFieldsFromData(fieldTemplates, this.props.selectedItem) : fieldTemplates
    }

    getDispatchFields = () => {
        const fieldTemplates = {
            DivisionID: new Field('DivisionID', '', ['empty'], false, 'select-search', { addContainerClass: 'col-span-full' }),
            DispatchDocumentName: new Field('DispatchDocumentName', 1, ['empty'], false, 'select'),
            DispatchDocumentAddress: new Field('DispatchDocumentAddress', 1, ['empty'], false, 'select'),
            DispatchDocumentLogo: new Field('DispatchDocumentLogo', 1, ['empty'], false, 'select'),
            AccountingDocumentName: new Field('AccountingDocumentName', 1, ['empty'], false, 'select'),
            AccountingDocumentAddress: new Field('AccountingDocumentAddress', 1, ['empty'], false, 'select'),
            AccountingDocumentLogo: new Field('AccountingDocumentLogo', 1, ['empty'], false, 'select'),
        }
        return this.props.selectedItem ? fillFieldsFromData(fieldTemplates, this.props.selectedItem) : fieldTemplates
    }

    getAccountingFields = () => {
        const IsPaymentOverrideActive = !!this.props.selectedItem?.IsPaymentOverrideActive
        const fieldTemplates = {
            IsPaymentOverrideActive: new Field('IsPaymentOverrideActive', '', [], false, 'checkbox'),
            AccountName: new Field('AccountName', '', [], !IsPaymentOverrideActive, 'text'),
            AccountNumber: new Field('AccountNumber', '', [], !IsPaymentOverrideActive, 'text'),
            RoutingNumber: new Field('RoutingNumber', '', [], !IsPaymentOverrideActive, 'text'),
            BankName: new Field('BankName', '', [], !IsPaymentOverrideActive, 'text'),
        }

        return this.props.selectedItem ? fillFieldsFromData(fieldTemplates, this.props.selectedItem) : fieldTemplates
    }

    hideModal = () => {
        this.props.close()
    }

    setLocations = (fields) => {
        let updatedFields = this.state.InfoFields

        for (const [key, value] of Object.entries(fields)) {
            if (key in this.state.InfoFields) {
                updatedFields = FieldsManager.updateField(updatedFields, key, value)
            }
        }

        this.setState({
            InfoFields: updatedFields,
            canSubmit: true
        }, () => {
            document.getElementById("AddressName").focus()
        })
    }

    handleTabChange = (name) => {
        const tabs = this.state.tabs.map((it) => {
            it.current = it.name === name
            it.invalid = it.invalid || !it.name === name
            return it
        })

        this.setState({ tabs, selectedTab: name })
    }

    render () {
        const { translate } = this.props

        const InfoMetadata = {
            StateID: getLookup('State'),
            CountryID: getLookup('Country'),
            AreaCode: getCountryCodes()
        }
        const DispatchMetadata = {
            DivisionID: {
                api: 'api/' + Resources.Divisions,
                query: {
                    limit: 20,
                    offset: 0,
                },
                searchMap: (it) => ({
                    label: it.DivisionName,
                    value: it.DivisionID
                })
            },
            DispatchDocumentName: DISPATCH_DOCUMENT_NAME,
            DispatchDocumentAddress: DISPATCH_DOCUMENT_ADDRESS,
            DispatchDocumentLogo: DISPATCH_DOCUMENT_LOGO,
            AccountingDocumentName: ACCOUNTING_DOCUMENT_NAME,
            AccountingDocumentAddress: ACCOUNTING_DOCUMENT_ADDRESS,
            AccountingDocumentLogo: ACCOUNTING_DOCUMENT_LOGO
        }

        const InfoFieldsToHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.InfoFields)), translate, (name, value) => this.handleInputChange(name, value, 'InfoFields'), InfoMetadata)
        const DispatchFieldsToHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.DispatchFields)), translate, (name, value) => this.handleInputChange(name, value, 'DispatchFields'), DispatchMetadata)
        const AccountingFieldsToHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.AccountingFields)), translate, (name, value) => this.handleInputChange(name, value, 'AccountingFields'), {})

        return (
            <React.Fragment>
                <ModalHeader
                    title={translate(`text.${this.props.selectedItem ? 'edit' : 'create'}`) + ' ' + translate('text.Office')}
                    onClose={this.hideModal}
                />

                <div className="grid grid-cols-5">
                    <div
                        className="pr-3 border-tm-gray-200 border-r hidden lg:block w-full sticky top-0 col-span-1">
                        <DialogTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>
                    <div className="col-span-4 p-5">
                        {this.state.selectedTab === 'Info' && (
                            <div className={'grid grid-cols-3 gap-5'}>
                                {InfoFieldsToHtml}
                            </div>
                        )}
                        {this.state.selectedTab === 'Divisions' && (
                            <div className={'grid grid-cols-3 gap-5'}>
                                {DispatchFieldsToHtml}
                            </div>
                        )}
                        {this.state.selectedTab === 'Accounting' && (
                            <div>
                                {AccountingFieldsToHtml}
                            </div>
                        )}
                    </div>
                </div>

                <ModalFooter
                    buttonDisabled={!this.state.canSubmit}
                    buttonLabel={translate(`btn.save`)}
                    onButtonClick={this.state.canSubmit && this.submit}

                    closeButtonLabel={translate('btn.cancel')}
                    onClose={this.hideModal}
                />
            </React.Fragment>
        )
    }
}

export default connect(state => state)(CreateOfficeDialog)
