import React, {useEffect, useState} from "react";
import BuildingOfficeIcon from "@heroicons/react/24/outline/BuildingOfficeIcon";
import {
    CreditCardIcon,
    EnvelopeIcon,
    ExclamationTriangleIcon,
    HomeIcon,
    MapPinIcon,
    PhoneIcon
} from "@heroicons/react/24/outline";
import DisplayDataFieldContainer from "../../display-data/display-data-field-container";
import DisplayDataFieldGroupContainer from "../../display-data/display-data-field-group-container";
import DisplayDataFieldTextarea from "../../display-data/display-data-field-textarea";
import LocalStorage from "../../../util/localStorage";
import {useDispatch, useSelector} from "react-redux";
import LoaderLarge from "../../loader/loader-large";
import ResourceTable from "../../resource-table";
import {BanknotesIcon} from '@heroicons/react/20/solid';
import {getDialogInfoResource, resetDialogInfoResource} from "../../../../data/actions/dialogInfoResource";
import {checkPerm, getProp, getSystemName, openInNewTab} from "../../../util/util-helpers";
import Resources from "../../../../data/services/resources";
import ModalDefault from "../modal-default";
import {SECTIONS_VISIBILITY, UPDATE_PERM} from "../../../../util/util-constants";
import {Field} from "../../../../data/services/fields";
import TableCard from "../../resource-table/table-components/table-card";
import CopyToClipboardButton from "../../buttons/copy-to-clipboard-button";
import Env from "../../../../util/env";
import LastBookedLoad from "./last-booked-load";


export default function OrganizationInfoDialog({translate, organizationID, show, handleClose}) {

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.dialogInfoResource);
    const isLoading = getProp(resource, 'isLoading', false)
    const [data, setData] = useState({})

    const fetchData = () => {
        dispatch(getDialogInfoResource({
            user: LocalStorage.get("user"),
            resource: Resources.OrganizationsQuick,
            query: {OrganizationID: organizationID}
        }));
    }

    useEffect(() => {
        if (organizationID && show) {
            fetchData()
        }
    }, [show])

    useEffect(() => {
        if (resource?.data?.list) {
            const selectedOrganization = Object.values(resource.data.list).find((item) => item.OrganizationID == organizationID)
            if (selectedOrganization) {
                setData(selectedOrganization)
            }
        }
    }, [resource])

    const getOrganizationSubType = () => {
        if (data.CustomerID) {
            return {
                route: 'customers',
                key: 'CustomerID'
            }
        } else if (data.CarrierID) {
            return {
                route: 'carriers',
                key: 'CarrierID'
            }
        } else {
            return {
                route: 'vendors',
                key: 'VendorID'
            }
        }
    }

    const handleViewDetails = () => {
        const {route, key} = getOrganizationSubType()

        let hasRoute = false;

        if (route === 'vendors') {
            hasRoute = SECTIONS_VISIBILITY.hasVendorsSubSection
        }

        if (route === 'carriers') {
            hasRoute = SECTIONS_VISIBILITY.hasCarriersSubSection
        }

        if (route === 'customers') {
            hasRoute = SECTIONS_VISIBILITY.hasCustomersSubSection
        }

        hasRoute !== false ? openInNewTab(`/${route}/info/${data.OrganizationID}/${data[key]}`) : openInNewTab(Env.getTruckMainUrl(`/${route}/info/${data.OrganizationID}/${data[key]}`))
    }

    const fields = getFields();
    const secondaryFields = getSecondaryFields();
    const payableFields = getPayableFields();
    const receivableFields = getReceivableFields()
    const taxFields = getTaxFields(data)

    const lastLoad = resource?.data?.LastLoad;

    const billingContacts = getProp(data, 'BillingContacts.list.list', []).map((contact) => {
        contact["Name"] = contact.FirstName + " " + contact.LastName
        return contact
    })

    function handleModalClose() {
        dispatch(resetDialogInfoResource());
        handleClose();
    }

    return (
        <ModalDefault
            show={show}
            title={translate("text.organization_info")}
            widthClass={"max-w-3xl"}
            translate={translate}
            buttonLabel={checkPerm(Resources.CustomersInfo, UPDATE_PERM) && translate("btn.view")}
            onButtonClick={handleViewDetails}
            onClose={handleModalClose}
            closeButtonLabel={translate("btn.close")}
        >
            <div className="bg-tm-gray-50 px-3 py-8 text-tm-gray-700 space-y-8 relative min-h-[27rem]">
                {isLoading && (
                    <LoaderLarge stripesBg/>
                )}

                {SECTIONS_VISIBILITY.hasOrganizationLastLoadMessage && getSystemName() === 'main' && (
                    <div className="max-w-md mx-auto space-y-3">
                        <LastBookedLoad
                            lastLoad={lastLoad}
                            translate={translate}
                        />
                    </div>
                )}

                {!isLoading && (
                    <React.Fragment>
                        <DisplayDataFieldContainer
                            fields={fields}
                            data={data}
                            translate={translate}
                        />

                        <DisplayDataFieldGroupContainer
                            fields={secondaryFields}
                            data={data}
                            translate={translate}
                        />

                        {(!!data?.CreditLimit || !!data?.CreditHold || data?.CreditLimit === 0) && (
                            <div className={'max-w-md mx-auto'}>
                                <p className="font-bold leading-5 mb-2">
                                    {translate("text.Credit")}
                                </p>

                                <DisplayDataFieldGroupContainer
                                    fields={getCreditFields(data, translate)}
                                    data={data}
                                    translate={translate}
                                />
                            </div>
                        )}

                        <DisplayDataFieldTextarea
                            fieldName={"Notes"}
                            translate={translate}
                            data={data}
                        />

                        <DisplayDataFieldGroupContainer
                            title={"Payable"}
                            fields={payableFields}
                            data={data}
                            translate={translate}
                        />

                        <DisplayDataFieldGroupContainer
                            title={"Receivable"}
                            fields={receivableFields}
                            data={data}
                            translate={translate}
                        />


                        <DisplayDataFieldGroupContainer
                            title={"Tax"}
                            fields={taxFields}
                            data={data}
                            translate={translate}
                        />


                        {!!billingContacts.length && (
                            <div className={"max-w-lg mx-auto"}>
                                <p className="font-bold leading-5 mb-2">
                                    Billing contacts
                                </p>
                                <TableCard className={"bg-inverse border-tm-gray-300 border shadow-card rounded-lg"}>
                                    <ResourceTable
                                        addClass={"rounded-card"}
                                        commonTable={true}
                                        data={billingContacts}
                                        count={billingContacts.length}
                                        isLoading={isLoading}
                                        fields={{
                                            Name: new Field('Name', ''),
                                            Email: new Field('Email', '', [''], false, 'custom', {
                                                render: (item) => {
                                                    return (
                                                        <div className={"flex"}>
                                                            <CopyToClipboardButton addClass={"ml-0 pl-0"}
                                                                                   clipboardText={item.Email}
                                                                                   translate={translate}/>
                                                            <div className={"py-2"}>
                                                                {item.Email}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }),
                                            Phone: new Field('Phone', '', [''], false, 'custom', {
                                                render: (item) => {
                                                    return (
                                                        <div className={"flex"}>
                                                            <CopyToClipboardButton addClass={"ml-0 pl-0"}
                                                                                   clipboardText={item.Phone}
                                                                                   translate={translate}/>
                                                            <div className={"py-2"}>
                                                                {item.Phone}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }}
                                        translate={translate}
                                    />
                                </TableCard>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>
        </ModalDefault>
    );
}

const getFields = () => {
    return {
        LegalName: new Field('LegalName', '', [''], false, "text", {icon: () => BuildingOfficeIcon}),
        LocationName: new Field('LocationName', '', [''], false, "text", {icon: () => HomeIcon}),
        AddressName: new Field('AddressName', '', [''], false, "address", {icon: () => MapPinIcon}),
        PhoneNumber: new Field('PhoneNumber', '', [''], false, "phone", {icon: () => PhoneIcon}),
        PrimaryEmail: new Field('PrimaryEmail', '', [], false, "email", {icon: () => EnvelopeIcon})
    }
}

const getCreditFields = (data, translate) => {
    let fields = {
        CreditHold: new Field('CreditHold', '', [''], false, "checkbox", {
            customIcon: () => <ExclamationTriangleIcon className="text-red-600 w-5 h-5"/>,
            label: "creditOnHold",
            info: translate('text.credit_on_hold_note')
        }),
        CreditLimit: new Field('CreditLimit', '', [''], false, "money", {icon: () => CreditCardIcon}),
        AvailableCredit: new Field('AvailableCredit', '', [''], false, "money", {icon: () => BanknotesIcon}),
        CreditLimitUpdateDate: new Field('CreditLimitUpdateDate', '', [''], false, "date"),
        CreditLimitUpdateBy: new Field('CreditLimitUpdateBy', '', [''], false, "text"),

        CreditHoldNote: new Field('CreditHoldNote', '', [''], false, "text"),
    }

    if (!data.CreditHold) {
        delete fields.CreditHold;
    }

    if (!data.CreditLimitUpdateDate) {
        delete fields.CreditLimitUpdateDate;
        delete fields.CreditLimitUpdateBy;
    }

    if (!data.CreditHoldNote) {
        delete fields.CreditHoldNote;
    }

    return fields;
}

const getSecondaryFields = () => {
    return {
        MCNumber: new Field('MCNumber', '', [''], false, "text"),
        USDOTNumber: new Field('USDOTNumber', '', [''], false, "text"),
        FederalID: new Field('FederalID', '', [''], false, "text"),
    }
}

const getPayableFields = () => {
    return {
        PaymentNetTermType: new Field('PaymentNetTermType', '', [''], false, 'text'),
        FactoringOrganization: new Field('FactoringOrganization', '', [''], false, 'text'),
        IsFactoringActive: new Field('IsFactoringActive', '', [''], false, 'checkbox', {}),
    }
}

const getReceivableFields = () => {
    return {
        NetTermType: new Field('NetTermType', '', [''], false, 'text'),
        IsReceivableFactoringActive: new Field('IsReceivableFactoringActive', '', [''], false, 'checkbox')
    }
}

const getTaxFields = (data) => {
    return {
        IsTaxExempt: new Field('IsTaxExempt', '', [''], false, 'checkbox'),
        ...(!!data.TaxExemptReason && {
            TaxExemptReason: new Field('TaxExemptReason', '', [''], false, 'text')
        })
    }
}