import React, { useEffect } from "react";
import { getUserData } from "../../../data/actions/user";
import { useDispatch, useSelector } from "react-redux";
import LocalStorage from "../../../common/util/localStorage";
import {clearCookies} from "../../../common/util/util-auth";
import {Loader} from "../../../common/components/loader";

export default function LoggingInView({history}) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(getUserData());
    }, [])

    useEffect(() => {
        if (user.logout) {
            LocalStorage.remove('user');
            LocalStorage.remove('lookup');
            clearCookies();
            history.push("/login");
        } else if (user.hasUserData && LocalStorage.has("user")) {
            let redirectPath = LocalStorage.get('redirect_path');
            LocalStorage.remove('redirect_path');

            if (!redirectPath) {
                redirectPath = process.env.REACT_APP_HOME_PATH ?? '/home';
            }

            history.push(redirectPath);
        }
    }, [user])

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-inverse">
            <div className="text-center">
                <div className="flex justify-center mb-2">
                    <Loader disableContainer={true}/>
                </div>
                <p className="text-tm-gray-700 text-base tracking-wide font-bold">Logging in ...</p>
            </div>
        </div>
    )
}
