import React, {useEffect, useRef, useState} from "react";
import {
    classNames, convertReminderFields,
    getDefaultContactGroup,
    getDefaultUserOfficeValues,
    getLookup,
    getProp, minutesToDayHourMinutes, onDocumentPrint, openInNewTab,
    resourceIsUpdated
} from "../../../../common/util/util-helpers";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {checkPerm, getDefaultQueryFields, getUser, translateConstant, updateQuery} from "../../../../util/util";
import Resources from "../../../../data/services/resources";
import {
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    READ_PERM,
    UPDATE_PERM,
    WORK_ORDER_STATUS_OPTIONS
} from "../../../../util/util-constants";
import LocalStorage from "../../../../util/localStorage";
import InformationCircleIcon from "@heroicons/react/20/solid/esm/InformationCircleIcon";
import ListBulletIcon from "@heroicons/react/24/outline/ListBulletIcon"
import {showGlobalModal, showModal} from "../../../../data/actions/ui";
import {useSelector} from "react-redux";
import useQuery from "../../../../hooks/use-query";
import {getSecondResource} from "../../../../data/actions/secondResource";
import {
    ArrowLeftIcon,
    CheckCircleIcon,
    MinusIcon,
    PlusIcon,
    TagIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import PageTabWithFooter from "../../../../common/components/layout/layout-components/page/page-tab-with-footer";
import Buttons from "../../../../common/components/buttons";
import FormCard from "../../../../common/components/forms/form-card";
import ModalDefault from "../../../../common/components/modal/modal-default";
import ResourceTableTopBar from "../../../../common/components/resource-table/table-components/resource-table-top-bar";
import FieldSearch from "../../../../common/components/fields/field-text/search";
import ResourceTable from "../../../../common/components/resource-table";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import TableCardFooter from "../../../../common/components/resource-table/table-components/table-card-footer";
import FieldOptions from "../../../../common/components/fields/field-options";
import ProgressArrowsWO from "./progress-arrows-wo";
import PopOver from "../../../../common/components/popover";
import TableTagManagerPopover
    from "../../../../common/components/resource-table/table-components/table-tag-manager/table-tag-manager-popover";
import TableTagManagerModal
    from "../../../../common/components/resource-table/table-components/table-tag-manager/table-tag-manager-modal";
import {createDialogResource, getDialogResource, updateDialogResource} from "../../../../data/actions/dialogResource";
import {Bars3BottomRightIcon} from "@heroicons/react/16/solid";
import EventsTab from "./wo-sidebar/events-tab";
import {updateThirdResource} from "../../../../data/actions/thirdResource";
import Tippy from "@tippyjs/react";
import {HourglassIcon} from "../../../../data/themes/icons";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../../common/components/modal/modal-history";
import {
    CREATE_PERM,
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DRIVER_STATUS_BOOKED, DRIVER_STATUS_ON_LOAD, DRIVER_STATUS_AVAILABLE
} from "../../../../common/util/util-consts";
import {getHistoryResource} from "../../../../data/actions/historyResource";
import Modal from "../../../../common/components/modal";
import FieldSwitchLabel from "../../../../common/components/fields/field-switch/field-switch-label";
import {ExclamationTriangleIcon, NoSymbolIcon} from "@heroicons/react/20/solid";
import Button from "../../../../common/components/button";
import InfoParagraph from "../../../../common/components/info-paragraph";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import CellLink from "../../../../common/components/resource-table/table-components/cell-link";
import WorkOrderStatusBadge from "../../../../common/components/badge/work-order-status-badge";
import {genericMoneyFormatter, scrollErrorIntoViewFields} from "../../../../common/util/util-vanilla";
import Env from "../../../../util/env";
import {getJWT} from "../../../../common/util/util-auth";
import FileViewer from "../../../../common/components/file-viewer/components";
import {download} from "../../../../data/actions/download";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import ResourceTableDialog from "../../../../common/components/modal/resource-table-dialog";
import TrucksTableBadge from "../../../fleet/trucks-view/trucks-table-badge";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";


const CheckboxCell = ({value}) => {
    return value ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
        : <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>
}

export default function WorkOrdersInfoTab({
                                              dataInfo,
                                              isLoading,
                                              fetchData,
                                              dispatch,
                                              translate,
                                              history,
                                              isLayoutScrolled,
                                              scrollToTop,
                                              ID,
                                              handleTabChange,
                                              isParts
                                          }) {
    const getPrimaryField = () => "WorkOrderID";
    const getResourcePath = () => Resources.WorkOrders;
    const getListPath = () => getResourcePath() + "_wo"

    const basicInfoRef = useRef();
    const assignedToRef = useRef();
    const assetsRef = useRef();
    const reminderRef = useRef();

    const secondResource = useSelector((state) => state.secondResource)
    const dialogResource = useSelector((state) => state.dialogResource)
    const historyResource = useSelector((state) => state.historyResource)
    const thirdResource = useSelector((state) => state.thirdResource)

    const secondData = getProp(secondResource, 'data.list', [])
    const secondCount = getProp(secondResource, 'data.count', 0)
    const secondIsLoading = getProp(secondResource, 'isLoading', false)
    const [activeSidebarTab, setActiveSidebarTab] = useState(Resources.WorkOrders);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [confirmModalCancel, setConfirmModalCancel] = useState(false);
    const [confirmModalReject, setConfirmModalReject] = useState(false);

    const isInvoiced = getProp(thirdResource, "data.IsInvoiced", false);
    const isPaid = getProp(thirdResource, "data.IsPaid", false);

    const sidebarTabs = [
        {
            name: 'Events',
            resource: Resources.WorkOrders,
            visible: checkPerm(Resources.WorkOrders, READ_PERM),
            current: true
        }
    ]
    const getQueryFields = (translate) => {
        return getDefaultQueryFields(getPrimaryField(), translate);
    }

    const handleOrganizationQuickView = (it) => {
        dispatch(showModal('ViewCustomerCard', {
            OrganizationID: it.value.value
        }))
    }
    const contactTableOptions = {
        style: {
            stripedRows: true,
            horizontalLines: false,
            floatingActions: false,
        },
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields({}));
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath())
    const [fieldsBasic, setFieldsBasic] = useState(getBasicFields({}, translate, dispatch, handleOrganizationQuickView));
    const [fieldsAssigned, setFieldsAssigned] = useState(getAssignedFields({}, translate));
    const [fieldsAssets, setFieldsAssets] = useState(getAssetFields({}, translate));
    const [fieldsReminder, setFieldsReminder] = useState(() => getFieldsReminderUpdate());

    const [fieldsStatus, setFieldsStatus] = useState(getStatusFields({}, translate));
    const [isStateDirty, setIsStateDirty] = useState(false);
    const [showTruckWoDialog, setShowTruckWoDialog] = useState(false);
    const [showTrailerWoDialog, setShowTrailerWoDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [WOStatusID, setWOStatusID] = useState(1)
    const [initialWOStatusID, setInitialWOStatusID] = useState(1)
    const [previewDocument, setPreviewDocument] = useState(undefined);
    const [previewDialog, setPreviewDialog] = useState(false);
    const [isTruckSelectDialogOpen, setIsTruckSelectDialogOpen] = useState(false);
    const [isTrailerSelectDialogOpen, setIsTrailerSelectDialogOpen] = useState(false);

    const WoColorLabels = getProp(dialogResource, 'data', []);

    const taggedRows = String(dataInfo.ColorTag).split("|").reduce((memo, it) => {
        memo[it] = [1]
        return memo;
    }, {});


    const [isTagManagerModalOpen, setIsTagManagerModalOpen] = useState(false)

    const [sideMenu, setSideMenu] = useState(
        [
            {
                key: "basicInfo",
                label: translate('text.basicInfo'),
                ref: basicInfoRef,
                expanded: true
            },
            {
                key: "assets",
                label: translate('text.assets'),
                ref: assetsRef,
                expanded: true
            },
            {
                key: "assignedTo",
                label: translate('text.service_provider'),
                ref: assignedToRef,
                expanded: true
            },
            {
                key: "reminder",
                label: translate('text.reminder'),
                ref: reminderRef,
                expanded: true
            },
        ])

    /** Constants
     ================================================================= */

    /** Data events
     ================================================================= */
    const onSubmitClick = () => {
        const fieldsBasicValidate = FieldsManager.validateFields(fieldsBasic);
        const fieldsAssignedValidate = FieldsManager.validateFields(fieldsAssigned);
        const fieldsAssetsValidate = FieldsManager.validateFields(fieldsAssets);
        const fieldsStatusValidate = FieldsManager.validateFields(fieldsStatus);
        const fieldsReminderValidate = FieldsManager.validateFields(fieldsReminder);

        if (
            FieldsManager.checkFieldsForErrors(fieldsBasicValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsAssetsValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsAssignedValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsStatusValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsReminderValidate)
        ) {
            let params = Object.assign({},
                FieldsManager.getFieldKeyValues(fieldsBasicValidate),
                FieldsManager.getFieldKeyValues(fieldsAssignedValidate),
                FieldsManager.getFieldKeyValues(fieldsAssetsValidate),
                FieldsManager.getFieldKeyValues(fieldsStatusValidate),
                FieldsManager.getFieldKeyValues(fieldsReminderValidate)
            )
            params = updateParams(params);

            params = convertReminderFields(params);

            dispatch(updateThirdResource({
                user: LocalStorage.get('user'),
                params: params,
                query: {
                    WorkOrderID: params.WorkOrderID
                },
                successMessage: translate("message.work_order_updated"),
                errorMessage: true,
                resource: Resources.WorkOrders,
                piggyResource: Resources.WorkOrder
            }));
        } else {
            scrollErrorIntoViewFields([fieldsBasicValidate])
            scrollErrorIntoViewFields([fieldsAssetsValidate])
            scrollErrorIntoViewFields([fieldsAssignedValidate])
            scrollErrorIntoViewFields([fieldsReminderValidate])
            setFieldsBasic(fieldsBasicValidate)
            setFieldsAssets(fieldsAssetsValidate)
            setFieldsStatus(fieldsStatusValidate)
            setFieldsAssigned(fieldsAssignedValidate)
        }
    }

    const fetchSecondData = (item, query, type) => {
        dispatch(getSecondResource({
            user: getUser(),
            query: Object.assign({
                searchFields: JSON.stringify({
                    [type]: item,
                })
            }, query)
            ,
            resource: Resources.WorkOrders,
        }))
    }

    const fetchLabels = () => {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }));
    }

    const createTag = (label, color) => {
        dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: {ColorTag: label + ',' + color},
            resource: Resources.ColorTag,
            piggyResource: Resources.ColorTag,
            errorMessage: true,
            successMessage: translate('text.tag_created'),
        }))
    }
    const handleTagLoad = (selectedLabels) => {
        const IDs = [ID]
        const ColorTag = selectedLabels.filter(it => it.checked).reduce((memo, it, index) => {
            memo = memo + (index ? '|' : '') + it.label + ',' + it.color
            return memo
        }, '')

        dispatch(updateDialogResource({
            user: LocalStorage.get('user'),
            params: {IDs, ColorTag},
            resource: Resources.ColorTagWorkOrders,
            query: {},
            errorMessage: true,
            successMessage: translate('text.tags_applied'),
        }));
    }

    function getFields(item = {}) {

        const fieldTemplates = {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'custom', {
                render: (it) => {
                    return (
                        <CellLink
                            openInNewTab={true}
                            to={checkPerm(Resources.WorkOrders, UPDATE_PERM) && "/work-orders/" + it.WorkOrderID}
                            disabled={!checkPerm(Resources.WorkOrders, UPDATE_PERM)}
                        >
                            {it.AutoCounter}
                        </CellLink>
                    )
                }
            }),
            WorkOrderStatusID: new Field('WorkOrderStatusID', '', [''], false, 'select', {
                render: (it) => <WorkOrderStatusBadge
                    workOrderStatuses={getLookup("WorkOrderStatus") ?? {}}
                    statusID={it.WorkOrderStatusID}
                />
            }),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                label: "ChargeTo",
                render: (item) => {
                    if (item.ChargeTo == 2) {
                        return ("Non-Chargeable")
                    }
                    if (item.ChargeContactID) {
                        return item.ChargeContact;
                    }
                    if (item.ChargeOrganizationID) {
                        return item.ChargeOrganization;
                    }
                }
            }),
            TotalAmount: new Field('TotalAmount', '', [''], false, "float", {
                render: (it) => (
                    <Tippy
                        content={
                            <div className={"text-right"}>
                                <div>Labor: {genericMoneyFormatter(it.LaborAmount)}</div>
                                <div>Parts: {genericMoneyFormatter(it.PartsAmount)}</div>
                                <div>Shop supplies: {genericMoneyFormatter(it.MiscAmount)}</div>
                                <div>Misc. surcharge: {genericMoneyFormatter(it.SurchargeAmount)}</div>
                            </div>
                        }
                    >
                        <div className={"text-right"}>
                            {genericMoneyFormatter(it.TotalAmount)}
                        </div>
                    </Tippy>
                ),
                colFilter: true
            }, {icon: false}),
            IsInvoiced: new Field('IsInvoiced', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsInvoiced}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsAuthorized: new Field('IsAuthorized', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsAuthorized}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getConfirmFields = (item = null) => {
        const fieldTemplates = {
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}, {})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const handleDownloadDocument = () => {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.WorkOrderForm,
            query: Object.assign({}, {
                WorkOrderID: ID,
                status: 1,
                type: 1,
                name: 'estimate_' + ID + '.pdf'
            }),
            errorMessage: true, successMessage: 'Successfully downloaded estimate!'
        }))
    }

    /** UI Events
     ================================================================= */
    const handleQueryChange = (name, value) => {
        let queryUpdate = updateQuery(queryFields, name, value);
        if (name === 'query') {
            queryUpdate.offset.value = "0"
        }

        if (name === 'limit') {
            queryUpdate.offset.value = "0"
        }

        setQueryFields(queryUpdate);
        fetchSecondData(selectedItem.TruckID ?? selectedItem.TrailerID, FieldsManager.getFieldKeyValues(queryUpdate), selectedItem.TruckID ? 'TruckID' : 'TrailerID');
    }

    const handleBasicInputChange = (name, value) => {
        let fieldsBasicUpdate = Object.assign({}, fieldsBasic);

        if (name === 'ChargeTo') {
            fieldsBasicUpdate.ChargeContactID.validate = [value == "0" ? 'empty' : '']
            fieldsBasicUpdate.ChargeOrganizationID.validate = [value == "1" ? 'empty' : '']

            fieldsBasicUpdate.ChargeContactID.disabled = value == "0" ? false : true
            fieldsBasicUpdate.ChargeOrganizationID.disabled = value == "1" ? false : true
            fieldsBasicUpdate.ChargeContactID.value = ''
            fieldsBasicUpdate.ChargeOrganizationID.value = ''

            fieldsBasicUpdate.IsInHouseRepair.value = value === 2 && 1
        }

        fieldsBasicUpdate = FieldsManager.updateField(fieldsBasicUpdate, name, value);

        setFieldsBasic(fieldsBasicUpdate);
        setIsStateDirty(true);
    }

    const handleAssignedChange = (name, value) => {
        let fieldsAssignedUpdate = Object.assign({}, fieldsAssigned);

        fieldsAssignedUpdate = FieldsManager.updateField(fieldsAssignedUpdate, name, value);

        if ("IsInHouseRepair" === name) {
            fieldsAssignedUpdate.AssignedVendorID.disabled = value === 1;
            fieldsAssignedUpdate.AssignedVendorID.value = value === 1 && "";
            fieldsAssignedUpdate.AssignedVendorID.validate = value ? [''] : ['empty']
        }

        setFieldsAssigned(fieldsAssignedUpdate);
        setIsStateDirty(true);
    }

    const handleReminderChange = (name, value) => {
        let fieldsReminderUpdate = Object.assign({}, fieldsReminder);

        fieldsReminderUpdate = FieldsManager.updateField(fieldsReminderUpdate, name, value);

        if ("IsUpdateReminderOn" === name) {
            fieldsReminderUpdate.ReminderIntervalDays.disabled = !value;
            fieldsReminderUpdate.ReminderIntervalHours.disabled = !value;
            fieldsReminderUpdate.ReminderIntervalMinutes.disabled = !value;
        }

        setFieldsReminder(fieldsReminderUpdate);
        setIsStateDirty(true);
    }

    const handleAssetsChange = (name, value) => {
        let fieldsAssetsUpdate = Object.assign({}, fieldsAssets);

        fieldsAssetsUpdate = FieldsManager.updateField(fieldsAssetsUpdate, name, value);

        fieldsAssetsUpdate.TruckID.validate = !!fieldsAssetsUpdate.TrailerID?.value ? [''] : ['empty']
        fieldsAssetsUpdate.TrailerID.validate = !!fieldsAssetsUpdate.TruckID?.value ? [''] : ['empty']

        setFieldsAssets(fieldsAssetsUpdate);
        setIsStateDirty(true);
    }

    function getFieldsReminderUpdate(item = {}) {
        const fieldTemplates = {
            IsUpdateReminderOn: new Field('IsUpdateReminderOn', '', [''], false, 'switch', {
                label: "update_reminder",
                addContainerClass: "px-4 sm:col-span-8 col-span-full flex items-center bg-tm-gray-100 hover:bg-sky-600/10 rounded-xl",
                htmlAfter: () =>
                    <FieldSwitchLabel
                        label={translate("field.IsStopUpdateReminderOn")}
                        note={translate("text.StopUpdateReminderInfo")}
                    />
            }),
            ReminderIntervalDays: new Field('ReminderIntervalDays', '', [], !item.IsUpdateReminderOn, 'integer', {
                addContainerClass: "col-span-3 col-start-1 sm:col-start-9 sm:col-span-1 w-[calc(100%+1rem+1px)]",
                addClass: "form-control text-center relative rounded-r-none focus:z-10",
            }),
            ReminderIntervalHours: new Field('ReminderIntervalHours', '', [], !item.IsUpdateReminderOn, 'integer', {
                addContainerClass: "col-span-3 sm:col-span-1 w-[calc(100%+1rem+1px)]",
                addClass: "form-control text-center rounded-none focus:z-10 focus:relative",
            }),
            ReminderIntervalMinutes: new Field('ReminderIntervalMinutes', '', [], !item.IsUpdateReminderOn, 'integer', {
                addContainerClass: "col-span-6 sm:col-span-2",
                addClass: "form-control text-center rounded-l-none focus:z-10",
            })
        }

        const filledFields = fillFieldsFromData(fieldTemplates, item);

        if (item.UpdateReminderInterval) {
            const conversion = minutesToDayHourMinutes(item.UpdateReminderInterval)

            filledFields.ReminderIntervalDays.value = conversion.days ?? 0;
            filledFields.ReminderIntervalHours.value = conversion.hours ?? 0;
            filledFields.ReminderIntervalMinutes.value = conversion.minutes ?? 0;
        }

        return filledFields;
    }

    const handleVendorInfoClick = (item) => {
        dispatch(showModal('ViewVendorCard', {VendorID: item?.VendorID, OrganizationID: item?.OrganizationID}))
    }

    const handleContactInfoClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleTruckInfoClick = (truckID) => {
        dispatch(showGlobalModal("ViewTruckCard", truckID))
    }

    const handleTrailerInfoClick = (trailerID) => {
        dispatch(showGlobalModal("ViewTrailerCard", trailerID))
    }

    const handleTruckWoClick = (item) => {
        setShowTruckWoDialog(!showTruckWoDialog);
        setSelectedItem(item);
        fetchSecondData(item?.TruckID, FieldsManager.getFieldKeyValues(queryFields), 'TruckID');
        setQueryFields(getQueryFields(translate));
    }
    const handleTrailerWoClick = (item) => {
        setShowTrailerWoDialog(!showTrailerWoDialog);
        setSelectedItem(item);
        fetchSecondData(item?.TrailerID, FieldsManager.getFieldKeyValues(queryFields), 'TrailerID');
        setQueryFields(getQueryFields(translate));
    }

    const toggleExpandSection = (sectionName) => {
        let sections = Object.values(sideMenu)

        sections.map(it => {
            if (sectionName === it.key) {
                it.expanded = !it.expanded;
            }
            return it;
        })

        setSideMenu(sections)

    }

    const toggleTagManagerModal = () => {
        setIsTagManagerModalOpen(!isTagManagerModalOpen)
    }

    const getFieldTypes = () => {
        let fieldsCombined = Object.assign({}, getBasicFields({}, translate, dispatch, handleOrganizationQuickView), getAssetFields({}, translate), getAssignedFields({}, translate))

        fieldsCombined = Object.values(fieldsCombined).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})

        fieldsCombined['IsInHouseRepair'] = 'checkbox'

        return Object.assign({}, fieldsCombined)
    }

    const handleCancelWoClick = () => {
        if (isStateDirty) {
            return null
        }

        setConfirmModalCancel(true)
    }

    const handleRejectWoClick = () => {
        if (isStateDirty) {
            return null
        }

        setConfirmModalReject(true)
    }

    const toggleProcessPreview = (item = null) => {
        setPreviewDocument(item);
        setPreviewDialog(!previewDialog);
    }

    const getTruckFields = () => ({
            TruckNumber: new Field('TruckNumber', '', [''], false, "text",),
            Manufacturer: new Field('Manufacturer', '', [''], false, "text",),
            Model: new Field('Model', '', [''], false, "text",),
            Year: new Field('Year', '', [''], false, "text", {label: "model_year"}),
            Engine: new Field('Engine', '', [''], false, "text"),
            VIN: new Field('VIN', '', [''], false, "text"),
            OwnedBy: new Field('OwnedBy', '', [''], false, "custom", {
                omitSort: true,
                render: (it) => {
                    return (
                        <div>
                            {it.OwnedByOrganization ?? it.OwnedByContact}
                        </div>
                    )
                }
            })
    });

    const getTrailerFields = () => ({
        TrailerNumber: new Field('TrailerNumber', '', [''], false, "text",),
        Manufacturer: new Field('Manufacturer', '', [''], false, "text",),
        Model: new Field('Model', '', [''], false, "text",),
        Year: new Field('Year', '', [''], false, "text", {label: "model_year"}),
        Engine: new Field('Engine', '', [''], false, "text"),
        VIN: new Field('VIN', '', [''], false, "text"),
        OwnedBy: new Field('OwnedBy', '', [''], false, "custom", {
            omitSort: true,
            render: (it) => {
                return (
                    <div>
                        {it.OwnedByOrganization ?? it.OwnedByContact}
                    </div>
                )
            }
        })
    });

    const getAssetFilters = () => (Object.assign({
            query: new Field('query', '', [''], false, 'search', {
                addContainerClass: 'col-span-3',
                labelType: "float"
            }),
            StatusID: new Field('StatusID', '', [''], false, 'select', {
                addContainerClass: 'col-span-3',
                labelType: "float"
            }, {isClearable: true}),
        },
        getUser("Office.CountryName") === "USA" ? {
            StateID: new Field('StateID', '', [''], false, 'select', {
                addContainerClass: 'col-span-3',
                labelType: "float"
            }, {isClearable: true})
        } : {}
    ));

    const toggleTruckSelectDialog = () => {
            setIsTruckSelectDialogOpen(!isTruckSelectDialogOpen)
    }

    const toggleTrailerSelectDialog = () => {
            setIsTrailerSelectDialogOpen(!isTrailerSelectDialogOpen)
    }

    const getAssetSelects = () => ({
        StatusID: {
            [DRIVER_STATUS_AVAILABLE]: "Available",
            [DRIVER_STATUS_BOOKED]: "Booked",
            [DRIVER_STATUS_ON_LOAD]: "On Load"
        },
        CountryID: getLookup('Country', 'CountryID', 'CountryName'),
        StateID: getLookup('State', 'StateID', 'State'),
        OwnedByOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.OrganizationID,
                label: item.LegalName
            })
        },
        OwnedByContactID: {
            api: 'api/' + Resources.ContactsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + " " + item.LastName
            })
        },
    })

    const handleInputChange = (name, value) => {
       return FieldsManager.updateField(fieldsAssets, name, value)
    }
    /** Helpers
     ================================================================= */
    const updateParams = (params) => {
        params.WorkOrderStatusID = WOStatusID;

        return params;
    }

    const handleScrollToSectionClick = (sectionRef) => {
        sectionRef.current.scrollIntoView({block: "center", behavior: "smooth"})
    }

    const updateWOStatusID = (statusID) => {
        setWOStatusID(statusID)
        setIsStateDirty(true)
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        if (typeof fetchData === 'function') {
            fetchData();
            fetchLabels();
        }
    }, [])

    useEffect(() => {
        if (resourceIsUpdated(dialogResource) && dialogResource.resource === Resources.ColorTagWorkOrders) {
            fetchData();
        }
    }, [dialogResource]);

    useEffect(() => {
        if (!isLoading) {
            let workOrderStatusID = getProp(dataInfo, "WorkOrderStatusID", 1);
            setFieldsBasic(getBasicFields(dataInfo, translate, dispatch, handleOrganizationQuickView));
            setFieldsAssigned(getAssignedFields(dataInfo, translate, handleVendorInfoClick, handleContactInfoClick));
            setFieldsAssets(getAssetFields(dataInfo, translate, handleTruckInfoClick, handleTrailerInfoClick, handleTruckWoClick, handleTrailerWoClick, toggleTruckSelectDialog, toggleTrailerSelectDialog));
            setFieldsStatus(getStatusFields(dataInfo, translate, updateStatusOptions(workOrderStatusID, translate)));
            setFieldsReminder(getFieldsReminderUpdate(dataInfo))
            setIsStateDirty(false);
            setWOStatusID(getProp(dataInfo, "WorkOrderStatusID", 1))
            setInitialWOStatusID(getProp(dataInfo, "WorkOrderStatusID", 1))
            isParts && handleTabChange(Resources.WorkOrderParts)
            history.replace({
                search: "",
            })
        }
    }, [isLoading])

    /** Component Body
     ================================================================= */
    return (
        <PageTabWithFooter
            isLoading={isLoading}
            customFooter={(
                <React.Fragment>
                    {/* Page footer */}
                    <div
                        className={"w-full pl-60 h-14 flex justify-end items-center fixed bottom-0 right-0 px-8 bg-inverse border-tm-gray-200 border-t z-30"}
                    >
                        <div className="flex mr-auto space-x-3">
                            {!isLoading && (
                                <Tippy content={translate('btn.cancel_wo_disabled')}
                                       disabled={!isStateDirty}>
                                    <div className="inline-block">
                                        <Button
                                            hasPerm={checkPerm(Resources.WorkOrderCancel, UPDATE_PERM)}
                                            disabled={!!isStateDirty || WOStatusID === 17 || !!isPaid || !!isInvoiced}
                                            className={'btn btn-danger'}
                                            onClick={() => {
                                                handleCancelWoClick();
                                            }}
                                        >
                                            <ExclamationTriangleIcon className="-ml-1 mr-2 h-5 w-5"
                                                                     aria-hidden="true"/>

                                            {translate('btn.cancel_wo')}
                                        </Button>
                                    </div>
                                </Tippy>
                            )}

                            {!isLoading && (
                                <Tippy content={translate('btn.reject_wo_disabled')}
                                       disabled={!isStateDirty}>
                                    <div className="inline-block">
                                        <Button
                                            hasPerm={checkPerm(Resources.WorkOrderReject, UPDATE_PERM)}
                                            disabled={!!isStateDirty || WOStatusID === 16 || !!isPaid || !!isInvoiced}
                                            className={'btn btn-danger-outline'}
                                            onClick={() => {
                                                handleRejectWoClick();
                                            }}
                                        >
                                            <NoSymbolIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>

                                            {translate('btn.reject_wo')}
                                        </Button>
                                    </div>
                                </Tippy>
                            )}
                        </div>

                        <div className={"flex gap-x-4"}>
                            <Buttons
                                buttons={
                                    [
                                        {
                                            className: "btn btn-primary",
                                            onClick: onSubmitClick,
                                            disabled: !isStateDirty,
                                            label: translate("btn.save")
                                        },
                                        {
                                            className: "btn btn-outline",
                                            disabled: !isStateDirty,
                                            onClick: fetchData,
                                            label: translate("btn.cancel")
                                        },
                                    ]
                                }
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
        >

            <aside
                className={classNames(
                    'custom-sidebar z-30',
                    isSidebarVisible
                        ? 'translate-x-0'
                        : 'translate-x-full',
                    'transition-all transform bg-tm-gray-50 border-tm-gray-300 border-l fixed top-16 right-0 w-96 app-body-height-footer'
                )}
            >
                <div className="p-6">
                    <div className="flex items-start justify-between">
                        <h2 className="text-lg font-medium text-tm-gray-900">
                            {translate("text.wo_sidebar")}
                        </h2>

                        <div className="ml-3 h-7 flex items-center">
                            <button
                                type="button"
                                className="btn btn-close"
                                onClick={() => setIsSidebarVisible(!isSidebarVisible)}
                            >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="border-b border-tm-gray-200">
                    <div className="px-6">
                        <nav className="-mb-px flex space-x-6">
                            {sidebarTabs.map((tab) => (
                                <span
                                    key={tab.name}
                                    onClick={() => setActiveSidebarTab(tab.resource)}
                                    className={classNames(
                                        activeSidebarTab === tab.resource
                                            ? 'border-primary text-primary'
                                            : 'border-transparent text-tm-gray-600 hover:text-tm-gray-500 hover:border-tm-gray-300',
                                        'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                                    )}
                                >
                                        {tab.name}
                                    </span>
                            ))}
                        </nav>
                    </div>
                </div>

                {isSidebarVisible && activeSidebarTab === Resources.WorkOrders && (
                    <div className="h-[calc(100vh-243px)]">
                        <EventsTab
                            key={ID + "event"}
                            WoID={ID}
                            translate={translate}
                            disableCreateEvent
                        />
                    </div>
                )}
            </aside>

            <div className="flex items-center justify-end gap-4 mt-2">
                <Button
                    hasPerm={checkPerm(Resources.WorkOrderForm, CREATE_PERM)}
                    appearance={'outline'}
                    addClass={"flex"}
                    disabled={isInvoiced || isLoading}
                    onClick={() => toggleProcessPreview()}>{translate("btn.print_intro_form")}</Button>

                {!isSidebarVisible && (
                    <button
                        className="btn btn-outline flex mr-10 relative2"
                        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
                    >
                        <Bars3BottomRightIcon className="h-5 w-5"/>
                    </button>
                )}
            </div>

            <div className={classNames(isSidebarVisible ? "mt-[52px]" : "mt-4")}>
                <ProgressArrowsWO
                    WOStatusID={WOStatusID}
                    locked={false}
                    initialWOStatusID={initialWOStatusID}
                    onItemClick={updateWOStatusID}
                    translate={translate}
                    isLoading={isLoading}
                />
            </div>

            <div className="flex items-center space-x-3 justify-start relative left-[20%] mt-2">
                {WOStatusID === 17 && (
                    <div className={"w-fit"}>
                        <InfoParagraph type="danger">
                            {translate('info.wo_is_canceled')}
                        </InfoParagraph>
                    </div>
                )}

                {WOStatusID === 16 && (
                    <div className={"w-fit"}>
                        <InfoParagraph type="danger">
                            {translate('info.wo_is_rejected')}
                        </InfoParagraph>
                    </div>
                )}
            </div>

            <div className="flex items-center space-x-3 justify-end relative right-1/4 mt-2">
                <PopOver
                    btnClass={"text-opacity-90 btn btn-header"}
                    tippy={translate("text.label_wo")}
                    BtnIcon={TagIcon}
                    btnIconClass="text-tm-gray-600 w-5 h-5"
                    widthClass={'max-w-[16rem]'}
                    positionClass="fixed md:absolute right-0 mx-5 md:mx-0 translate-x-0 "
                >
                    <div
                        className="bg-popup border border-tm-gray-300 rounded-md"
                    >
                        <TableTagManagerPopover
                            translate={translate}
                            labels={WoColorLabels}
                            taggedRows={{
                                list: taggedRows
                            }}
                            selectedRows={{[1]: 1}}
                            onApplySelected={(taggedRows, labelList) => handleTagLoad(labelList)}
                            onManageTagsClick={toggleTagManagerModal}
                            isLoading={false}
                        />
                    </div>
                </PopOver>

                {
                    checkPerm(Resources.WorkOrderHistory, READ_PERM) && (
                        <div className={'flex justify-end ml-6'}>
                            <Tippy content={<span>{translate('text.view_history')}</span>}>
                                <button
                                    type="button"
                                    onClick={() => setHistoryModalOpen(true)}
                                    className="btn btn-header"
                                >
                                    {<HourglassIcon className="w-5 h-5"/>}
                                </button>
                            </Tippy>
                        </div>
                    )
                }
            </div>

            <div className="xl:max-w-3xl md:ml-44 mx-6 xl:mx-auto py-8 space-y-6 relative mb-64">
                <div
                    className="hidden w-36 h-full absolute md:block right-full ml-6">
                    <div className="fixed h-10 top-30">
                        <div
                            className="space-y-1 pr-6"
                        >
                            {isLayoutScrolled && (
                                <div className="flex relative right-4">
                                    <div>
                                        <Buttons
                                            buttons={[
                                                {
                                                    iconLeading: ArrowLeftIcon,
                                                    className: "btn btn-header",
                                                    onClick: () => history.push('/work-orders/'),
                                                    title: "Go back to work orders list"
                                                }
                                            ]}
                                        />
                                    </div>

                                    <button
                                        onClick={() => scrollToTop()}
                                        className="btn btn-text ml-2 hover:bg-primary-transparent hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                                    >
                                        {dataInfo.AutoCounter ?? translate("text.scroll_to_top")}
                                    </button>
                                </div>
                            )}

                            {sideMenu.map(it => {
                                return (<div className={"flex relative right-8"}>
                                        <button
                                            className="flex btn-icon rounded-button hover:bg-tm-gray-200"
                                            onClick={() => toggleExpandSection(it.key)}
                                        >
                                            {!it.expanded && (
                                                <PlusIcon className="w-5 h-5 text-green-600"/>
                                            )}

                                            {it.expanded && (
                                                <MinusIcon className="w-5 h-5"/>
                                            )}
                                        </button>

                                        <button
                                            onClick={() => handleScrollToSectionClick(it.ref)}
                                            className="text-tm-gray-800 hover:bg-inverse flex items-center px-3 py-2 text-sm font-medium rounded-btn w-full"
                                        >
                                            <span className="truncate">{it.label}</span>
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <FormCard
                    innerRef={basicInfoRef}
                    title={translate('text.basicInfo')}
                    isLoading={isLoading}
                    onInputChange={handleBasicInputChange}
                    fields={fieldsBasic}
                    excludedFields={['RequesterID', 'CreatedDate']}
                    selects={getSelects()}
                    addFieldContainerClass={"col-span-6"}
                    translate={translate}
                    isExpanded={sideMenu[0].expanded}
                    toggleExpandSection={() => toggleExpandSection('basicInfo')}
                />

                <FormCard
                    innerRef={assetsRef}
                    title={translate('text.assets')}
                    isLoading={isLoading}
                    onInputChange={handleAssetsChange}
                    fields={fieldsAssets}
                    selects={getSelects()}
                    addFieldContainerClass={"col-span-6"}
                    translate={translate}
                    isExpanded={sideMenu[1].expanded}
                    toggleExpandSection={() => toggleExpandSection('assets')}
                />

                <FormCard
                    innerRef={assignedToRef}
                    title={translate('text.service_provider')}
                    isLoading={isLoading}
                    onInputChange={handleAssignedChange}
                    fields={fieldsAssigned}
                    selects={getSelects()}
                    addFieldContainerClass={"col-span-6"}
                    translate={translate}
                    isExpanded={sideMenu[2].expanded}
                    toggleExpandSection={() => toggleExpandSection('assignedTo')}
                />

                <FormCard
                    innerRef={reminderRef}
                    title={translate('text.update_reminder')}
                    isLoading={isLoading}
                    onInputChange={handleReminderChange}
                    fields={fieldsReminder}
                    // selects={getSelects()}
                    addFieldContainerClass={"col-span-4"}
                    translate={translate}
                    isExpanded={sideMenu[3].expanded}
                    toggleExpandSection={() => toggleExpandSection('reminder')}
                />

                <ModalDefault
                    title={translate("title.work_orders_for_truck", [selectedItem?.Truck])}
                    show={showTruckWoDialog}
                    onClose={handleTruckWoClick}
                    translate={translate}
                    widthClass={"max-w-6xl"}
                >
                    <ResourceTableTopBar
                        addClass="rounded-t-md ring-1 ring-black ring-opacity-5"
                    >
                        <FieldSearch
                            addClass="form-control"
                            name="query"
                            value={queryFields?.query?.value}
                            onChange={handleQueryChange}
                            translate={translate}
                        />

                    </ResourceTableTopBar>

                    {(secondIsLoading || !!secondCount) && (
                        <ResourceTable
                            data={secondData}
                            fields={fields}
                            queryFields={queryFields}

                            options={{
                                style: {
                                    stripedRows: true,
                                    horizontalLines: true,
                                    verticalLines: true,
                                    floatingActions: true
                                }
                            }}

                            translate={translate}
                            isLoading={secondIsLoading}
                        />
                    )}

                    {!secondIsLoading && !secondCount && (
                        <NoRecords
                            addClass="p-10"
                            title={translate("text.no_work_orders_for_truck")}
                        />
                    )}

                    <TableCardFooter
                        show={!(!secondData.length && !secondIsLoading)}
                    >
                        <Pagination
                            count={secondCount}
                            isLoading={secondIsLoading}
                            handleQueryChange={handleQueryChange}
                            queryFields={queryFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </ModalDefault>

                <ModalDefault
                    title={translate("title.work_orders_for_trailer", [selectedItem?.Trailer])}
                    show={showTrailerWoDialog}
                    onClose={handleTrailerWoClick}
                    translate={translate}
                    widthClass={"max-w-6xl"}
                >
                    <ResourceTableTopBar
                        addClass="rounded-t-md ring-1 ring-black ring-opacity-5"
                    >
                        <FieldSearch
                            addClass="form-control"
                            name="query"
                            value={queryFields?.query?.value}
                            onChange={handleQueryChange}
                            handleQueryChange={handleQueryChange}
                            translate={translate}
                        />

                    </ResourceTableTopBar>

                    {(secondIsLoading || !!secondCount) && (
                        <ResourceTable
                            data={secondData}
                            fields={fields}
                            queryFields={queryFields}

                            options={{
                                style: {
                                    stripedRows: true,
                                    horizontalLines: true,
                                    verticalLines: true,
                                    floatingActions: true
                                }
                            }}

                            translate={translate}
                            isLoading={secondIsLoading}
                        />
                    )}

                    {!secondIsLoading && !secondCount && (
                        <NoRecords
                            addClass="p-10"
                            title={translate("text.no_work_orders_for_trailer")}
                        />
                    )}

                    <TableCardFooter
                        show={!(!secondData.length && !secondIsLoading)}
                    >
                        <Pagination
                            count={secondCount}
                            isLoading={secondIsLoading}
                            handleQueryChange={handleQueryChange}
                            queryFields={queryFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </ModalDefault>

                <TableTagManagerModal
                    show={isTagManagerModalOpen}
                    onClose={toggleTagManagerModal}
                    translate={translate}
                    onCreateTag={createTag}
                    tagsData={WoColorLabels}
                    isLoading={dialogResource.isLoading}
                />
            </div>

            <Modal
                show={historyModalOpen}
                widthClass={"max-w-5xl"}
                onClose={() => {
                    setHistoryModalOpen(false)
                }}
                translate={translate}
            >
                {historyModalOpen && (
                    <HistoryModal
                        onClose={() => setHistoryModalOpen(false)}
                        translate={translate}
                        title={translate("text." + Resources.WorkOrderHistory)}
                        tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, getFieldTypes())}
                        filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                        onFetchData={(query) => {
                            dispatch(getHistoryResource({
                                user: LocalStorage.get('user'),
                                query: Object.assign({}, query, {
                                    id: ID
                                }),
                                resource: Resources.WorkOrderHistory
                            }))
                        }}
                        data={getProp(historyResource, "data.list", [])}
                        count={getProp(historyResource, "data.count", 0)}
                        isLoading={getProp(historyResource, "isLoading", false)}
                        showUserAvatar

                        onRowClick={(item) => {
                            dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                        }}
                        fieldTypes={getFieldTypes()}
                    />
                )}
            </Modal>

            <ModalSaveResource
                show={!!confirmModalCancel}
                onSubmit={(params) => {
                    dispatch(updateThirdResource({
                        user: LocalStorage.get('user'),
                        params: {
                            WorkOrderID: ID,
                            Notes: params.Notes
                        },
                        query: {
                            WorkOrderID: ID
                        },
                        resource: Resources.WorkOrderCancel,
                        piggyResource: Resources.WorkOrder,
                        errorMessage: true, successMessage: `Work order canceled`
                    }))
                    setConfirmModalCancel(false)
                }}
                canSubmit={true}
                fields={getConfirmFields()}
                onClose={() => {
                    setConfirmModalCancel(false)
                }}
                buttonLabel={translate("btn.yes")}
                closeButtonLabel={translate("btn.no")}
                title={translate('modal_heading.confirm')}
                htmlBefore={<div
                    className={"flex justify-center my-4 text-lg"}>{translate("text.are_sure_cancel_order")}</div>}
                translate={translate}
            />

            <ModalSaveResource
                show={!!confirmModalReject}
                onSubmit={(params) => {
                    dispatch(updateThirdResource({
                        user: LocalStorage.get('user'),
                        params: {
                            WorkOrderID: ID,
                            Notes: params.Notes
                        },
                        query: {
                            WorkOrderID: ID
                        },
                        resource: Resources.WorkOrderReject,
                        piggyResource: Resources.WorkOrder,
                        errorMessage: true, successMessage: `Work order rejected`
                    }))
                    setConfirmModalReject(false)
                }}
                canSubmit={true}
                fields={getConfirmFields()}
                onClose={() => {
                    setConfirmModalReject(false)
                }}
                buttonLabel={translate("btn.yes")}
                closeButtonLabel={translate("btn.no")}
                title={translate('modal_heading.confirm')}
                htmlBefore={<div
                    className={"flex justify-center my-4 text-lg"}>{translate("text.are_sure_reject_order")}</div>}
                translate={translate}
            />

            <ModalDefault
                show={previewDialog}
                limitHeight={true}
                widthClass={'max-w-6xl'}
                title={translate('text.preview_document')}

                onButtonClick={handleDownloadDocument}
                buttonLabel={translate('Download')}

                customButtonsHTML={(
                    <div>
                        <button
                            className={`btn btn-primary mr-2`}
                            onClick={() => onDocumentPrint(Env.getApiUrl('api/' + Resources.WorkOrderForm, Object.assign({}, {
                                WorkOrderID: ID,
                                token: getJWT().access_token,
                                type: 1,
                                name: 'estimate_' + ID + '.pdf'
                            })), 'pdf')}
                        >
                            {translate('btn.Print')}
                        </button>
                    </div>
                )}

                closeButtonLabel={translate('btn.Close')}
                onClose={toggleProcessPreview}
                isLoading={false}
            >
                {!!previewDialog && (
                    <FileViewer
                        fileType={'pdf'}
                        filePath={Env.getApiUrl('api/' + Resources.WorkOrderForm, Object.assign({}, {
                            WorkOrderID: ID,
                            token: getJWT().access_token,
                            type: 1,
                            name: 'estimate_' + ID + '.pdf'
                        }))}
                        onError={(e) => {
                            console.log(e)
                        }}
                    />
                )}
            </ModalDefault>

            <ResourceTableDialog
                show={isTruckSelectDialogOpen}
                dialogResource={dialogResource}
                resource={Resources.TrucksQuick}
                title={translate("text.select_truck")}
                dispatch={dispatch}
                fields={getTruckFields()}
                widthClass={"max-w-7xl"}
                options={contactTableOptions}
                fieldsFilter={
                    Object.assign(getAssetFilters(),
                        {NotSoldRetired: new Field('NotSoldRetired', '1', [''], false, "hidden")}
                    )}

                searchFields={
                    {
                        OwnedByContactID: new Field('OwnedByContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-3',
                            labelType: "float"}, {
                            isClearable: true
                        }),
                        OwnedByOrganizationID: new Field('OwnedByOrganizationID', '', [''], false, 'select-search', {addContainerClass: 'col-span-3',
                            labelType: "float"}, {
                            isClearable: true
                        }),
                    }
                }

                sortBy={"TruckNumber"}
                defaultAction={(item) => dispatch(showGlobalModal('ViewTruckCard', item.TruckID))}
                onCustomActions={[
                    {
                        action: (item) => {
                            handleInputChange("TruckID", {
                                value: item.TruckID,
                                label: item.TruckNumber + " - " + item.Manufacturer + ", " + item.Model + ", " + item.Year + " (VIN:" + item.VIN + ")",
                                metadata: item
                            })
                            toggleTruckSelectDialog();
                        },
                        icon: PlusCircleIcon,
                        visible: () => true
                    }
                ]}
                selects={getAssetSelects()}
                onClose={toggleTruckSelectDialog}
                translate={translate}
            />

            <ResourceTableDialog
                show={isTrailerSelectDialogOpen}
                dialogResource={dialogResource}
                resource={Resources.TrailersQuick}
                title={translate("text.select_trailer")}
                dispatch={dispatch}
                fields={getTrailerFields()}
                widthClass={"max-w-7xl"}
                options={contactTableOptions}
                fieldsFilter={
                    Object.assign(getAssetFilters(),
                        {NotSoldRetired: new Field('NotSoldRetired', '1', [''], false, "hidden")}
                    )}

                searchFields={
                    {
                        OwnedByContactID: new Field('OwnedByContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-3',
                            labelType: "float"}, {
                            isClearable: true
                        }),
                        OwnedByOrganizationID: new Field('OwnedByOrganizationID', '', [''], false, 'select-search', {addContainerClass: 'col-span-3',
                            labelType: "float"}, {
                            isClearable: true
                        }),
                    }
                }

                sortBy={"TrailerNumber"}
                defaultAction={(item) => dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID))}
                onCustomActions={[
                    {
                        action: (item) => {
                            handleInputChange("TrailerID", {
                                value: item.TrailerID,
                                label: item.TrailerNumber + " - " + item.Manufacturer + ", " + item.Model + ", " + item.Year + " (VIN:" + item.VIN + ")",
                                metadata: item
                            })
                            toggleTrailerSelectDialog();
                        },
                        icon: PlusCircleIcon,
                        visible: () => true
                    }
                ]}
                selects={getAssetSelects()}
                onClose={toggleTrailerSelectDialog}
                translate={translate}
            />
        </PageTabWithFooter>
    )
}

const getBasicFields = (item = {}, translate, dispatch, handleOrganizationQuickView) => {
    // item.ChargeTo = item.IsInHouseRepair ? 2 : item.ChargeTo
    const fieldTemplates = {
        WorkOrderID: new Field('WorkOrderID', '', [''], false, 'hidden'),
        // RequestedServiceCategoryID: new Field('RequestedServiceCategoryID', '', ['empty'], false, 'select', {addContainerClass: "col-span-6"}),
        // WorkOrder: new Field('WorkOrder', '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),
        ChargeTo: new Field('ChargeTo', '', [''], '', 'button-group', {
            data: {
                0: translate('field.ContactID'),
                1: translate('field.OrganizationID'),
                2: translate('field.Non-ChargeableRepair')
            },
            hideTable: true,
            addContainerClass: 'col-span-12'
        }),
        IsInHouseRepair: new Field('IsInHouseRepair', 0, [], false, "hidden"),
        ChargeContactID: new Field('ChargeContactID', '', [item.ChargeTo ? '' : 'empty'], item.ChargeTo == "0" ? false : true, 'select-search', {
            addContainerClass: 'col-span-6',
            hideTable: true,
            fieldOptions: (it) => {
                return (
                    <FieldOptions
                        options={[
                            {
                                icon: InformationCircleIcon,
                                onClick: () => dispatch(showModal('ViewContactCard', {ContactID: it?.value.value})),
                                isVisible: !!it.value
                            }
                        ]}
                    />
                )
            }
        }),
        ChargeOrganizationID: new Field('ChargeOrganizationID', '', [item.ChargeTo == "1" ? 'empty' : ''], item.ChargeTo == "1" ? false : true, 'select-search', {
            addContainerClass: 'col-span-6',
            hideTable: true,
            fieldOptions: (it) => {
                return (
                    <FieldOptions
                        options={[
                            {
                                icon: InformationCircleIcon,
                                onClick: () => handleOrganizationQuickView(it),
                                isVisible: !!it.value
                            }
                        ]}
                    />
                )
            }
        }),
        CustomerReferenceNumber: new Field('CustomerReferenceNumber', '', [''], false, 'text', {addContainerClass: "col-span-6"}),
        OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['empty'], false, 'select-search', {addContainerClass: "col-span-6"}),
        ContactGroupID: new Field('ContactGroupID', getDefaultContactGroup(), ['empty'], false, 'select-search', {addContainerClass: "col-span-6"}),
        Notes: new Field('Notes', '', [''], false, 'textarea', {
            label: 'internal_notes',
            addContainerClass: "col-span-12",
            htmlAfter: () => <InfoBar type="info" addClass="col-span-full">
                {translate("text.internal_notes_message")}
            </InfoBar>
        }),
        ExternalNotesCustomer: new Field('ExternalNotesCustomer', '', [''], false, 'textarea', {
            label: "customer_notes",
            addContainerClass: "col-span-12",
            htmlAfter: () => <InfoBar type="warning" addClass="col-span-full font-bold">
                {translate("text.external_customer_notes_message")}
            </InfoBar>
        }),
    }

    return fillFieldsFromData(fieldTemplates, item)
}

const getAssignedFields = (item = {}, translate, handleVendorInfoClick, handleContactInfoClick) => {
    const fieldTemplates = {
        IsInHouseRepair: new Field('IsInHouseRepair', 0, [''], false, 'button-group', {
            data: {1: translate('btn.internal_shop'), 0: translate('btn.third_party_shop')},
            addContainerClass: 'col-span-full',
            hideLabel: true
        }),
        AssignedVendorID: new Field(
            'AssignedVendorID',
            '',
            item.IsInHouseRepair === 0 ? ['empty'] : [''],
            item.IsInHouseRepair === 1,
            'select-search',
            {
                addContainerClass: "col-start-1 col-span-6",
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[{
                                icon: InformationCircleIcon,
                                onClick: () => handleVendorInfoClick(it?.value?.metadata),
                                isVisible: !!it?.value
                            }]}
                        />
                    )
                }
            },
            {isClearable: true}
        )
    }

    let filledFields = fillFieldsFromData(fieldTemplates, item);

    if (!!filledFields.AssignedVendorID.value) {
        filledFields.AssignedVendorID.value.metadata = item.AssignedVendorInfo;
    }

    return filledFields;
}

const getAssetFields = (item = {}, translate, handleTruckInfoClick, handleTrailerInfoClick, handleTruckWoClick, handleTrailerWoClick, toggleTruckSelectDialog, toggleTrailerSelectDialog) => {
    const fieldTemplates = {
        TruckID: new Field(
            'TruckID',
            '',
            !!item.TrailerID ? [''] : ['empty'],
            false,
            'select-search',
            {
                addContainerClass: "col-span-6 max-w-80",
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: ListBulletIcon,
                                    onClick: () => handleTruckWoClick(it?.value?.metadata),
                                    isVisible: !!it?.value?.metadata
                                },
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => handleTruckInfoClick(it?.value?.value),
                                    isVisible: !!it?.value
                                },
                            ]}
                        />
                    )
                },
                htmlAfterField: (it) => {
                    return <button
                        onClick={() => toggleTruckSelectDialog()}
                        className="btn btn-input"
                    >
                        <PlusIcon/>
                    </button>
                },
                htmlAfter: (it) => {
                    return (<div className="col-start-1 2xl:col-span-6 col-span-full row-start-2 gap-4">
                        <div className="flex gap-2 px-px text-tm-gray-900">
                            <div className="w-full">
                                <div className="px-3 group h-4">
                                   <Button
                                    onClick={() => openInNewTab("/truck/create")}
                                    className="btn btn-outline h-5"
                                >
                                    Create truck
                                </Button>
                                </div>
                            </div>
                        </div>
                    </div>)
                }
            },
            {
                isClearable: true
            }
        ),
        TrailerID: new Field(
            'TrailerID',
            '',
            !!item.TruckID ? [''] : ['empty'],
            false,
            'select-search',
            {
                addContainerClass: "col-span-6 max-w-80",
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: ListBulletIcon,
                                    onClick: () => handleTrailerWoClick(it?.value?.metadata),
                                    isVisible: !!it?.value?.metadata
                                },
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => handleTrailerInfoClick(it?.value?.value),
                                    isVisible: !!it?.value
                                }]}
                        />
                    )
                },
                htmlAfterField: (it) => {
                    return <button
                        onClick={() => toggleTrailerSelectDialog()}
                        className="btn btn-input"
                    >
                        <PlusIcon/>
                    </button>
                },
                htmlAfter: (it) => {
                    return (<div className="col-start-7 2xl:col-span-6 col-span-full gap-4 row-start-2">
                        <div className="flex gap-2 px-px text-tm-gray-900">
                            <div className="w-full">
                                <div className="px-3 group h-4">
                                    <Button
                                        onClick={() => openInNewTab("/trailer/create")}
                                        className="btn btn-outline h-5"
                                    >
                                        Create trailer
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>)
                }
            },
            {isClearable: true}
        ),
        CurrentOdometerValue: new Field('CurrentOdometerValue', '', [''], false, 'text', {addContainerClass: "col-span-6"})
    }

    let filledFields = fillFieldsFromData(fieldTemplates, item);

    if (!!filledFields.TruckID.value) {
        filledFields.TruckID.value.metadata = item.TruckInfo;
    }

    if (!!filledFields.TrailerID.value) {
        filledFields.TrailerID.value.metadata = item.TrailerInfo;
    }

    return filledFields;
}

const getStatusFields = (item = {}, translate, workOrderOptions) => {
    return {
        WorkOrderStatusID: new Field('WorkOrderStatusID', {
            value: item.WorkOrderStatusID,
            label: translateConstant(WORK_ORDER_STATUS_OPTIONS, translate)[item.WorkOrderStatusID]
        }, [''], false, 'select', {addContainerClass: "col-span-6",}, {
            options: workOrderOptions
        }),
    }
}

function getSelects() {
    return {
        AssignedContactID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
                metadata: it
            })
        },
        AssignedVendorID: {
            api: 'api/' + Resources.Vendors,
            query: {},
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.VendorID,
                metadata: it
            })
        },
        TruckID: {
            api: 'api/' + Resources.Trucks,
            query: {
                NotSoldRetired: 1
            },
            searchMap: (it) => {
                return ({
                    label: it.TruckNumber + " - " + it.Manufacturer + ", " + it.Model + ", " + it.Year + " (VIN:" + it.VIN + ")",
                    value: it.TruckID,
                    metadata: it
                })
            }
        },
        TrailerID: {
            api: 'api/' + Resources.Trailers,
            query: {
                NotSoldRetired: 1
            },
            searchMap: (it) => {
                return ({
                    label: it.TrailerNumber + " - " + it.Manufacturer + ", " + it.Model + ", " + it.Year + " (VIN:" + it.VIN + ")",
                    value: it.TrailerID,
                    metadata: it
                })
            }
        },
        RequestedServiceCategoryID: getLookup('RequestedServiceCategory'),
        VMRSCode: {
            api: 'api/' + Resources.WorkOrdersVMRSCode,
            query: {},
            searchMap: (it) => {
                return ({
                    label: it.value,
                    value: it.value,
                })
            }
        },
        CustomerID: {
            api: 'api/' + Resources.CustomersQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName || item.Organization,
                value: item.CustomerID
            })
        },
        OfficeID: {
            api: 'api/' + Resources.OfficesQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.OfficeName,
                value: item.OfficeID
            })
        },
        ContactGroupID: {
            api: 'api/' + Resources.ContactGroupsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.ContactGroupName,
                value: item.ContactGroupID
            })
        },
        ChargeContactID: {
            api: "api/" + Resources.Contacts,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + " " + item.LastName + " (" + item.Email + ")",
                Contact: item
            })
        },
        ChargeOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (it) => ({
                label: it.LegalName + " - " + (it.AddressName ?? "")
                    + ", " + (it.CityName ?? "")
                    + " " + (getLookup('State')[it.StateID] ?? "") + " "
                    + (it.PostalCode ?? ""),
                value: it.OrganizationID,
                metadata: it,
            })
        }
    }
}

const updateStatusOptions = (workOrderStatusID, translate) => {

    const translatedOptions = translateConstant(WORK_ORDER_STATUS_OPTIONS, translate);
    return Object.keys(translatedOptions)
        .map(key => {
            let option = {};
            option.value = key;
            option.label = translatedOptions[key];

            return option
        });
}
