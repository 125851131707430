import React from "react";
import { Link } from "react-router-dom";
import Env from "../../../util/env";
import Resources from "../../../data/services/resources";
import {getJWT, getUser} from "../../util/util-auth";

export default function CompanyLogo({className}) {
    const token = getJWT().access_token;
    const ContactID = getUser("Contact.ContactID");
    const CompanyName = getUser("Contact.CompanyName");
    const homePage = process.env.REACT_APP_HOME_PATH ?? '/home';

    return (
        <Link
            className={className}
            to={homePage}
        >
            <img className={"max-h-12 text-inverse flex items-center w-full"} src={Env.getApiUrl("api/" + Resources.CompanyImage, {
                token: token,
                ContactID: ContactID
            })}
                 alt={CompanyName}
            />
        </Link>
    )
}
