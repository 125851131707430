import React, {Component} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import LocalStorage from "../../../util/localStorage";
import MaskedTextInput from "react-text-mask";
import {FieldClearIcon} from "../../../../data/themes/icons";
import {getUserDateTimeFormat, toBackDateTime} from "../../../util/util-dates";
import {classNames, handleMaskedFocus} from "../../../util/util-helpers";
import {scrollIntoView} from "../../../util/util-vanilla";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../../util/util-constants";

export default class FieldDate extends Component {
    constructor(props) {
        super(props);

        this.ref = this.props?.innerRef ?? React.createRef();
    }

    onKeyDown = (e) => {
        if ((e.keyCode === 9 || e.which === 9) && this.ref.current.isCalendarOpen()) {
            this.ref.current.setOpen(false);
        }

        if ((e.keyCode === 40 || e.which === 40) && !this.ref.current.isCalendarOpen()) {
            this.ref.current.setOpen(true);
        }

        if (e.key === "-" && this.props.value) {
            e.preventDefault();
            if (moment(this.props.value, DEFAULT_DATABASE_DATETIME_FORMAT).isValid()) {
                this.props.onChange(this.props.name, moment(this.props.value, DEFAULT_DATABASE_DATETIME_FORMAT)
                    .subtract(1, "day")
                    .format(DEFAULT_DATABASE_DATETIME_FORMAT)
                );
            }
        }

        if (e.key === "+" && this.props.value) {
            e.preventDefault();
            if (moment(this.props.value, DEFAULT_DATABASE_DATETIME_FORMAT).isValid()) {
                this.props.onChange(this.props.name, moment(this.props.value, DEFAULT_DATABASE_DATETIME_FORMAT)
                    .add(1, "day")
                    .format(DEFAULT_DATABASE_DATETIME_FORMAT)
                );
            }
        }
    };

    render() {
        const timeformatlength = getUserDateTimeFormat()?.split(" ")?.length

        return (
            <div className='w-full'>
                <div className="relative">
                    <DatePicker
                        shouldCloseOnSelect={this.props.shouldCloseOnSelect} // NOTE: in order for this to work, component MUST NOT be wrapped by label (replace it with span)
                        ref={this.ref}
                        preventOpenOnFocus={true}
                        disabledKeyboardNavigation={this.props.disabledKeyboardNavigation}
                        autoFocus={this.props.autoFocus}
                        selected={moment(this.props.value).isValid() ? moment(this.props.value).toDate() : null}
                        onChange={(date) => {
                            this.props.onChange(this.props.name, date ? toBackDateTime(date) : "");
                        }}
                        minDate={this.props.minDate ? moment(this.props.minDate).toDate() : moment().subtract(100, 'years').toDate()}
                        maxDate={this.props.maxDate ? moment(this.props.maxDate).toDate() : moment().add(100, 'years').toDate()}
                        disabled={this.props.disabled}
                        showTimeSelect={this.props.showTimeSelect}
                        showTimeSelectOnly={this.props.showTimeSelectOnly}
                        timeFormat={LocalStorage.has('user') ? timeformatlength == 2 ? "HH:mm" : "h:mm aa" : "h:mm aa"}
                        dateFormat={this.props.showTimeSelect ? LocalStorage.has('user') ? LocalStorage.get('user')?.Contact?.DateTimeFormat?.replace(/mm/i, 'MM').replace(/dd/i, 'dd').replace(/yyyy/i, 'yyyy').replace(/a/i, 'aa') : "MM/dd/yyyy h:mm aa" : LocalStorage.has('user') ? LocalStorage.get('user')?.Contact.DateTimeFormat?.split(' ')?.[0]?.replace(/mm/i, 'MM')?.replace(/dd/i, 'dd')?.replace(/yyyy/i, 'yyyy') : "MM/dd/yyyy"}
                        timeIntervals={15}
                        timeCaption="time"
                        showMonthDropdown
                        showYearDropdown={!this.props.disableYearDropdown}
                        onKeyDown={this.onKeyDown}
                        popperPlacement={this.props.placement ? this.props.placement : "bottom-start"}
                        className={
                            classNames(
                                "form-control text-center relative",
                                this.props.addClass,
                                (this.props.errorMessage ? " is-invalid" : ""))
                        }
                        customInput={
                            <MaskedTextInput
                                type="text"
                                mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                showMask
                            />
                        }
                        excludeDates={this.props.excludeDates}
                        onFocus={(event) => {
                            if (this.props.onFocus) {
                                this.props.onFocus(event);
                            } else {
                                handleMaskedFocus(event);
                            }

                            scrollIntoView(event);
                        }}

                        inline={this.props.inline}
                    />

                    {this.props.isClearable && this.props.value && !this.props.disabled && (
                        <button
                            tabIndex={this.props.isCloseFocusable === false ? "-1" : null}
                            onClick={() => this.props.onChange(this.props.name, "")}
                            className="absolute z-10 inset-y-0 right-0 px-3 flex items-center cursor-pointer hover:text-tm-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-tint">
                            <FieldClearIcon/>
                        </button>
                    )}
                </div>
                
                {this.props.errorMessage && (
                    <div className={"text-red-600 font-semibold display-block"}>
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Field required*"}
                    </div>
                )}
            </div>
        )
    }
}

