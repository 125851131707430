import React, {useState} from "react";
import {Field} from "../../../../data/services/fields";
import Tippy from "@tippyjs/react";
import {toFrontDateTime} from "../../../../common/util/util-dates";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {genericMoneyFormatter} from "../../../../common/util/util-vanilla";
import {checkPerm, getDefaultTableOptions, getProp} from "../../../../common/util/util-helpers";
import {useDispatch, useSelector} from "react-redux";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import {DELETE_PERM} from "../../../../util/util-constants";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {deleteDialogResource} from "../../../../data/actions/dialogResource";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../../common/components/resource-table";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import InfoParagraph from "../../../../common/components/info-paragraph";

export default function ReviewNoteReceivableModalActualPaymentsTab({translate}) {
    const dispatch = useDispatch();
    const dialogResource = useSelector(state => state.dialogResource);
    const isLoading = dialogResource.isLoading;
    const data = getProp(dialogResource, 'data.Payments', []);

    const [selectedItem, setSelectedItem] = useState({});
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [offset, setOffset] = useState(0);
    const limit = 12;
    let dataSlice = [];

    function handleToggleConfirmDialog(item) {
        setSelectedItem(item);
        setIsConfirmModalOpen(!isConfirmModalOpen);
    }

    const tableOptions = getTableOptions("note_receivable_modal_actual_payments", translate)

    if (data?.length) {
        dataSlice = data.slice(Number(offset), Number(offset) + Number(limit));
    }

    function getTableOptions(pagePath, translate) {
        return getDefaultTableOptions(getFields(translate), {
            behaviour: {
                rowSelect: true,
            }
        }, pagePath, translate)
    }

    const handleUpdateOffset = (name, value) => {
        if (name === 'offset') {
            setOffset(value)
        }
    }

    function getFields() {
        return {
            Date: new Field('Date', '', [], false, 'date'),
            Amount: new Field('Amount', '', [], false, 'money', {
                render: (item) => {
                    return <div className={"text-right"}>{genericMoneyFormatter(item.AmountTotal)}</div>
                }
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search'),
            Description: new Field('Description', '', ['empty'], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }
    }

    return <>
        {(data.length > 0) && !isLoading && (
            <div className="p-1">
                <InfoParagraph>
                    {translate("text.receivable_payment_delete")}
                </InfoParagraph>
            </div>
        )}

        {(data.length === 0) && !isLoading && (
            <div className="h-full flex justify-center items-center">
                <NoRecordsTable
                    className="pt-12 px-6 pb-6"
                    show={true}
                    canCreate={false}
                    title={translate('text.no_matching_records')}
                />
            </div>
        )}

        <ResourceTable
            data={dataSlice}
            options={tableOptions}
            fields={getFields()}
            isLoading={dialogResource?.isLoading}
            actions={[
                {
                    action: handleToggleConfirmDialog,
                    hasPerm: checkPerm(Resources.RentalNoteReceivablePayment, DELETE_PERM),
                    icon: TrashIcon, // make this a function
                    visible: (it) => {
                        if (it.NoteReceivablePaymentID != data?.[data.length - 1]?.NoteReceivablePaymentID) {
                            return null
                        }
                        return it.NoteReceivablePaymentID
                    },
                    label: false, // make this a function
                    title: translate('btn.delete'),
                    disabled: false,
                    class: false,
                    iconClass: false
                }
            ]}
            translate={translate}
        />

        {(data?.length > limit) && (
            <div className="px-6 py-2">
                <Pagination
                    hideRowsPerPage
                    count={data?.length ?? 0}
                    handleQueryChange={
                        (name, value) => handleUpdateOffset(name, value)}
                    pageLimit={limit}
                    pageOffset={offset}
                    translate={translate}
                />
            </div>
        )}

        <ModalConfirm
            title={translate('text.Confirm')}
            show={isConfirmModalOpen}
            text={translate('message.confirm_delete_payment')}
            onClose={() => {
                setIsConfirmModalOpen(false);
                setSelectedItem({});
            }}
            buttonLabel={translate('btn.confirm')}
            closeButtonLabel={translate('btn.Cancel')}
            translate={translate}
            onConfirm={() => {
                dispatch(deleteDialogResource({
                    user: LocalStorage.get('user'),
                    query: {
                        NoteReceivablePaymentID: selectedItem.NoteReceivablePaymentID
                    },
                    resource: Resources.RentalNoteReceivablePayment,
                    piggyResource: Resources.RentalNoteReceivable,
                    piggyQuery: {
                        NoteReceivableID: selectedItem.NoteReceivableID
                    },
                    errorMessage: true,
                    successMessage: translate('text.PaymentDeletedSuccessfully')
                }))

                setIsConfirmModalOpen(false);
                setSelectedItem({});
            }}
        />
    </>
}