import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {showMapDialog, showModal} from '../../../data/actions/ui'
import {download} from '../../../data/actions/download'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import CreateOfficeDialog from '../../../common/components/layout/global-dialogs/create-update-office-dialog'
import {MapPinIcon} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import RectangleGroupIcon from '@heroicons/react/20/solid/RectangleGroupIcon'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'

import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {HourglassIcon} from "../../../data/themes/icons";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import {getHistoryResource} from "../../../data/actions/historyResource";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import Layout from "../../../common/components/layout";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Modal from "../../../common/components/modal";
import ModalHeader from "../../../common/components/modal/modal-header";
import OfficeCardDialog from "../../../common/components/modal/office-card";

class Offices extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'OfficeName',

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            createUpdateDialog: false,
            createUpdateDialogHideAnimation: false,
            confirmDialog: false,
            confirmDialogHideAnimation: false,
            breakpoint: {},
            selectedItem: {},
            isOfficeCardModalOpen: false
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.handleCheckIfRedirected()
        this.fetchData()
    }

    /** Data Events
     ================================================================= */

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'offices_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'offices_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate("message.confirm_archive_generic")} ${item.OfficeName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({
                            OfficeID: item.OfficeID
                        }, this.getQuery()),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.OfficeName]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate("message.are_you_sure_restore_office")} ${item.OfficeName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            OfficeID: item.OfficeID,
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: `${item.CityName} ${item.OfficeName} office restored`,
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleLocationMapView = (item) => {
        if ((item.Latitude === null || item.Latitude === '0') || item.Longitude === null || item.Longitude === '0') {
            return null;
        }

        this.props.dispatch(showMapDialog(item));
    }

    handleShowCreateUpdateDialog = (selectedItem = null) => {
        this.setState({selectedItem: selectedItem, createUpdateDialog: true}, () => {
            if (LocalStorage.has('selectedItem')) LocalStorage.remove('selectedItem')
        })
    }

    handleCloseCreateUpdateDialog = () => {
        this.setState({
            createUpdateDialog: false,
            selectedItem: null,
            prefilled: null
        })
    }

    handleOpenInfoDialog = (item) => {
        this.setState({
            selectedItem: item,
            isOfficeCardModalOpen: true
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    viewContactCard = (item) => {
        this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleCheckIfRedirected = () => {
        if (LocalStorage.has('selectedItem')) {
            this.handleShowCreateUpdateDialog(LocalStorage.get('selectedItem'))
        }
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    /** Helpers
     ================================================================= */
    getFields = () => {
        return {
            OfficeName: new Field('OfficeName', '', ['empty']),
            OfficeNumber: new Field('OfficeNumber', '', ['empty']),
            AddressName: new Field('AddressName', '', ['empty']),
            CityName: new Field('CityName', '', ['empty']),
            State: new Field('State', '', ['empty']),
            PhoneNumber: new Field('PhoneNumber', '', ['empty'], false, 'phone'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'date'),
        }
    }

    getFieldTypes = () => {
        let fieldsCombined = Object.assign({}, this.getFields())

        fieldsCombined = Object.values(fieldsCombined).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})

        fieldsCombined['CountryID'] = 'select'
        fieldsCombined['StateID'] = 'select'
        fieldsCombined['AreaCode'] = 'select'
        fieldsCombined['PhoneNumber'] = 'phone'
        fieldsCombined['DivisionID'] = 'select'
        fieldsCombined['IsPaymentOverrideActive'] = 'checkbox'

        return Object.assign({}, fieldsCombined);
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'query', 'showArchived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getResource = () => {
        return Resources.Offices
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, historyResource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >

                <Page>
                    <PageHeader
                        title={translate('page.heading.offices')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleShowCreateUpdateDialog()}
                        buttonHidden={!checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                infoBox={(
                                    <div>
                                        <p>Offices aid in grouping.</p>
                                        <p>Each office can be assigned to one division.</p>
                                    </div>
                                )}
                                InfoBoxBtnLabel={translate('btn.read_more')}
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleFilterClear}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            {/*<Tippy content={translate('text.download_template')}>*/}
                            {/*    <button*/}
                            {/*        className="btn-icon"*/}
                            {/*        onClick={this.downloadExcelTemplate}*/}
                            {/*    >*/}
                            {/*        <RectangleGroupIcon className="w-5 h-5"/>*/}
                            {/*    </button>*/}
                            {/*</Tippy>*/}

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(this.getResource(), CREATE_PERM) ? this.handleShowCreateUpdateDialog : null}

                            onView={this.handleOpenInfoDialog}
                            onEdit={this.handleShowCreateUpdateDialog}
                            onDelete={this.archiveResource}
                            onRestore={this.restoreFromArchive}

                            hasViewPerm={checkPerm(this.getResource(), READ_PERM)}
                            hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                            hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                            hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}

                            actions={[
                                {
                                    action: this.handleLocationMapView,
                                    icon: MapPinIcon, // make this a function
                                    visible: (it) => (it.Latitude && it.Longitude),
                                    label: false, // make this a function
                                    title: translate('text.map'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                            ]}
                        />

                        {/*Table footer*/}

                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >

                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new office'}
                            btnLabel="Create office"
                            onBtnClick={() => {
                                this.setState({
                                    prefilled: this.state.queryFilterFields.query.value,
                                    createUpdateDialog: true
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>
                    <Modal
                        show={this.state.createUpdateDialog}
                        widthClass={'max-w-5xl'}
                        onClose={this.handleCloseCreateUpdateDialog}
                    >
                        <CreateOfficeDialog
                            selectedItem={this.state.selectedItem}
                            prefilled={this.state.prefilled}
                            translate={translate}
                            close={this.handleCloseCreateUpdateDialog}
                            query={this.getQuery()}
                        />
                    </Modal>

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.setTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    <Modal
                        show={this.state.historyModalOpen}
                        widthClass={"max-w-5xl"}
                        onClose={() => this.setState({historyModalOpen: false})}
                        translate={translate}
                    >
                        {this.state.historyModalOpen && (
                            <HistoryModal
                                onClose={() => this.setState({historyModalOpen: false})}
                                translate={translate}
                                title={translate("text." + Resources.OfficesHistory)}
                                tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                                filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                                onFetchData={(query) => {
                                    this.props.dispatch(getHistoryResource({
                                        user: LocalStorage.get('user'),
                                        query: Object.assign({}, query, {
                                            id: this.state.selectedItem.OfficeID
                                        }),
                                        resource: Resources.OfficesHistory
                                    }))
                                }}
                                data={getProp(historyResource, "data.list", [])}
                                count={getProp(historyResource, "data.count", 0)}
                                isLoading={getProp(historyResource, "isLoading", false)}
                                showUserAvatar

                                onRowClick={(item) => {
                                    this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                                }}
                                fieldTypes={this.getFieldTypes()}
                            />
                        )}
                    </Modal>

                    <Modal
                        show={this.state.isOfficeCardModalOpen}
                        widthClass={"max-w-3xl"}
                        translate={translate}
                        onClose={() => this.setState({isOfficeCardModalOpen: false})}
                    >
                        <ModalHeader
                            title={translate("text.office_info")}
                            onClose={() => this.setState({isOfficeCardModalOpen: false})}
                        />

                        <OfficeCardDialog
                            onClose={() => this.setState({isOfficeCardModalOpen: false})}
                            translate={translate}
                            office={this.state.selectedItem}
                        />
                    </Modal>
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(Offices)
