import React, {Component} from "react";
import LocalStorage from "../../../util/localStorage";
import NoRecords from "../../no-records-found/no-records";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {DEFAULT_CRUD_STATE, READ_PERM} from "../../../../util/util-constants";
import {getDialogResource} from "../../../../data/actions/dialogResource";
import {showGlobalModal} from "../../../../data/actions/ui";
import {checkPerm, getProp} from "../../../util/util-helpers";
import ResourceTable from "../../resource-table";
import TableCardFooter from "../../resource-table/table-components/table-card-footer";
import Pagination from "../../resource-table/table-components/pagination";
import ModalConfirm from "../modal-confirm";
import Resources from "../../../../data/services/resources";

export default class TrucksTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "TruckNumber",
            fields: this.getFields(),

            // Delete modal
            confirmModal: false,
            confirmModalAnimation: false
        }
    }

    componentDidMount = () => {
        this.getId() && this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            resource: this.getResourceName(),
            query: this.getQuery(),
        }));
    };

    handleShowDetails = (item) => {
        this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID));
    };

    handleShowConfirmDialog = (submit = null) => {
        this.setState({
            confirmModal: submit
        })
    };

    hideConfirmDialog = () => {
        this.setState({
            confirmModalAnimation: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    confirmModalAnimation: false,
                    confirmModal: false
                })
            }, 500)
        })
    };

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData();
        })
    };

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => {
            this.fetchData();
        })
    };

    getFields = () => {
        return this.props.fields
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            limit: this.state.limit,
            id: this.getId()
        }
    }

    getPrimaryKey = () => {
        return this.props.primaryKey
    }

    getId = () => {
        return this.props.id;
    }

    getResourceName = () => {
        return this.props.resourceName
    }

    render() {
        const {translate, dialogResource} = this.props;
        const dataItems = getProp(dialogResource, "data.list", [])
        const isLoading = (dialogResource.isLoading);
        const count = getProp(dialogResource, "data.count", 0);
        return (
            <React.Fragment>
                <div className="flex justify-between items-center mb-3 p-4">
                    <h2 className="text-tm-gray-900 text-2xl">{translate("page.heading.trucks")}</h2>

                    <button className="btn btn-header ml-auto mr-5" onClick={this.fetchData}>
                        <ArrowPathIcon className="w-5 h-5"/>
                    </button>
                </div>

                <NoRecords
                    show={!dataItems.length && !isLoading}
                    addClass="p-4"
                    title="No trucks"
                    text={translate("text.noTrucksPresent")}
                />

                <div className="relative">
                    <ResourceTable
                        data={dataItems}
                        count={count}
                        options={{
                            style: {horizontalLines: true, floatingActions: true},
                            behaviour: {hasVirtualRows: true}
                        }}
                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        page={this.state.paginationPage}

                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onView={checkPerm(Resources.Contacts, READ_PERM) ? this.handleShowDetails : null}
                    />

                    <TableCardFooter
                        show={!(!dataItems.length && !isLoading) && count > this.state.limit}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            pageLimit={this.state.limit}
                            translate={translate}
                        />
                    </TableCardFooter>
                </div>

                {!!this.state.confirmModal && (
                    <ModalConfirm
                        text={this.state.confirmText}
                        submit={this.state.confirmModal}
                        cancel={this.hideConfirmDialog}
                        translate={translate}
                        ui={{
                            confirmDialogHideAnimation: !!this.state.confirmModalAnimation
                        }}
                    />
                )}
            </React.Fragment>
        )
    }
}
