import React from "react";
import {classNames} from "../../../util/util-helpers";

export default function TableCardFooter({show, children, addClass}) {
    if (show === false) return null;

    return (
        <div
            className={
                classNames(
                    "bg-inverse px-5 py-2 flex items-center justify-between rounded-card md:rounded-t-none mt-5 md:mt-0",
                    addClass
                )}
        >
            {children}
        </div>
    )
}
