import React, {useEffect} from "react";
import LoaderSmall from "../../loader/loader-small";
import Resources from "../../../../data/services/resources";
import LocalStorage from "../../../util/localStorage";
import {checkPerm, classNames, getProp, openInNewTab} from "../../../util/util-helpers";
import {Field} from "../../../../data/services/fields";
import DataCard from "../../../../common/components/display-data/data-card";
import {getDialogInfoResource} from "../../../../data/actions/dialogInfoResource";
import ModalFooter from "../modal-footer";
import {UPDATE_PERM} from "../../../util/util-consts";
import ResourceTableTags from "../../resource-table/table-components/resource-table-tags";
import moment from "moment";
import {toFrontDate, toFrontDateTimeFromUTC} from "../../../util/util-dates";
import {numberWithCommas} from "../../../util/util-formaters";

export default function TruckCard({translate, dialogInfoResource, TruckID, onClose, dispatch}) {
    useEffect(() => {
        dispatch(getDialogInfoResource({
            user: LocalStorage.get("user"),
            resource: Resources.TrucksInfo,
            query: {id: TruckID}
        }));
    }, [TruckID, dispatch])

    const handleUpdateButtonClick = () => {
        openInNewTab("/trucks/info/" + TruckID)
    }

    const data = getProp(dialogInfoResource, "data", {});
    console.log("Data", data)
    const ColorTags = getProp(dialogInfoResource, 'data.ColorTag', "")
    const isLoading = getProp(dialogInfoResource, "isLoading", true);
    const truckFields = Object.values(getFields()).filter(it => {
        if (it.name === "OwnedBy") {
            return data.OwnedByOrganizationID ?? data.OwnedByContactID;
        }
        return data[it.name] !== undefined
    });

    return (
        <React.Fragment>
            <div className="relative p-3 min-h-[24rem]">
                {isLoading && (
                    <div className="text-center absolute inset-0 flex items-center justify-center">
                        <LoaderSmall/>
                    </div>
                )}

                {!isLoading && (
                    <React.Fragment>
                        <div className={'max-w-md mx-auto my-10'}>
                            <div className={"flex"}>
                                <div className="text-2xl font-bold truncate mr-6 mb-2 text-tm-gray-900">
                                    {data["TruckNumber"]}
                                </div>

                                <div>
                                    <ResourceTableTags data={ColorTags} addClass="py-1 mx-2"/>
                                </div>
                            </div>

                            <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                                {truckFields.map((field, i) => {
                                    return (
                                        <DataCard
                                            key={field.name}
                                            displayField={field}
                                            fieldsData={data}
                                            className={classNames("px-3 py-1.5 flex items-center px-6", i < truckFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                            translate={translate}
                                        />
                                    )
                                })}
                            </div>

                            <div className="mt-6">
                                <p className="font-bold leading-5 mb-2">{translate("field.LastGPSLocation")}</p>

                                {data.LocationDate ?
                                    (<GPSLocation item={data} translate={translate}/>)
                                    :
                                    (<div
                                        className="bg-inverse shadow-card px-6 py-1.5 border border-tm-gray-300 rounded-xl">
                                        <p className={"text-left font-bold"}>No data</p></div>)
                                }
                            </div>

                            {!!data?.Notes && (
                                <div className="mt-6">
                                    <p className="font-bold leading-5 mb-2">{translate("field.Notes")}</p>
                                    <div
                                        className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-6 py-3 flex items-center">
                                        {data?.Notes}
                                    </div>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>

            <ModalFooter
                buttonLabel={checkPerm(Resources.TrucksInfo, UPDATE_PERM) && translate("btn.view")}
                onButtonClick={handleUpdateButtonClick}
                closeButtonLabel={translate("btn.cancel")}
                onClose={onClose}
            />
        </React.Fragment>
    )
}

const getFields = () => {
    return {
        TruckNumber: new Field('TruckNumber', '', [''], false, "text",),
        Model: new Field('Model', '', [''], false, "text",),
        Year: new Field('Year', '', [''], false, "text", {label: "model_year"}),
        Manufacturer: new Field('Manufacturer', '', [''], false, "text",),
        Engine: new Field('Engine', '', [''], false, "text"),
        TruckLicenceNumber: new Field('TruckLicenceNumber', '', [''], false, "text"),
        VIN: new Field('VIN', '', [''], false, "text"),
        TruckOwnershipType: new Field('TruckOwnershipType', '', [''], false, "text"),
        OwnedBy: new Field('OwnedBy', '', [''], false, "custom", {
            render: (it) => {
                return (
                    <div>
                        {it.OwnedByOrganization ?? it.OwnedByContact}
                    </div>
                )
            }
        }),
        Mileage: new Field('Mileage', '', [''], false, 'custom', {
            render: (item) => {
                if (item.Mileage) {
                    return (
                        <div>

                            {
                                <div>{numberWithCommas(item.Mileage, 0) ?? ""} mi. {item.OdometerDate ? "(" + toFrontDate(item.OdometerDate) + ")" : ''}</div>}
                        </div>
                    )

                }
            }, label: "Odometer"}),
    }
};

function GPSLocation({item, translate}) {
    const LocationDate = moment(item.LocationDate);
    const currentDate = moment();

    const dateDifference = moment.duration(LocationDate.diff(currentDate)).asDays();

    return (
        <div className="bg-inverse shadow-card px-6 py-1.5 border border-tm-gray-300 rounded-xl">
                <div
                    className={classNames("text-left flex space-x-1 font-bold", (dateDifference < -7) ? "text-red-500" : 'text-primary')}>
                    {item?.CityName || item?.State || item?.PostalCode ?
                        <div>{item?.CityName ? item.CityName + ", " : ""} {item?.State} {item?.PostalCode}</div> : ""}
                    {(item.LocationDate ?
                        <div>- {toFrontDateTimeFromUTC(item.LocationDate)}</div> : undefined)}
                </div>

                <div
                    className={classNames("text-left flex space-x-1 font-bold", (dateDifference < -7) ? "text-red-500" : 'text-primary')}>
                    {dateDifference < -7 && translate("text.GPS_older_than_7_days")}
                </div>
            </div>
            )}