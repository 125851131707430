import {getProp, scrollToElementBottom} from "../../../../util/util-helpers";
import LocalStorage from "../../../../../util/localStorage";
import CommentCard from "./cards/comment-card";
import {deleteResource} from "../../../../../data/actions/resource";
import Resources from "../../../../../data/services/resources";
import {showModal} from "../../../../../data/actions/ui";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDialogResource} from "../../../../../data/actions/dialogResource";

export default function TaskFormComments({
                                             onEditComment,
                                             onDeleteComment,
                                             onSubmitComment,
                                             selectedItem,
                                             TaskID,
                                             afterConfirmDelete,
                                             translate
                                         }) {
    const myID = Number(LocalStorage.get('user')?.Contact?.ContactID);
    const dispatch = useDispatch();

    const ui = useSelector((state) => state.ui);
    const taskResource = useSelector((state) => state.taskResource);
    const dialogResource = useSelector((state) => state.dialogResource);

    const [comments, setComments] = useState([]);

    function fetchComments() {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            query: {
                id: TaskID,
            },
            resource: Resources.TasksInfo
        }))
    }

    useEffect(() => {
        if (
            ui?.notification?.notificationMetadata?.type === "NEW_COMMENT_ON_TASK" &&
            ui?.notification?.notificationMetadata?.resource
            && Number(ui.notification.notificationMetadata.resource.split("=")[1]) === TaskID
        ) {
            fetchComments();
        }
    }, [ui]);

    useEffect(() => {
        if (dialogResource?.data?.comments) {
            setComments(dialogResource?.data?.comments);
            setTimeout(() => {
                scrollToElementBottom('commentSection');
            }, 300);
        }
    }, [dialogResource]);

    useEffect(() => {
        if (taskResource?.data?.comments) {
            setComments(   getProp(taskResource, 'data.comments', []));
            setTimeout(() => {
                scrollToElementBottom('commentSection');
            }, 300);
        }
    }, [taskResource]);

    return comments.map((item) => {
        let isMe = Number(item?.ContactID ?? 0) === myID;

        return (
            <CommentCard
                key={item?.TaskCommentID}
                item={item}
                isMe={isMe}
                editComment={onEditComment}
                confirmDeleteCommentHandler={() =>
                    onDeleteComment(() => {
                        dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: {
                                id: item['TaskCommentID']
                            },
                            taskQuery: {
                                id: TaskID
                            },
                            resource: Resources.TasksComment,
                            taskPiggyResource: Resources.TasksInfo,
                            errorMessage: true,
                            successMessage: translate('text.successfully_deleted_comment'),
                        }))

                        afterConfirmDelete();
                    })
                }
                editCommentHandler={onSubmitComment}
                translate={translate}
                editedComment={selectedItem}
                onAvatarClick={() => dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))}
            />
        )
    })
}