import React, {useEffect, useRef, useState} from "react";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import {
    checkPerm,
    getDefaultQueryFields,
    getDefaultTableOptions,
    getUser,
    groupListBySCAC,
    updateQuery
} from "../../../util/util";
import {useDispatch, useSelector} from "react-redux";
import useQuery from "../../../hooks/use-query";
import {
    classNames, convertReminderFields,
    getLookup,
    getProp,
    longTableColumn,
    minutesToDayHourMinutes,
    resourceIsCreated
} from "../../../common/util/util-helpers";
import {ChevronUpIcon as ChevronUpSolidIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {cloneDeep, genericMoneyFormatter} from "../../../common/util/util-vanilla";
import {
    CREATE_PERM,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    READ_PERM,
    UPDATE_PERM,
    VIN_CODE_FIELD_LENGTH
} from "../../../util/util-constants";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import ResourceList from "../../../common/components/layout/layout-components/resource-list";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import WorkOrderStatusBadge from "../../../common/components/badge/work-order-status-badge/index";
import Page from "../../../common/components/layout/layout-components/page";
import Tippy from "@tippyjs/react";
import {getUserDateTimeFormat, getUserTimezone, toFrontDateTimeFromUTC} from "../../../common/util/util-dates";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import TableReminder from "../../../common/components/resource-table/table-components/table-reminder";
import moment from 'moment-timezone';
import ModalDefault from "../../../common/components/modal/modal-default";
import FieldsForm from "../../../common/components/fields/fields-form";
import FieldSwitchLabel from "../../../common/components/fields/field-switch/field-switch-label";
import LocalStorage from "../../../common/util/localStorage";
import {Loader, LoaderLarge} from "../../../common/components/loader";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../common/util/util-consts";
import {createResourceDiff, getResourceDiff} from "../../../data/actions/resourceDiff";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import Bot from "../../../assets/images/bot.png";
import ErrorCodeHelpAiModal from "../work-orders-single-view/work-orders-labor-parts-tab/error-code-help-ai-modal";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import {clearThirdResource, getThirdResource} from "../../../data/actions/thirdResource";
import WidgetStats from "../../../common/components/widgets/widget-stats";
import banknotesIcon from "@heroicons/react/24/outline/esm/BanknotesIcon";
import PresentationChartLineIcon from "@heroicons/react/24/outline/PresentationChartLineIcon";

const CheckboxCell = ({value}) => {
    return value ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
        : <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>
}
export default function WorkOrdersView({history, match, translate}) {
    const autoRefreshTime = 60000;
    const autoRefreshTimeout = useRef(setInterval(null));

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resourceDiff);
    const thirdResource = useSelector((state) => state.thirdResource);
    const isLoading = getProp(resource, "isLoading", true);
    const count = getProp(resource.data, "count", 0);
    const [selectedRows, setSelectedRows] = useState({});
    const [isErrorCodeHelpModalOpen, setIsErrorCodeHelpModalOpen] = useState(false);
    const [DecodeVINModalOpen, setDecodeVINModalOpen] = useState(false);
    const [areStatsVisible, setAreStatsVisible] = useState(false);
    const workOrderStatuses = getLookup("WorkOrderStatus") ?? {};
    const dataVIN = getProp(thirdResource, 'data', [])
    const isLoadingThird = getProp(thirdResource, 'isLoading', false)

    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath());
    const query = Object.assign({}, FieldsManager.getFieldKeyValues(queryFields), {
        searchFields: JSON.stringify({
            WorkOrderStatusID: queryFields.WorkOrderStatusID?.value,
            TruckID: queryFields.TruckID?.value?.value,
            TrailerID: queryFields.TrailerID?.value?.value,
            IsInvoiced: queryFields.IsInvoiced?.value ? ['IsInvoiced', '=', queryFields.IsInvoiced?.value.toString()] : "",
            IsAuthorized: queryFields.IsAuthorized?.value ? ['IsAuthorized', '=', queryFields.IsAuthorized?.value.toString()] : "",
            ChargeContactID: queryFields.ChargeContactID?.value,
            ChargeOrganizationID: queryFields.ChargeOrganizationID?.value?.value,
            OfficeID: queryFields.OfficeID?.value?.value,
            ContactGroupID: queryFields.ContactGroupID?.value?.value,
            ...(queryFields.ShowOnlyCompleted?.value && {ShowOnlyCompleted: ['WorkOrderStatusID', '=', 14]}),
            ...(queryFields.ShowOnlyActive?.value && {ShowOnlyActive: ['WorkOrderStatusID', '<>', 14]}),
            ...(!!queryFields?.StartDate?.value && {StartDate: ['CreatedDate', '>=', queryFields?.StartDate?.value]}),
            ...(!!queryFields?.EndDate?.value && {EndDate: ['CreatedDate', '<=', queryFields?.EndDate?.value]}),
        })
    });

    const [tableOptions, setTableOptions] = useState(
        getDefaultTableOptions(getFields(), {
            behaviour: {
                rowSelect: true,
                hasMenu: true,
                canAdjustWidth: true
            }
        }, getListPath(), translate)
    );
    const [selectedOrganization, setSelectedOrganization] = useState({})
    const [isOrganizationInfoDialogOpen, setIsOrganizationInfoDialogOpen] = useState(false);

    const [selectedReminder, setSelectedReminder] = useState(null);
    const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);

    const [updateReminderFields, setUpdateReminderFields] = useState(() => getUpdateReminderFields())

    const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(LocalStorage.get(getListPath() + "_query")?.isAutoRefreshEnabled ?? false);

    useEffect(() => {
        fetchData(dispatch, query)
    }, [queryFields]);
    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResourceDiff({
            user: getUser(),
            resource: getResourcePath(),
            data: {isInitial: true},
            query: query
        }))

        if (isAutoRefreshEnabled) {
            clearTimeout(autoRefreshTimeout.current);
            autoRefreshTimeout.current = setInterval(autoFetchData, autoRefreshTime);
        }
    }

    const autoFetchData = () => {
        dispatch(getResourceDiff({
            user: LocalStorage.get('user'),
            resource: getResourcePath(),
            data: {
                deltaKey: "WorkOrderID",
                compareKeys: Object.values(tableOptions?.columns).filter(it => it.show).map(it => it.name)
            },
            query: query
        }))
    }

    const fetchVINData = (VIN) => {
        dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.DecodeVIN,
            query: {VIN: VIN}
        }))
    }

    function handleReminderSubmit() {
        const validatedUpdateReminderFields = FieldsManager.validateFields(
            Object.assign({}, updateReminderFields)
        );

        if (FieldsManager.checkFieldsForErrors(validatedUpdateReminderFields)) {
            let params = FieldsManager.getFieldKeyValues(updateReminderFields);
            params.WorkOrderID = selectedReminder.WorkOrderID;
            params.id = selectedReminder.WorkOrderID;

            params = convertReminderFields(params);

            dispatch(createResourceDiff({
                user: LocalStorage.get('user'),
                params: params,
                resource: Resources.WorkOrderNotes,
                piggyResource: getResourcePath(),
                query: query,
                successMessage: translate("text.reminder_updated"),
                errorMessage: true
            }));

            setIsReminderModalOpen(false);
        } else {
            setUpdateReminderFields(validatedUpdateReminderFields);
        }
    }

    /** UI events
     ================================================================= */
    const handleCreateResourceClick = () => {
        history.push('/work-orders-create/');
    }

    const handleEditResourceClick = (item) => {
        history.push('/work-orders/' + item.WorkOrderID);
    }

    const handleSelectRowClick = (item) => {
        const itemID = item.WorkOrderID

        let selectedRowsUpdate = cloneDeep(selectedRows)

        if (selectedRowsUpdate[itemID]) {
            delete selectedRowsUpdate[itemID]
        } else {
            Object.assign(selectedRowsUpdate, {[itemID]: item})
        }

        setSelectedRows(selectedRowsUpdate)
    }

    const handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(resource.data, 'list', []))

        let selectedRowsClone = cloneDeep(selectedRows)

        if (!selectAll) {
            Object.assign(selectedRowsClone, data.reduce((memo, it) => {
                memo[it.WorkOrderID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsCloneKeys = Object.keys(selectedRowsClone)
            data.forEach(it => {
                if (selectedRowsCloneKeys.includes(it.WorkOrderID.toString())) {
                    delete selectedRowsClone[it.WorkOrderID]
                }
            })
        }

        setSelectedRows(selectedRowsClone)
    }

    const handleViewTruckClick = (truckID) => {
        dispatch(showGlobalModal('ViewTruckCard', truckID));
    }

    const handleViewTrailerClick = (trailerID) => {
        dispatch(showGlobalModal('ViewTrailerCard', trailerID));
    }

    const handleViewContactClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleViewOrganizationClick = (item) => {
        setSelectedOrganization(item)
        setIsOrganizationInfoDialogOpen(true)
    }

    const handleReminderUpdateClick = (item) => {
        setSelectedReminder(item);
        setIsReminderModalOpen(true);
    }

    const handleReminderUpdateModalClose = () => {
        setSelectedReminder(null);
        setIsReminderModalOpen(false);
    }

    function handleReminderInputChange(name, value) {
        let reminderUpdate = Object.assign({}, updateReminderFields);

        reminderUpdate = FieldsManager.updateField(reminderUpdate, name, value);

        if ("IsUpdateReminderOn" === name) {
            reminderUpdate.ReminderIntervalDays.disabled = !value;
            reminderUpdate.ReminderIntervalHours.disabled = !value;
            reminderUpdate.ReminderIntervalMinutes.disabled = !value;
        }

        setUpdateReminderFields(reminderUpdate);
    }

    function handleAutoRefreshClick() {
        const isEnabled = !isAutoRefreshEnabled;
        const storage = LocalStorage.get(getListPath() + "_query");
        storage.isAutoRefreshEnabled = isEnabled;
        LocalStorage.set(getListPath() + "_query", storage);
        setIsAutoRefreshEnabled(isEnabled);

        if (isEnabled) {
            autoRefreshTimeout.current = setInterval(autoFetchData, autoRefreshTime);
        } else {
            clearTimeout(autoRefreshTimeout.current);
        }
    }

    const handleLinkClick = (e) => {
        history.push(e)
    }

    const handleDecodeVIN = () => {
        setDecodeVINModalOpen(!DecodeVINModalOpen);
        dispatch(clearThirdResource());
    }

    /** Helpers
     ================================================================= */
    function getFields(item = {}) {
        const TruckID = !!item?.TruckID;
        const TrailerID = !!item?.TrailerID;

        const fieldTemplates = {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'custom', {
                render: (it) => {
                    return (
                        <CellLink
                            to={checkPerm(Resources.WorkOrders, UPDATE_PERM) && "/work-orders/" + it.WorkOrderID}
                            disabled={!checkPerm(Resources.WorkOrders, UPDATE_PERM)}
                        >
                            {it.AutoCounter}
                        </CellLink>
                    )
                }
            }),
            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
                , omitSort: true
            }),
            WorkOrderStatusID: new Field('WorkOrderStatusID', '', [''], false, 'select', {
                render: (it) => <WorkOrderStatusBadge
                    workOrderStatuses={workOrderStatuses}
                    statusID={it.WorkOrderStatusID}
                />
            }),
            TruckID: new Field('TruckID', '', TrailerID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TruckID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.TrucksInfo, READ_PERM) && handleViewTruckClick(item.TruckID)}
                            >
                                {item.Truck}
                            </CellButton>
                        )
                    } else {
                        return (<span>{item.Truck}</span>)
                    }
                },
                addContainerClass: "col-span-6 col-start-1"
            }, {isClearable: true}),
            TrailerID: new Field('TrailerID', '', TruckID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TrailerID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.Trailer, READ_PERM) && handleViewTrailerClick(item.TrailerID)}
                            >
                                {item.Trailer}
                            </CellButton>
                        )
                    } else {
                        return item.Trailer;
                    }
                },
                addContainerClass: "col-span-6"
            }, {isClearable: true}),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                label: "ChargeTo",
                render: (item) => {
                    if (item.ChargeTo == 2) {
                        return ("Non-Chargeable")
                    }
                    if (item.ChargeContactID) {
                        return <CellButton
                            onClick={() => checkPerm(Resources.Contacts, READ_PERM) && handleViewContactClick(item.ChargeContactID)}
                        >
                            {item.ChargeContact}
                        </CellButton>
                    }
                    if (item.ChargeOrganizationID) {
                        return <CellButton
                            onClick={() => checkPerm(Resources.Contacts, READ_PERM) && handleViewOrganizationClick(item.ChargeOrganizationID)}
                        >
                            {item.ChargeOrganization}
                        </CellButton>
                    }
                }
            }),
            UpdateReminderInterval: new Field('UpdateReminderInterval', "", [''], false, 'custom', {
                disableColumnFilter: true,
                // hideTable: true,
                label: "StopUpdateReminder",
                render: (item) => {
                    if (!item.UpdateReminderInterval) {
                        return <button onClick={
                            (e) => {
                                e.stopPropagation();
                                handleReminderUpdateClick(item)
                            }}
                                       className="text-tm-gray-500 font-semibold hover:text-primary hover:bg-tm-gray-100 p-1.5 rounded-btn">
                            No reminder set
                        </button>
                    }

                    const greenZoneDiff = item.UpdateReminderInterval * 0.8;
                    const currentDateTime = moment()
                        .tz(getUserTimezone())
                        .subtract(item?.UpdateReminderInterval ?? 0, 'minutes')
                        .format(DEFAULT_DATABASE_DATETIME_FORMAT)
                    const utcDate = moment(toFrontDateTimeFromUTC(item.LastNoteDateTime), getUserDateTimeFormat())
                        .format(DEFAULT_DATABASE_DATETIME_FORMAT);

                    const intervalDiff = moment(utcDate).diff(currentDateTime, 'minutes');

                    let type = "warning"

                    const conversion = minutesToDayHourMinutes(Math.abs(intervalDiff))

                    let reminderText = translate("text.reminderToNote", [intervalDiff]);

                    if (!!conversion.days) {
                        reminderText = translate(`text.reminderToNoteDHM`, [conversion.days, conversion.hours, conversion.minutes])
                    } else if (!!conversion.hours) {
                        reminderText = translate("text.reminderToNoteHM", [conversion.hours, conversion.minutes])
                    }

                    if (intervalDiff < 0) {
                        type = "danger"
                        reminderText = translate("text.reminderDueNote", [Math.abs(intervalDiff)])

                        if (!!conversion.days) {
                            reminderText = translate(`text.reminderDueNoteDHM`, [conversion.days, conversion.hours, conversion.minutes])
                        } else if (!!conversion.hours) {
                            reminderText = translate("text.reminderDueNoteHM", [conversion.hours, conversion.minutes])
                        }

                    } else if (intervalDiff >= greenZoneDiff) {
                        type = "ok"
                    }

                    if (!item?.LastNoteDateTime) {
                        reminderText = "Reminder is set, first update should be posted"
                    }

                    return (
                        <TableReminder
                            onClick={() => handleReminderUpdateClick(item)}
                            type={type}
                            content={reminderText}
                            item={item}
                        />
                    )
                }
            }),
            Title: new Field('Title', "", [''], false, 'custom', {
                disableColumnFilter: true,
                label: "LastStopUpdate",
                render: (item) => {
                    if (!item.LastTitleNotes) {
                        return null;
                    }
                    if (item.LastNotes) {
                        return (
                            <Tippy
                                content={<span>{item.LastNotes}</span>}
                            >
                                <span>
                                    {item.LastTitleNotes} ({toFrontDateTimeFromUTC(item.LastNoteDateTime)})
                                </span>
                            </Tippy>
                        )
                    }
                    return (
                        <span>
                            {item.Title}
                        </span>
                    )
                }
            }),
            InitialComplaint: new Field('InitialComplaint', '', [''], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.InitialComplaint)}</div>
                )
            }),
            Notes: new Field('Notes', '', [''], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.Notes)}</div>
                )
            }),
            TotalAmount: new Field('TotalAmount', '', [''], false, "float", {
                render: (it) => (
                    <Tippy
                        content={
                            <div className={"text-right"}>
                                <div>Labor: {genericMoneyFormatter(it.LaborAmount)}</div>
                                <div>Parts: {genericMoneyFormatter(it.PartsAmount)}</div>
                                <div>Shop supplies: {genericMoneyFormatter(it.MiscAmount)}</div>
                                <div>Misc. surcharge: {genericMoneyFormatter(it.SurchargeAmount)}</div>
                            </div>
                        }
                    >
                        <div className={"text-right"}>
                            {genericMoneyFormatter(it.TotalAmount)}
                        </div>
                    </Tippy>
                ),
                colFilter: true
            }, {icon: false}),
            IsInvoiced: new Field('IsInvoiced', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsInvoiced}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsAuthorized: new Field('IsAuthorized', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsAuthorized}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CreatedDate: new Field('CreatedDate', '', [], false, 'datetimezone'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    function getUpdateReminderFields(item = {}) {
        const fieldTemplates = {
            Title: new Field('Title', '', ['required'], false, 'text', {addContainerClass: "col-span-6"}),
            Notes: new Field('Notes', '', [''], false, 'text', {
                addContainerClass: "col-span-12"
            }),
            IsUpdateReminderOn: new Field('IsUpdateReminderOn', '', [''], false, 'switch', {
                addContainerClass: "px-4 sm:col-span-8 col-span-full flex items-center bg-tm-gray-100 hover:bg-sky-600/10 rounded-xl"
            }),
            ReminderIntervalDays: new Field('ReminderIntervalDays', '', [], !item.IsUpdateReminderOn, 'integer', {
                addContainerClass: "col-span-3 col-start-1 sm:col-start-9 sm:col-span-1 w-[calc(100%+1rem+1px)]",
                addClass: "form-control text-center relative rounded-r-none focus:z-10",
            }),
            ReminderIntervalHours: new Field('ReminderIntervalHours', '', [], !item.IsUpdateReminderOn, 'integer', {
                addContainerClass: "col-span-3 sm:col-span-1 w-[calc(100%+1rem+1px)]",
                addClass: "form-control text-center rounded-none focus:z-10 focus:relative",
            }),
            ReminderIntervalMinutes: new Field('ReminderIntervalMinutes', '', [], !item.IsUpdateReminderOn, 'integer', {
                addContainerClass: "col-span-6 sm:col-span-2",
                addClass: "form-control text-center rounded-l-none focus:z-10",
            })
        }

        const filledFields = fillFieldsFromData(fieldTemplates, item);
        filledFields.Notes.value = "";
        if (item.UpdateReminderInterval) {
            const conversion = minutesToDayHourMinutes(item.UpdateReminderInterval)

            filledFields.ReminderIntervalDays.value = conversion.days ?? 0;
            filledFields.ReminderIntervalHours.value = conversion.hours ?? 0;
            filledFields.ReminderIntervalMinutes.value = conversion.minutes ?? 0;
        }

        return filledFields;
    }

    const getDecodeVINFields = () => {
        return  {
            DecodeVIN: new Field('DecodeVIN', '', ['length_===_17'], false, 'text', {addContainerClass: 'col-span-3'}, {
                max: VIN_CODE_FIELD_LENGTH,
                upperCaseOnly: true,
                numbersAndLettersOnly: true
            }),
        }
    }

    useEffect(() => {
        if (selectedReminder) {
            setUpdateReminderFields(getUpdateReminderFields(selectedReminder))
        }
    }, [selectedReminder]);

    useEffect(() => {
        if (resourceIsCreated(resource)) {
            setIsReminderModalOpen(false);
        }
    }, [resource])

    useEffect(() => {
        setQueryFields(queryFields)
    }, []);

    updateReminderFields.IsUpdateReminderOn.metadata.htmlAfter = (it) => {
        return <FieldSwitchLabel
            onClick={() => handleReminderInputChange("IsUpdateReminderOn", !it.value)}
            label={translate("field.IsUpdateReminderOn")}
            note={translate("text.StopUpdateReminderInfo")}
        />
    }

    const stats = [
        {
            id: 1,
            name: "Total amount",
            icon: banknotesIcon,
            value: resource?.data?.TotalAmount ?? 0,
            valuePrefix: '$',
            decimalCount: 2
        },
        {
            id: 2,
            name: "Parts",
            icon: banknotesIcon,
            value: resource?.data?.PartsAmount ?? 0,
            valuePrefix: '$',
            decimalCount: 2
        },
        {
            id: 3,
            name: "Labor",
            icon: banknotesIcon,
            value: resource?.data?.LaborAmount ?? 0,
            valuePrefix: '$',
            decimalCount: 2
        },
        {
            id: 4,
            name: "Shop supplies",
            icon: banknotesIcon,
            value: resource?.data?.ShopSuppliesAmount ?? 0,
            valuePrefix: '$',
            decimalCount: 2
        },
        {
            id: 5,
            name: "Misc.",
            icon: banknotesIcon,
            value: resource?.data?.MiscAmount ?? 0,
            valuePrefix: '$',
            decimalCount: 2
        },
    ]

    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <Layout
                customPageTitle={"Work Orders"}
                history={history}
                match={match}
                translate={translate}
            >
                <Page>
                    <PageHeader
                        title={translate("page_title.work-orders")}
                        buttons={[
                            {
                                label:  translate('text.decode_vin'),
                                onClick: () => handleDecodeVIN()
                            },
                            {
                                className: "btn btn-outline text-sky-600 border-sky-600",
                                label: <><img src={Bot} alt="bot" className={"w-5 h-5 mr-3"}/> Need AI help identifying
                                    the diagnostic code?</>,
                                onClick: () => setIsErrorCodeHelpModalOpen(true),
                                isHidden: !checkPerm(getResourcePath(), CREATE_PERM)
                            },
                            {
                                label: translate("btn.create"),
                                type: "primary",
                                onClick: handleCreateResourceClick,
                                isHidden: !checkPerm(getResourcePath(), CREATE_PERM)
                            }
                        ]}
                    >
                        {/* For next release
                        <Tippy
                            content={translate(areStatsVisible ? 'text.hide_stats' : 'text.show_stats')}
                            delay={[400, 0]}
                            trigger="mouseenter"
                        >
                            <button
                                className={
                                    classNames(
                                        areStatsVisible ? 'text-primary border-primary' : undefined,
                                        'btn btn-header'
                                    )
                                }
                                onClick={() => {
                                    setAreStatsVisible(!areStatsVisible);
                                }}
                            >
                                <PresentationChartLineIcon
                                    className="w-5 h-5"
                                />
                            </button>
                        </Tippy>
                        */}
                    </PageHeader>

                    {areStatsVisible && (
                        <div className="relative z-40">
                            <div className="grid grid-cols-1 gap-5 sm:grid-cols-5 lg:grid-cols-5">
                                <WidgetStats
                                    stats={stats}
                                />
                            </div>
                        </div>
                    )}

                    <ResourceList
                        fields={getFields()}
                        toggleAutoRefresh={handleAutoRefreshClick}
                        isAutoRefreshEnabled={isAutoRefreshEnabled}
                        resource={resource}
                        Data={resource?.data?.list}
                        Count={count}
                        updatedRows={resource?.diffData ?? {}}
                        // fetchData={fetchData}
                        isLoading={isLoading}
                        isLoadingSilent={!!resource?.isLoadingSilent}
                        queryFields={queryFields}
                        setQueryFields={setQueryFields}
                        listPath={getListPath()}
                        dispatch={dispatch}
                        query={query}
                        onSelectRow={handleSelectRowClick}
                        onSelectAllClick={handleSelectAllClick}
                        selectedRows={selectedRows}
                        onRefreshTable={fetchData}
                        translate={translate}
                        // onMount={fetchData}
                        selects={getSelects()}
                        tableKey={"WorkOrderID"}
                        onRowClick={checkPerm(Resources.WorkOrders, UPDATE_PERM) && handleEditResourceClick}
                        // onView
                        // onEdit
                    />

                    <TableBulkActions
                        selectedRows={selectedRows}
                        tableKey="WorkOrderID"
                        fields={getFields()}
                        translate={translate}
                        options={tableOptions}
                        setSelectedRows={setSelectedRows}
                        onSelectAllClick={handleSelectAllClick}
                    >
                        <div className='flex items-center h-8'>
                            <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>
                                <div className='flex gap-x-1 pr-2'>


                                    <div className='flex gap-x-1 px-2'>
                                        <TableTagManager
                                            rowKey={'WorkOrderID'}
                                            btnIconClass='w-5 h-5'
                                            customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                            selectedRows={selectedRows}
                                            resourceName={Resources.ColorTagWorkOrders}
                                            isDiffResource={true}
                                            piggyResourceName={getResourcePath()}
                                            query={query}
                                            onAfterTagRows={() => {
                                                setSelectedRows({})
                                            }}
                                            translate={translate}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </TableBulkActions>

                    {(isOrganizationInfoDialogOpen &&
                        <OrganizationInfoDialog
                            show={isOrganizationInfoDialogOpen}
                            translate={translate}
                            organizationID={selectedOrganization}
                            handleClose={() => setIsOrganizationInfoDialogOpen(false)}
                        />
                    )}
                </Page>
            </Layout>

            <ModalDefault
                show={isReminderModalOpen}
                widthClass={"max-w-3xl"}
                title={translate("text.wo_reminder_update")}

                onButtonClick={handleReminderSubmit}
                buttonLabel={translate('btn.apply')}

                closeButtonLabel={translate('btn.Cancel')}
                onClose={handleReminderUpdateModalClose}
            >
                <>
                    {isLoading && (
                        <LoaderLarge/>
                    )}
                    <div className="p-6 grid grid-cols-12 gap-4">
                        <FieldsForm
                            isLoading={isLoading}
                            fieldsState={updateReminderFields}
                            onChange={handleReminderInputChange}
                            translate={translate}
                        />
                    </div>
                </>
            </ModalDefault>

            <ModalDefault
                show={isErrorCodeHelpModalOpen}
                limitHeight={true}
                widthClass={'max-w-3xl'}
                title={translate('text.error_code_help')}

                closeButtonLabel={translate('btn.Close')}
                onClose={() => setIsErrorCodeHelpModalOpen(false)}
            >
                <ErrorCodeHelpAiModal
                    dataUnit={{
                        Type: "Truck",
                        Year: "Unspecified",
                        Manufacturer: "Unspecified",
                        Model: "Unspecified",
                    }}
                    translate={translate}
                />
            </ModalDefault>

            <ModalSaveResource
                title={'Decode VIN'}
                show={DecodeVINModalOpen}
                widthClass="max-w-md"
                gridColsClass="grid-cols-3"
                onClose={handleDecodeVIN}
                secondaryButtonLabel={checkPerm(Resources.Trucks, CREATE_PERM) && dataVIN?.Model && (dataVIN?.Type === "TRUCK" ? translate("btn.create_truck") : translate("btn.create_trailer"))}
                onSecondaryButtonClick={(params) => {
                    if (dataVIN.Type === "TRUCK") {
                        dataVIN.VIN = params.DecodeVIN
                        handleLinkClick(`/truck/create?prefilled=` + JSON.stringify(dataVIN))
                    } else if (dataVIN.Type === "TRAILER"){
                        dataVIN.VIN = params.DecodeVIN
                        handleLinkClick(`/trailer/create?prefilled=` + JSON.stringify(dataVIN))
                    }
                }}
                secondaryButtonDisabled={!dataVIN?.Model || !checkPerm(Resources.Trucks, CREATE_PERM)}
                buttonLabel={translate("btn.decode_vin")}
                fields={getDecodeVINFields()}
                onSubmit={(params) => {
                    if (params) {
                        fetchVINData(params.DecodeVIN)
                    }
                }}
                htmlAfter={<div>
                    {!!isLoadingThird && (
                        <div className="relative flex w-full justify-center my-3">
                            <Loader disableContainer={true}/>
                        </div>
                    )}
                    {(!isLoadingThird && !!dataVIN?.Model) && (
                        <div className={"grid gap-4 ml-6 text-base mb-4"}>
                            <p>Type: {dataVIN.Type}</p>
                            <p>Manufacturer: {dataVIN.Manufacturer}</p>
                            <p>Model: {dataVIN.Model}</p>
                            <p>Engine: {dataVIN.Engine}</p>
                            <p>Year: {dataVIN.Year}</p>
                        </div>
                    )}
                </div>
                }
                translate={translate}
            />
        </React.Fragment>
    )
}

/** Page methods
 ================================================================= */
const getPrimaryField = () => "WorkOrderID";
const getResourcePath = () => Resources.WorkOrders;

const getListPath = () => getResourcePath() + "_list"


const getQueryFields = (translate) => {
    return (
        Object.assign(
            getDefaultQueryFields(getPrimaryField(), translate),
            {
                archived: new Field('archived', '', [''], false, 'checkbox', {
                    labelType: "float",
                    label: "archived",
                    hasActiveBadge: true,
                }),
                StartDate: new Field('StartDate', '', [''], false, 'date', {hasActiveBadge: true}, {isClearable: true}),
                EndDate: new Field('EndDate', '', [''], false, 'date', {hasActiveBadge: true}, {isClearable: true}),
                WorkOrderStatusID: new Field('WorkOrderStatusID', '', [], false, 'select', {hasActiveBadge: true}, {all: true}),
                // RequestedServiceCategoryID: new Field('RequestedServiceCategoryID', '', [], false, 'select', {hasActiveBadge: true}, {all: true}),
                TruckID: new Field('TruckID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                TrailerID: new Field('TrailerID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                IsInvoiced: new Field('IsInvoiced', '', [], false, 'select', {
                    hasActiveBadge: true,
                    activeFilterLabel: "invoiced"
                }, {isClearable: true}),
                IsAuthorized: new Field('IsAuthorized', '', [], false, 'select', {
                    hasActiveBadge: true,
                    activeFilterLabel: "authorized"
                }, {isClearable: true}),
                ChargeContactID: new Field('ChargeContactID', '', [], false, 'select-search', {
                    hasActiveBadge: true,
                    label: "ChargeContact"
                }, {isClearable: true}),
                ChargeOrganizationID: new Field('ChargeOrganizationID', '', [], false, 'select-search', {
                    hasActiveBadge: true,
                    label: "ChargeOrganisation"
                }, {isClearable: true}),
                OfficeID: new Field('OfficeID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                ContactGroupID: new Field('ContactGroupID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                ShowOnlyCompleted: new Field('ShowOnlyCompleted', '', [''], false, 'checkbox', {hasActiveBadge: true}, {}),
                ShowOnlyActive: new Field('ShowOnlyActive', '', [''], false, 'checkbox', {hasActiveBadge: true}, {}),
            }
        )
    )
}


function getSelects() {
    return {
        AssignedContactID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
            })
        },
        RequesterID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
            })
        },
        AssignedVendorID: {
            api: 'api/' + Resources.Vendors,
            query: {},
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.VendorID,
            })
        },
        TruckID: {
            api: 'api/' + Resources.Trucks,
            query: {
                NotSoldRetired: 1
            },
            customizeList: (list) => {
                return groupListBySCAC(list, 'Truck')
            }
        },
        TrailerID: {
            api: 'api/' + Resources.Trailers,
            query: {
                NotSoldRetired: 1
            },
            customizeList: (list) => {
                return groupListBySCAC(list, 'Trailer')
            }
        },
        CustomCodeID: {
            api: 'api/' + Resources.WorkOrdersCustomCodes,
            query: {},
            searchMap: (it) => ({
                label: it.CustomCode,
                value: it.CustomCodeID,
            })
        },
        VMRSCode: {
            api: 'api/' + Resources.WorkOrdersVMRSCode,
            query: {},
            searchMap: (it) => {
                return ({
                    label: it.value,
                    value: it.value,
                })
            }
        },
        WorkOrderStatusID: getLookup('WorkOrderStatus'),
        RequestedServiceCategoryID: getLookup('RequestedServiceCategory'),
        IsInvoiced: {"": "All", 0: "No", 1: "Yes"},
        IsAuthorized: {"": "All", 0: "No", 1: "Yes"},
        ChargeContactID: {
            api: "api/" + Resources.Contacts,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + " " + item.LastName,
                Contact: item
            })
        },
        ChargeOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName,
                value: item.OrganizationID
            })
        },
        OfficeID: {
            api: 'api/' + Resources.OfficesQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.OfficeName,
                value: item.OfficeID
            })
        },
        ContactGroupID: {
            api: 'api/' + Resources.ContactGroupsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.ContactGroupName,
                value: item.ContactGroupID
            })
        },
    }
}
