import React, {Component} from 'react'
import {fillFieldsFromData} from '../../../common/util/util-fields'
import {Field, FieldsManager} from '../../../data/services/fields'
import Resources from '../../../data/services/resources'
import LocalStorage from '../../../util/localStorage'
import {getResource, updateResource} from '../../../data/actions/resource'
import {LoaderLarge} from "../../../common/components/loader";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import {toFrontDateTimeFromUTC} from "../../../common/util/util-dates";
import DisplayDataGrid from "../../../common/components/display-data/display-data-grid";
import Card from "../../../common/components/card";
import FieldsForm from "../../../common/components/fields/fields-form";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import FieldOptions from "../../../common/components/fields/field-options";
import {showModal} from "../../../data/actions/ui";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import {formatMoney} from "../../../common/util/util-formaters";
import ModalConfirm from "../../../common/components/modal/modal-confirm";

export default class WOCustomerPricingTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),

            isDirty: false,
            footerIsSticky: true
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({fields: this.getFields(getProp(this.props.resource, 'data', {}))})
        }
        if (prevState.isDirty !== this.state.isDirty) {
            this.props.setIsStateDirty(this.state.isDirty)
        }
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: {
                id: this.getID()
            }
        }))
    }

    onSubmit = () => {
        if (this.state.isDirty) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    const fieldValues = FieldsManager.getFieldKeyValues(this.state.fields);

                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, fieldValues, {
                            id: this.getID(),
                        }),
                        query: {
                            id: this.getID(),
                        },
                        errorMessage: true, successMessage: this.props.translate('text.wo_pricing_updated'),
                        resource: this.getResourceName(),
                        piggyResource: this.getResourceName()
                    }))
                    this.setState({isDirty: false})
                }
            })
        }
    }

    /** UI events
     ================================================================= */

    handleFormCancel = () => {
        this.setState({
            fields: this.getFields(),
            isDirty: false
        })
    }

    handleInputChange = (name, value) => {
        const fieldsUpdate = FieldsManager.updateField(this.state.fields, name, value);

        if ("OverrideLaborPrice" === name) {
            fieldsUpdate.DefaultLaborPrice.disabled = !value;
            fieldsUpdate.DefaultLaborPrice.validate = !value ? [''] : ['empty'];
        }


        fieldsUpdate[name].errorMessage = "";

        this.setState({
            fields: fieldsUpdate,
            isDirty: true
        })
    }

    /** Fields/data definitions
     ================================================================= */
    getFields = (data = {}) => {
        const isOverrideLaborPrice = !data?.OverrideLaborPrice;

        let fieldTemplates = {

            OverrideLaborPrice: new Field('OverrideLaborPrice', isOverrideLaborPrice ? 0 : 1, [''], false, 'checkbox', {addContainerClass: "col-span-4"}),
            DefaultLaborPrice: new Field('DefaultLaborPrice', "", isOverrideLaborPrice ? [''] : ['empty'], isOverrideLaborPrice, 'money', {addContainerClass: "col-start-1 col-span-4"}),
        }

        return fillFieldsFromData(fieldTemplates, data);
    }

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.CustomersWOPricing
    }

    getID = () => {
        return this.props.match.params.CustomerID
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;
        const {data, isLoading} = this.props.resource;

        return (
            <div className="max-w-4xl mx-auto">
                <Card className="bg-inverse rounded-card border-tm-gray-300 border shadow-card mb-4 relative pb-6">
                    <div className={'grid grid-cols-12 gap-4'}>
                        <FieldsForm
                            fieldsState={this.state.fields}
                            onChange={this.handleInputChange}
                            isLoading={isLoading}
                            translate={translate}
                        />
                    </div>

                    {!!resource.isLoading && (
                        <LoaderLarge/>
                    )}
                </Card>

                <PageFooter
                    translate={translate}
                    canSubmit={this.state.isDirty}
                    actionCancel={this.handleFormCancel}
                    actionSubmit={checkPerm(this.getResourceName(), UPDATE_PERM) && this.onSubmit}
                />
            </div>
        )
    }
}
