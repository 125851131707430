import React, {useEffect, useState} from 'react'
import {getResource} from '../../../data/actions/resource'
import {useDispatch, useSelector} from 'react-redux'
import {Field, FieldsManager} from '../../../data/services/fields'
import LocalStorage from '../../../util/localStorage'
import {getDefaultTableOptions} from '../../../common/util/util-helpers'
import {genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {ACCOUNT_TYPE_CURRENT_LIABILITY, DEFAULT_METADATA_SELECT_SEARCH_QUERY} from '../../../util/util-constants'

import Resources from '../../../data/services/resources'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import moment from "moment-timezone";
import ContactInfoDialog from "../../../common/components/modal/contact-info-dialog";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import FieldSearch from "../../../common/components/fields/field-text/search";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import Tooltip from "../../../common/components/tooltip";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";

export default function LiabilitiesTab({resourceName, translate}) {
    /** Store
     ================================================================= */
    const dispatch = useDispatch()
    const resource = useSelector((state) => state.resource)

    /** Const
     ================================================================= */
    const isLoading = resource.isLoading
    const dataList = resource?.data?.list ?? []
    const dataAccounts = resource?.data?.accounts ?? []

    /** State
     ================================================================= */
    const [selectedOrganization, setSelectedOrganization] = useState();
    const [isOrganizationInfoDialogOpen, setIsOrganizationInfoDialogOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null)
    const [isContactInfoDialogOpen, setContactInfoDialogOpen] = useState(null)
    const [filterValue, setFilterValue] = useState('')
    const [query, setQuery] = useState(getQueryFields())
    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(
        getFields(),
        {},
        'reports-liabilities',
        translate
    ))

    /** Data Events
     ================================================================= */
    function fetchData() {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: resourceName,
            query: processQuery()
        }))
    }

    // function downloadExcel() {
    //     dispatch(download({
    //         user: LocalStorage.get('user'),
    //         resource: resourceName,
    //         query: Object.assign({
    //             format: 'EXCEL',
    //             name: 'reports_liabilities_' + currentDate() + '.xlsx'
    //         }, processQuery())
    //     }))
    // }

    /** UI Events
     ================================================================= */
    function handleQuerychange(name, value) {
        let queryClone = Object.assign({}, query)

        if (!name && !value) {
            queryClone = Object.values(queryClone).reduce((memo, it) => {
                it.value = ''
                memo[it.name] = it
                return memo
            }, {})
        } else {
            queryClone = FieldsManager.updateField(queryClone, name, value)
        }

        setQuery(queryClone)
    }

    const handleToggleContactInfoDialog = (item = {}) => {
        setSelectedContact(item)
        setContactInfoDialogOpen(!isContactInfoDialogOpen)
    }

    function handleToggleOrganizationInfoDialog(item = {}) {
        setSelectedOrganization(item);
        setIsOrganizationInfoDialogOpen(true);
    }

    /** Helpers
     ================================================================= */
    function getFields() {
        const accounts = Object.keys(dataAccounts)

        const defaultFields = [
            new Field('LastName', '', [''], false, 'text', {
                order: 1,
                render: (it) => (
                    <CellButton
                        onClick={() => handleToggleContactInfoDialog(it)}>
                        <div>
                            {it.LastName}
                        </div>
                    </CellButton>
                )
            }),
            new Field('FirstName', '', [''], false, 'text', {
                order: 2,
                render: (it) => <CellButton
                    onClick={() => handleToggleContactInfoDialog(it)}>
                    <div>
                        {it.FirstName}
                    </div>
                </CellButton>
            }),
            new Field('LegalName', '', [''], false, 'text', {
                order: 2,
                render: (it) => <CellButton
                    onClick={() => it.OrganizationID && handleToggleOrganizationInfoDialog(it)}>
                    <div>
                        {it.LegalName}
                    </div>
                </CellButton>
            }),
            new Field('Balance', '', [''], false, 'money', {
                render: (it) => <div
                    className="font-bold h-full flex items-center justify-end">{genericMoneyFormatter(it.Balance)}</div>
            })
        ]

        const accountsFields = accounts.reduce((memo, key) => {
            memo.push(new Field(key, '', [''], false, 'money', {
                labelRaw: key,
                render: (it) => <div
                    className="font-bold h-full flex items-center justify-end">{genericMoneyFormatter(it[key])}</div>

            }))
            return memo
        }, [])

        return [...defaultFields, ...accountsFields]
            .reduce((memo, it) => {
                memo[it.name] = it
                return memo
            }, {})
    }

    function getQueryFields() {
        return {
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {
                labelType: 'float',
                addContainerClass: 'relative z-40'
            }, {
                isClearable: true,

            }),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {
                labelType: 'float',
                addContainerClass: 'relative z-30'
            }, {
                isClearable: true,
            }),
            AccountIDs: new Field('AccountIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                isClearable: true
            }),
        }
    }

    function processQuery() {
        return Object.values(query).reduce((memo, it) => {
            if (it.name === 'AccountIDs') {
                memo[it.name] = it?.value?.length ? it.value.map(it => it.value) : ''
            } else {
                memo[it.name] = it.value
            }

            return memo
        }, {})
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        // Update table options
        if (resource.resource === 'accounting/reports/liability') {
            const columns = Object.values(getFields()).reduce((memo, it, i) => {
                let column = {
                    frozen: false,
                    isMerged: false,
                    merged: [],
                    label: it?.metadata?.labelRaw ?? translate('field.' + it.name),
                    minWidth: undefined,
                    name: it.name,
                    order: 1.,
                    show: true,
                    showMobile: false,
                    type: 'money'
                }
                if (['LastName', 'FirstName', 'LegalName', 'Balance'].includes(it.name)) {
                    column.frozen = true
                    column.type = 'text'
                    column.showMobile = true
                    column.minWidth = 150
                    if (it.name === 'LastName') {
                        column.order = 1
                    }
                    if (it.name === 'FirstName') {
                        column.order = 2
                    }
                    if (it.name === 'LegalName') {
                        column.order = 2
                    }
                    if (it.name === 'Balance') {
                        column.order = 3
                    }
                } else {
                    column.order = i + 4
                    column.name = resource.data?.accounts?.[it.name]
                    column.label = resource.data?.accounts?.[it.name]
                }

                memo[it.name] = column

                return memo
            }, {})

            let tableOptionsClone = Object.assign({}, tableOptions)
            tableOptionsClone.columns = columns
            tableOptionsClone.behaviour.canAdjustWidth = true
            tableOptionsClone.behaviour.hasMenu = false
            setTableOptions(tableOptionsClone)
        }
    }, [resource])

    useEffect(() => {
        fetchData()
    }, [query])

    /** Render
     ================================================================= */

    const data = dataList.filter(it => {
        return !filterValue ||
            Number(it?.FirstName?.toLowerCase().search(filterValue.toLowerCase())) > -1
            || Number(it?.LastName?.toLowerCase().search(filterValue.toLowerCase())) > -1
            || Number(it?.LegalName?.toLowerCase().search(filterValue.toLowerCase())) > -1
    })

    return (
        <React.Fragment>
            <div className="min-h-[2.25rem] flex flex-wrap max-w-full items-center">
                <ActiveFilters
                    addClass={'flex-wrap'}
                    filterFields={query}
                    onLabelClick={handleQuerychange}
                    onClearFiltersClick={handleQuerychange}
                    translate={translate}
                />

                {/*<div className="flex ml-auto">*/}
                {/*    <Tooltip content={translate('text.download_excel')}>*/}
                {/*        <button*/}
                {/*            className="btn-icon"*/}
                {/*            onClick={downloadExcel}*/}
                {/*        >*/}
                {/*            <DocumentArrowDownIcon className="w-5 h-5"/>*/}
                {/*        </button>*/}
                {/*    </Tooltip>*/}
                {/*</div>*/}
            </div>

            <TableCard>
                <div className="pl-4 flex items-center gap-2 w-full relative z-30">
                    <FieldSearch
                        addClass={'form-control rounded-md w-full md:ring-1 ring-black ring-opacity-5'}
                        onChange={(_, value) => setFilterValue(value)}
                        value={filterValue}
                    />

                    <TableFilters
                        className="md:px-5 py-5 flex  w-full"
                        hideLimit
                        filterFields={query}
                        handleInputChange={handleQuerychange}
                        translate={translate}
                        onRefreshTable={fetchData}
                        isLoading={isLoading}
                        forceDialog
                        customHtml={
                            <PayDateFilter
                                queryFields={query}
                                translate={translate}
                                updateQueryFields={(queryUpdate) => {
                                    setQuery(queryUpdate)
                                }}
                                onQueryChange={query}
                                isDateTypeClearable={false}
                                hasDateType={false}
                                gridCols={'grid-cols-1'}
                            />
                        }
                        selects={{
                            AccountIDs: {
                                api: 'api/' + Resources.AccountsQuick,
                                query: {
                                    AccountTypeID: ACCOUNT_TYPE_CURRENT_LIABILITY,
                                    ...DEFAULT_METADATA_SELECT_SEARCH_QUERY()
                                },
                                searchMap: (item) => ({
                                    label: item.AccountNumber + ' ' + item.AccountName,
                                    value: item.AccountID
                                })
                            },
                        }}
                    />
                </div>

                {!(data.length === 0 && !isLoading) && (
                    <ResourceTable
                        data={data}
                        limit={data.length}
                        fields={getFields()}
                        maxHeightClass={'max-h-[calc(100vh-20rem)]'}

                        translate={translate}
                        isLoading={isLoading}

                        options={tableOptions}
                        saveTableOptions={setTableOptions}
                        tfoot={
                            Object.assign({
                                    LastName: '',
                                    FirstName: '',
                                    Balance: data.reduce((memo, it) => {
                                        memo = Number(memo) + Number(it.Balance)
                                        return memo
                                    }, 0)
                                }, Object.keys(dataAccounts ?? {}).reduce((memo, colName) => {
                                    memo[colName] = data.reduce((memo, it) => {
                                        memo = Number(memo) + Number(it[colName])
                                        return memo
                                    }, 0)
                                    return memo
                                }, {})
                            )
                        }
                    />
                )}

                <NoRecordsTable
                    show={(data.length === 0) && !resource.isLoading}
                    title={'No matching records found'}
                    onClearFilterClick={() => handleQuerychange()}
                    clearFilterBtnLabel={translate('text.clear_all_filters')}
                    clearFilterText={translate('text.try_without_filters')}
                    filters={query}
                />
            </TableCard>

            {(isContactInfoDialogOpen &&
                <ContactInfoDialog
                    show={isContactInfoDialogOpen}
                    translate={translate}
                    contactID={selectedContact?.ContactID}
                    handleClose={() => handleToggleContactInfoDialog()}
                />
            )}

            {(isOrganizationInfoDialogOpen &&
                <OrganizationInfoDialog
                    show={isOrganizationInfoDialogOpen}
                    translate={translate}
                    organizationID={selectedOrganization?.OrganizationID}
                    handleClose={() => setIsOrganizationInfoDialogOpen(false)}
                />
            )}
        </React.Fragment>
    )
}
