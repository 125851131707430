import React, {useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import {useDispatch} from "react-redux";
import {MAXIMUM_DOCUMENT_UPLOAD_SIZE} from "../../../../util/util-constants";
import {previewDocumentResource} from "../../../../data/actions/previewDocumentResource";
import {PencilIcon} from "@heroicons/react/24/outline";
import {FieldsManager} from "../../../../data/services/fields";
import {numberWithCommasToBack} from "../../../../util/util-formaters";
import {checkPerm, getDefaultTableOptions} from "../../../../common/util/util-helpers";
import LocalStorage from "../../../../common/util/localStorage";
import InfoParagraph from "../../../../common/components/info-paragraph";
import {fieldsToHtml} from "../../../../common/util/util-fields";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../../common/components/resource-table";
import ModalFooter from "../../../../common/components/modal/modal-footer";
import Resources from "../../../../data/services/resources";
import {CREATE_PERM} from "../../../../common/util/util-consts";

const ImportExcelJournalDataModal = ({onClose, translate, modalTitle, previewDocumentResourceName, previewHasError, isPreviewLoading, previewData, fields, selects, onUploadData, additionalFields = {}, params = {}, journalData}) => {
    /** Constants
     ================================================================= */
    const dispatch = useDispatch()

    const tablePageDefaults = {
        behaviour: {
            rowSelect: true,
            canAdjustWidth: false,
            hasMenu: false
        },
        style: {
            condensed: false,
            isGPUAccelerated: true,
            verticalLines: true,
            showHorizontalLines: true,
        }
    }

    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(fields, tablePageDefaults, 'import_excel_data_modal', translate))

    const tableKey = "IMPORT_ROW_ID"

    /** Data Events
     ================================================================= */
    const onPreviewDocument = (files) => {
        dispatch(previewDocumentResource({
            user: LocalStorage.get("user"),
            resource: previewDocumentResourceName,
            params: params,
            files: files
        }))
    }

    /** UI Events
     ================================================================= */
    const handleSubmit = () => {
        const validatedFields = FieldsManager.validateFields(additionalFormFields);
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            onUploadData(data.filter(it => !!selectedRows[it[tableKey]]), FieldsManager.getFieldKeyValues(validatedFields))
        } else {
            setAdditionalFormFields(validatedFields)
        }
    }

    const handleSelectRowClick = (item) => {
        if (!item.HasError && item.AccountID && (item.Credit || item.Debit)) {
            const itemID = item[tableKey];

            let rowsClone = Object.assign({}, selectedRows)

            if (rowsClone[itemID]) {
                delete rowsClone[itemID]
            } else {
                Object.assign(rowsClone, {[itemID]: item})
            }

            setSelectedRows(rowsClone);
        }

        if (item.HasError) {
            const itemID = item[tableKey];

            let rowsClone = Object.assign({}, selectedRows)

            if (rowsClone[itemID]) {
                delete rowsClone[itemID]
            }


            setSelectedRows(rowsClone);
        }
    }

    const handleSelectAllClick = (selectAll) => {
        let selected = Object.assign({}, selectedRows)

        if (!selectAll) {
            selected = Object.assign(selected, data.reduce((memo, it) => {
                if (!it.HasError) {
                    memo[it[tableKey]] = it
                }
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selected)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it[tableKey].toString())) {
                    delete selected[it[tableKey]]
                }
            })
        }

        setSelectedRows(selected);
    }

    const handleInputChange = (name, value, index, item) => {
        setData(data.map((it, i) => {
            if (i === index) {
                if ((name === 'Debit' || name === 'Credit')) {
                    if (value === "0.00") {
                        it[name] = 0
                    } else {
                        it[name] = value
                    }
                } else if (name === 'Description') {
                    it[name] = value
                } else
                if (value?.value) {
                    it[name] = value.value
                    it[name.substring(0, name.length - 2)] = value.label
                } else {
                    it[name] = value
                    it[name.substring(0, name.length - 2)] = selects[name][value]
                }

                if (Object.keys(it).filter((item) => item === "AccountID" && (item === "Debit" || item === "Credit")).every(elem => !!it[elem])) {
                    it.HasError = false
                }

                if (!it["Debit"] && !it["Credit"]) {
                    it.HasError = true
                    item.HasError = true

                    handleSelectRowClick(item)
                }
            }
            return it
        }))

        journalData(data.filter(it => !!selectedRows[it[tableKey]]))
    }

    const handleAdditionalFieldsInputChange = (name, value) => {
        setAdditionalFormFields(FieldsManager.updateField(additionalFormFields, name, value))
    }

    /** State
     ================================================================= */
    const [canSubmit, setCanSubmit] = useState(false)
    const [dropzoneActive, setDropzoneActive] = useState(false)
    const [files, setFiles] = useState([])
    const [data, setData] = useState([])
    const [selectedRows, setSelectedRows] = useState({})
    const [editCellKey, setEditCellKey] = useState({})
    const [additionalFormFields, setAdditionalFormFields] = useState(additionalFields)

    /** Lifecycles
     ================================================================= */
    useEffect(() => {
        if (!!previewData.length) {
            const selected = {}
            setData(previewData.map((item, index) => {
                let col = Object.keys(fields).reduce((memo, it, index) => {
                    if (fields[it].type === "select-search" || fields[it].type === "select") {
                        memo[it.substring(0, it.length - 2)] = item[index] ? item[index].name : ""
                    }

                    if (fields[it].type === "date" || fields[it].type === "datetime" || fields[it].type === "datetimezone") {
                        memo[it] = item[index] ? item[index].name + " 00:00:00" : ""
                    } else {
                        memo[it] = item[index] ? item[index].value : ""
                    }

                    if (fields[it].type === "text") {
                        memo[it] = item[index] ? item[index].value.toString() : ""
                    }

                    if (fields[it].type === "money") {
                        memo[it] = item[index] ? numberWithCommasToBack(item[index].value) : 0
                    }

                    return memo
                }, {})

                col[tableKey] = index

                if (item.some(elem => elem.error)) {
                    col.HasError = true
                } else {
                    selected[index] = col
                }

                return col
            }))
            setSelectedRows(selected)
            setCanSubmit(true)
        }
    }, [previewData])

    useEffect(() => {
        journalData(data.filter(it => !!selectedRows[it[tableKey]]))
    }, [selectedRows]);

    /** Render
     ================================================================= */
    return (
        <div className={'relative'}>
            <div className="p-5">

                {!files.length && (
                    <div>
                        <div className={"text-base mt-2"}>{translate("text.ImportJournal")}</div>

                        <Dropzone
                            onDrop={(acceptedFiles) => {
                                onPreviewDocument(acceptedFiles)
                                setFiles(acceptedFiles)
                            }}
                            onDragEnter={() => setDropzoneActive(true)}
                            onDragLeave={() => setDropzoneActive(false)}
                            onDropAccepted={() => setDropzoneActive(false)}
                            multiple={false}
                            maxFiles={1}
                            accept={".xlsx, .csv"}
                            maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                        >
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps()}
                                         className={'border-2 flex items-center flex-col justify-center h-20' + (dropzoneActive ? ' border-primary border-solid text-primary bg-sky-100' : ' border-tm-gray-200 border-dashed text-tm-gray-700')}>
                                        <input {...getInputProps()} />

                                        <p className="select-none text-tm-gray-500">{translate("field.drag_n_drop")}</p>

                                        {!!files.length && (
                                            <div>
                                                <p className=" text-tm-gray-500">{files[0].name}</p>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                )}

                {!!data.length && !!Object.keys(additionalFields).length && (
                    <div className={'grid grid-cols-12 gap-4 mx-4 my-4'}>
                        {fieldsToHtml(Object.values(Object.assign({}, additionalFormFields)), translate, handleAdditionalFieldsInputChange, selects)}
                    </div>
                )}

                <div className="my-5">
                    {!!previewData.length && previewData.length > 1000 && (
                        <InfoParagraph type="danger" message={translate('error.ImportHasMoreThan1000Rows')}/>
                    )}
                    {!!data.length && data.some(it => it.HasError) && (
                        <InfoParagraph type="warning" message={translate('error.OnlyNonErrorRowsWillBeImported')}/>
                    )}
                    {!!previewHasError && (
                        <InfoParagraph type="danger" message={translate('error.ImportFileCorrupted')}/>
                    )}
                </div>

                <div className={'mt-5'}>
                    <TableCard>
                        <ResourceTable
                            maxHeightClass={"max-h-[calc(100vh-23rem)]"}
                            options={tableOptions}
                            data={data}
                            fields={Object.keys(fields).reduce((memo, it) => {
                                memo[it] = fields[it]

                                if (!memo[it].metadata) {
                                    memo[it].metadata = {}
                                }
                                memo[it].metadata.hideLabel = true
                                memo[it].metadata.labelType = 'float'
                                memo[it].metadata.addContainerClass = 'w-full'
                                if (memo[it].type === 'select-search' || memo[it].type === 'select' || memo[it].type === 'money' || memo[it].type === 'text') {
                                    memo[it].metadata.render = (item) => {
                                        if (editCellKey !== `${item[tableKey]}.${it}`) {
                                            return (
                                                <div
                                                    className={"cursor-pointer"}
                                                    onClick={() => {
                                                        setEditCellKey(`${item[tableKey]}.${it}`)
                                                    }}
                                                >
                                                    { (memo[it].type === 'select-search' || memo[it].type === 'select') ?  item[it.substring(0, it.length - 2)] : (memo[it].type === 'money') ? '$' + item[it] : (memo[it].type === 'text') && item[it]}
                                                    <button title={translate("btn.edit")}>
                                                        <PencilIcon className={"ml-2 mb-1 inline w-3 h-3"}/>
                                                    </button>
                                                </div>
                                            )
                                        }

                                        if (memo[it].type === 'select-search') {
                                            memo[it].value = {
                                                label: data[item[tableKey]][it.substring(0, it.length - 2)],
                                                value: data[item[tableKey]][it],
                                            }
                                        } else if (memo[it].type === 'select') {
                                            memo[it].value = data[item[tableKey]][it]
                                        } else if (memo[it].type === 'money' || memo[it].type === 'text') {
                                            memo[it].value = data[item[tableKey]][it]
                                        }

                                        memo[it].props = {
                                            hasPortal: true,
                                            autoFocus: true,
                                            onBlur: () => {
                                                setEditCellKey('')
                                            },
                                        }

                                        const fieldsHtml = fieldsToHtml([memo[it]], translate, (name, value) => handleInputChange(name, value, item[tableKey], item), selects)

                                        return (
                                            <div className={'w-full'}>
                                                {fieldsHtml}
                                            </div>
                                        )
                                    }
                                }

                                return memo
                            }, {})}

                            translate={translate}
                            isLoading={isPreviewLoading}

                            limit={data.length || 100}

                            tableKey={tableKey}
                            onSelectRow={handleSelectRowClick}
                            selectedRows={selectedRows}
                            onSelectAllClick={handleSelectAllClick}

                            addRowClass={(row) => {
                                if (row.HasError) {
                                    return "bg-red-500"
                                }
                            }}
                        />
                    </TableCard>
                </div>
            </div>

            <ModalFooter
                closeButtonLabel={translate('btn.close')}
                onClose={onClose}

                buttonLabel={translate('btn.import')}
                buttonDisabled={!canSubmit || Object.keys(selectedRows).length === 0 || data.every(it => !!it.HasError) || !checkPerm(Resources.Journal, CREATE_PERM) || (Object.keys(selectedRows).length < 2)}
                onButtonClick={handleSubmit}
            />
        </div>
    )
}

export default ImportExcelJournalDataModal
