import React, {useEffect, useState} from "react";
import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {LoaderSmall} from "../../../loader";
import {fieldsToHtml, includeFields} from "../../../../util/util-fields";
import {QUERY_LIMIT} from "../../../../../util/util-constants";
import {classNames} from "../../../../util/util-helpers";

export default function Pagination({
                                       count,
                                       queryFields,
                                       pageLimit,
                                       pageOffset,
                                       handleQueryChange,
                                       isLoading,
                                       hideRowsPerPage,
                                       softHideRowsPerPage,
                                       htmlAfterRowsPerPage,
                                       limitOptions,
                                       hidePagination,
                                       translate
                                   }) {
    const limit = pageLimit ?? queryFields?.limit?.value;
    const offset = pageOffset ?? queryFields?.offset?.value;


    if (queryFields?.['limit']) {
        queryFields['limit'].props = Object.assign({}, queryFields['limit']?.props ?? {});
        queryFields['limit'].props.hasPortal = true;
    }

    let pagesNum = Math.ceil(count / limit);
    let currentPage = Math.round(offset / limit + 1);

    const from = Number(offset) + 1;
    const to = currentPage === pagesNum ? count : Number(offset) + Number(limit)

    const handleKeyDown = (e) => {
        if (isNaN(e.target.value)) {
            return false
        }

        if (e.key === 'Enter') {
            if (e.target.value > pagesNum || e.target.value < 1) {
                setPageInputValue(currentPage);
                return false;
            }

            handleQueryChange("offset", (e.target.value - 1) * limit)
        }
    }

    const handleInputChange = (e) => {
        if (isNaN(e.target.value)) {
            return false;
        }

        setPageInputValue(Math.floor(e.target.value));
    }

    const [pageInputValue, setPageInputValue] = useState(currentPage);

    useEffect(() => {
        setPageInputValue(Math.round(offset / limit + 1));
    }, [offset, limit]);

    return (
        <div className="flex items-center w-full">
            <div className="text-sm text-tm-gray-700 mr-auto pr-8 flex items-center">
                {!isLoading && !!count && (
                    <span className="min-w-[5.5rem]">{translate("text.pagination_text", [from, to, count])}</span>
                )}

                {isLoading && (
                    <span className="min-w-[5.5rem]">
                        <LoaderSmall disableContainer/>
                    </span>
                )}

                {htmlAfterRowsPerPage}
            </div>

            {!hidePagination && (
                <div className="ml-auto flex items-center gap-x-4">
                    {!hideRowsPerPage && (
                        <div className={
                            classNames(
                                "text-sm text-tm-gray-700 mr-auto gap-x-2 items-center",
                                softHideRowsPerPage ? "hidden sm:flex" : "flex"
                            )}
                        >
                            Rows per page:

                            {fieldsToHtml(
                                Object.values(Object.assign({}, includeFields(queryFields, ['limit']))),
                                translate,
                                handleQueryChange,
                                {limit: limitOptions ?? QUERY_LIMIT}
                            )}
                        </div>
                    )}

                    <div className="relative z-0 inline-flex -space-x-px">
                        <button
                            disabled={currentPage === 1 || isLoading}
                            onClick={() => (currentPage > 1) ? handleQueryChange("offset", (currentPage - 2) * limit, currentPage) : null}
                            className="btn btn-outline-secondary border border-tm-gray-300 px-2 rounded-r-none z-10"
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="w-5 h-5"/>
                        </button>

                        <input
                            className="form-control rounded-none w-14 text-right px-1.5 relative focus:z-20"
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) => e.target.select()}
                            value={pageInputValue}
                        />

                        <div className="relative">
                            <input
                                disabled={true}
                                className="form-control rounded-none w-14 px-0 text-center"
                                value={isLoading ? "" : translate("text.pagination_of", [pagesNum])}
                            />

                            {isLoading && (
                                <LoaderSmall/>
                            )}
                        </div>

                        <button
                            disabled={currentPage >= pagesNum || isLoading}
                            onClick={() => currentPage < pagesNum ? handleQueryChange("offset", currentPage * limit, currentPage) : null}
                            className="btn btn-outline-secondary border border-tm-gray-300 px-2 rounded-l-none"
                        >
                            <span className="sr-only">Next</span>

                            <ChevronRightIcon className="w-5 h-5"/>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
