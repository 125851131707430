import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Field, FieldsManager} from '../../../data/services/fields'
import moment from 'moment-timezone'
import {deleteDataSelect, getDataSelect} from '../../../data/selectors/resourceSelectors'
import {
    checkPerm, getDefaultContactGroup,
    getDefaultUserOfficeValues, getLookup,
    getProp, groupListBySCAC,
    JSONParseFix,
    longTableColumn
} from '../../../common/util/util-helpers'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE, DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    JOURNAL_ENTRY_TYPE_JOURNAL, MAJOR_ACCOUNT_TYPE_ASSET, MAJOR_ACCOUNT_TYPE_EXPENSE,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    ChevronRightIcon, DocumentArrowUpIcon,
    DocumentDuplicateIcon,
    DocumentMagnifyingGlassIcon,
    EllipsisHorizontalIcon,
    PencilIcon,
    TrashIcon
} from '@heroicons/react/24/outline'
import {genericMoneyFormatter} from '../../../common/util/util-vanilla'
import Env from '../../../util/env'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import JournalCreateDialog from '../dialogs/journal-create'
import {getSecondResource} from '../../../data/actions/secondResource'
import AccountReportDialog from '../dialogs/account-report-dialog'
import Tippy from "@tippyjs/react";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ModalDefault from "../../../common/components/modal/modal-default";
import FileViewer from "../../../common/components/file-viewer/components"
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import {getJWT} from "../../../common/util/util-auth";
import Modal from "../../../common/components/modal";
import ImportExcelJournalDataModal from "../journal/import-excel-journal-data-modal";
import {createResource} from "../../../data/actions/resource";
import {resetPreviewDocumentResource} from "../../../data/actions/previewDocumentResource";

const JournalTab = ({translate, breakpoint}) => {
    /** Constants
     ================================================================= */
    const transactionDialogLimit = 20;

    /** Store
     ================================================================= */
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resource)
    const secondResource = useSelector((state) => state.secondResource)
    const thirdResource = useSelector((state) => state.thirdResource)
    const dialogResource = useSelector((state) => state.dialogResource)
    const downloadResource = useSelector((state) => state.download)
    const previewDocumentResource = useSelector((state) => state.previewDocumentResource)

    const getData = getDataSelect({dispatch: dispatch, user: user.data})
    const deleteData = deleteDataSelect({dispatch: dispatch, user: user.data})

    const data = getProp(resource, 'data.list', [])
    const count = getProp(resource, 'data.count', 0)
    const isLoading = getProp(resource, 'isLoading', false)
    const defaults = getProp(resource, 'data.defaults', [])

    const downloadIsLoading = getProp(downloadResource, 'isLoading', false)

    /** Constants
     ================================================================= */
    const pagePath = 'JournalTab'

    /** Fields Data Definitions
     ================================================================= */
    const getFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text'),
            Date: new Field('Date', '', ['empty'], false, 'date'),
            Name: new Field('Name', '', [], false, 'text', {
                render: (item) => (
                    <Tippy
                        content={<div className={"word-break"}>{item.Name}</div>}
                    >
                        <div className={"max-w-sm overflow-hidden"}>{item.Name}</div>
                    </Tippy>

                )
            }),
            Description: new Field('Description', '', [], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.Description)}</div>
                )
            }),
            Transactions: new Field('Transactions', '', ['empty'], false, 'custom', {
                render: (item) => transactionsFieldRender(item),
                omitSort: true
            }),
            Debit: new Field('Debit', '', [''], false, 'custom', {
                    render: (item) => getDebitCreditFieldRender(item, 'Debit'),
                    omitSort: true
                }
            ),
            Credit: new Field('Credit', '', [''], false, 'custom', {
                render: (item) => getDebitCreditFieldRender(item, 'Credit'),
                omitSort: true
            }),
            JournalEntryTypeID: new Field('JournalEntryTypeID', '', ['empty'], false, 'select'),
            UpdatedByContact: new Field('UpdatedByContact', '', [], false, 'text', {}),
        }
    }

    const getTransactionFields = () => {
        return {
            Account: new Field('Account', '', ['empty'], false, 'text', {
                omitSort: true
            }),
            Debit: new Field('Debit', '', [''], false, 'money', {
                    omitSort: true
                }
            ),
            Credit: new Field('Credit', '', [''], false, 'money', {
                omitSort: true
            }),
            ContactID: new Field('ContactID', '', ['empty'], false, 'custom', {
                render: (it) => {
                    return it.Contact ?? it.Organization
                },
                omitSort: true,
            }),
        }
    }

    const getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            JournalEntryTypeID: new Field('JournalEntryTypeID', '', [''], false, 'select', {}, {
                all: true
            }),
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {
                isRequiredFilter: true
            }, {
                isClearable: true
            }),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {
                isRequiredFilter: true
            }, {
                isClearable: true
            }),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    /** Helpers
     ================================================================= */
    const getPrimaryKey = () => {
        return 'JournalEntryID'
    }

    const getQuery = () => {
        return {
            ...query,
            ...FieldsManager.getFieldKeyValues(queryFilterFields)
        }
    }

    const getResourceName = () => {
        return Resources.Journal
    }

    /** State
     ================================================================= */
        // Query definitions
    const [queryFilterFields, setQueryFilterFields] = useState(LocalStorage.has(pagePath + '_state')
            ? JSONParseFix(LocalStorage.get(pagePath + '_state'))?.queryFilterFields
            : getQueryFilterFields())
    const [query, setQuery] = useState({
        ...DEFAULT_CRUD_STATE, sort: 'DESC', sortBy: 'AutoCounter'
    })
    const [transactionsQuery, setTransactionsQuery] = useState(DEFAULT_CRUD_STATE)

    // Modals
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [transactionsModalOpen, setTransactionsModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [receiptModalOpen, setReceiptModalOpen] = useState(false)
    const [previewModalOpen, setPreviewModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [isCopyMode, setIsCopyMode] = useState(false);
    const [importExcelDataModalOpen, setImportExcelDataModalOpen] = useState(false);

    /** UI events - QUERY HANDLERS - main table
     ================================================================= */
    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
        handleResetPagination()
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }


    const handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFieldsTmp = Object.assign({}, queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFieldsTmp).filter((it) => !excludedFields.includes(it.name)).forEach((it) => {
            FieldsManager.updateField(queryFilterFieldsTmp, it.name, '')
        })

        setQueryFilterFields(queryFilterFieldsTmp)
        handleResetPagination()
    }

    const handleResetPagination = () => {
        setQuery((prevState) => ({
            ...prevState,
            offset: 0,
            paginationPage: 1
        }))
    }

    const handleToggleImportExcelDataModal = () => {
            setImportExcelDataModalOpen(!importExcelDataModalOpen)
            dispatch(resetPreviewDocumentResource())

    }

    /** UI events
     ================================================================= */
    const handleToggleTransactionsModal = (item = null) => {
        setTransactionsModalOpen(!transactionsModalOpen)
        setSelectedItem(item)
        setTransactionsQuery(DEFAULT_CRUD_STATE)
    }

    const handleToggleReceiptModal = (item = null) => {
        setReceiptModalOpen(!receiptModalOpen)
        setSelectedItem(item)
    }

    const handleToggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen)
    }

    const handleOpenViewDocumentClick = (item = null) => {
        setPreviewModalOpen(!previewModalOpen)
        setSelectedItem(item)
    }

    const handleCopyJournalClick = (item) => {
        setEditModalOpen(!editModalOpen);
        setIsCopyMode(true);
        setSelectedItem(item);
    }

    const handleToggleEditModal = (item = null) => {
        setEditModalOpen(!editModalOpen)
        setSelectedItem(item);
        setIsCopyMode(false);
    }

    const handleToggleConfirmModal = (item = null) => {
        setConfirmModalOpen(!confirmModalOpen)
        setSelectedItem(item)
    }

    const editHidden = (item) => {
        const LockedData = getProp(resource.data, 'defaults.LockedDate', null)
        return !((!LockedData || (moment(LockedData).diff(item?.Date) < 0)))
    }

   const deleteHidden = (item) => {
        const LockedData = getProp(resource.data, 'defaults.LockedDate', null)
        return !((!LockedData || (moment(LockedData).diff(item.Date) < 0)))
    }
    /** Data events
     ================================================================= */
    const fetchData = () => {
        getData({query: getQuery(), resource: getResourceName()})
    }

    const downloadDocument = () => {
        const fileExt = selectedItem?.AttachPath.split('.').pop()

        dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.JournalDocuments,
            query: Object.assign({
                [getPrimaryKey()]: selectedItem[getPrimaryKey()],
                format: fileExt,
                name: 'document_' + currentDate() + '.' + fileExt
            })
        }))

        handleOpenViewDocumentClick()
    }

    const downloadExcelReports = (query) => {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingAccountReport,
            query: Object.assign({
                format: 'EXCEL',
                name: 'transactions_' + currentDate() + '.xlsx'
            }, query)
        }))
    }
    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [query])

    useEffect(() => {
        if (breakpoint && breakpoint.index <= 1 && getProp(queryFilterFields, "limit.value", 10) !== 10) {
            handleFilterInputChange('limit', 10);
        }
    }, [breakpoint])

    /** Render
     ================================================================= */
    const transactionsFieldRender = (item) => {
        if (!Array.isArray(item?.Transactions)) {
            return null
        }

        return (
            <div>
                {item.Transactions.map((it, i) => (
                    i < 5 && (
                        <>
                            <button
                                onClick={() => handleToggleReceiptModal(it)}
                                className="w-full my-1 inline-flex items-center justify-between px-2.5 py-0.5 border border-tm-gray-300 shadow-sm text-xs font-medium rounded text-tm-gray-700 bg-field hover:bg-tm-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-inverse focus:ring-offset-2"
                            >
                                <span>{it.Account}</span>
                                <ChevronRightIcon className={'w-2 h-2'}/>
                            </button>
                            <br/>
                        </>
                    )
                ))}
                {item.Transactions.length > 4 && (
                    <>
                        <button
                            onClick={() => handleToggleTransactionsModal(item)}
                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-0.5 border border-tm-gray-300 shadow-sm text-xs font-medium rounded text-tm-gray-700 bg-field hover:bg-tm-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse"
                        >
                            <span>Show all transactions ({item.Transactions.length})</span>
                        </button>
                        <br/>
                    </>
                )}
                <span className="font-semibold pl-2.5">Total</span> <br/>
            </div>
        )
    }

    const getDebitCreditFieldRender = (item, key) => {
        if (!Array.isArray(item?.Transactions)) {
            return null
        }

        const total = item.Transactions.reduce((memo, it) => memo + (+it[key]), 0)

        return (
            <div>
                {item.Transactions.map((it, i) => (
                    i < 5 && (
                        <>
                            <button
                                className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 border-b border-tm-gray-400 text-xs font-medium pointer-events-none"
                            >
                                <span>{genericMoneyFormatter(it[key])}</span>
                            </button>
                            <br/>
                        </>
                    )
                ))}
                {item.Transactions.length > 4 && (
                    <>
                        <EllipsisHorizontalIcon className={'w-5 h-5 m-auto'}/>
                        <br/>
                    </>
                )}
                <span className="font-bold pl-2.5">{genericMoneyFormatter(total)}</span> <br/>
            </div>
        )
    }

    return (
        <div>
            <PageHeader
                buttonLabel={translate('btn.create_new')}
                onButtonClick={handleToggleCreateModal}
                hasPerm={checkPerm(getResourceName(), CREATE_PERM)}
            >
                {checkPerm(getResourceName(), CREATE_PERM) &&(
                    <Tippy content={translate('text.ImportDataFromExcel')}>
                        <button
                            className="btn-icon flex ml-auto"
                            onClick={handleToggleImportExcelDataModal}
                        >
                            <DocumentArrowUpIcon className="w-5 h-5"/>
                        </button>
                    </Tippy>
                )}
            </PageHeader>

            <div className="flex">
                <ActiveFilters
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />
            </div>

            <TableCard addClass={'relative mt-4'}>
                <TableFilters
                    popoverClass="relative"
                    hideLimit
                    filterFields={queryFilterFields}
                    handleInputChange={handleFilterInputChange}
                    translate={translate}
                    onRefreshTable={fetchData}
                    isLoading={isLoading}
                />

                <ResourceTable
                    data={Array.isArray(data) ? data : []}
                    fields={getFields()}
                    verticalTableIsVisible={breakpoint.index <= 1}

                    translate={translate}
                    isLoading={isLoading || downloadIsLoading}

                    options={{
                        style: {
                            floatingActions: true
                        }
                    }}

                    limit={queryFilterFields.limit.value}

                    sort={query.sort}
                    sortBy={query.sortBy}
                    onSortChange={handleUpdateSort}

                    disableEdit
                    disableDelete
                    tableKey={getPrimaryKey()}
                    actions={[
                        {
                            tooltipText: () => translate('btn.copy'),
                            action: handleCopyJournalClick,
                            hasPerm: checkPerm(getResourceName(), CREATE_PERM),
                            icon: () => DocumentDuplicateIcon
                        },
                        {
                            tooltipText: () => translate('btn.previewDocument'),
                            visible: (item) => !!item.AttachPath,
                            action: handleOpenViewDocumentClick,
                            hasPerm: checkPerm(Resources.JournalDocuments, READ_PERM),
                            icon: () => DocumentMagnifyingGlassIcon
                        },
                        {
                            tooltipText: () => translate('btn.edit'),
                            hasPerm: checkPerm(getResourceName(), UPDATE_PERM),
                            visible: (item) => !editHidden(item),
                            action: handleToggleEditModal,
                            icon: () => PencilIcon
                        }, {
                            tooltipText: () => translate('btn.delete'),
                            visible: (item) => (+item?.JournalEntryTypeID === JOURNAL_ENTRY_TYPE_JOURNAL) && !item.Reconciliation && !deleteHidden(item),
                            action: handleToggleConfirmModal,
                            icon: () => TrashIcon,
                            hasPerm: checkPerm(getResourceName(), DELETE_PERM),
                        }
                    ]}
                />

                {/*Table footer*/}
                <TableCardFooter
                    show={!(!data.length && !resource.isLoading)}
                >
                    <Pagination
                        count={count}
                        isLoading={isLoading}
                        hideRowsPerPage={breakpoint.index <= 1}
                        handleQueryChange={
                            (name, value, currentPage) => name === 'offset'
                                ? handleUpdateOffset(value, currentPage)
                                : handleFilterInputChange(name, value)
                        }
                        pageOffset={query.offset}
                        queryFields={queryFilterFields}
                        translate={translate}
                    />
                </TableCardFooter>


                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    canCreate={false}
                    title={'No matching records found'}

                    className={'pb-12 pt-16 px-6'}
                    onClearFilterClick={handleClearFiltersClick}
                    clearFilterBtnLabel={translate('text.clear_all_filters')}
                    clearFilterText={translate('text.try_without_filters')}
                    filters={queryFilterFields}
                />
            </TableCard>

            {createModalOpen && (
                <JournalCreateDialog
                    dispatch={dispatch}
                    activeTab={getResourceName()}
                    translate={translate}
                    defaults={defaults}
                    handleToggleViewDocument={handleOpenViewDocumentClick}
                    query={getQuery()}
                    onClose={handleToggleCreateModal}
                />
            )}

            {editModalOpen && (
                <JournalCreateDialog
                    isCopyMode={isCopyMode}
                    dispatch={dispatch}
                    activeTab={getResourceName()}
                    selectedItem={selectedItem}
                    translate={translate}
                    defaults={defaults}
                    handleToggleViewDocument={handleOpenViewDocumentClick}
                    query={getQuery()}
                    onClose={handleToggleEditModal}
                />
            )}

            <ModalDefault
                show={previewModalOpen}
                widthClass={'max-w-7xl'}
                limitHeight={true}
                title={translate('text.FilePreview')}
                isLoading={downloadIsLoading}

                translate={translate}
                onButtonClick={downloadDocument}
                buttonLabel={translate('btn.Download')}
                closeButtonLabel={translate('btn.close')}
                onClose={handleOpenViewDocumentClick}
            >
                {previewModalOpen && (
                    <FileViewer
                        fileType={selectedItem?.AttachPath?.split('.')?.pop()}
                        filePath={Env.getApiUrl('api/' + Resources.JournalDocuments, Object.assign({}, {
                            [getPrimaryKey()]: selectedItem[getPrimaryKey()],
                            token: getJWT().access_token,
                        }))}
                        onError={(e) => {
                            console.log(e)
                        }}
                    />
                )}
            </ModalDefault>

            <ModalDefault
                show={transactionsModalOpen}
                widthClass={'max-w-5xl'}
                title={translate('text.Transactions')}
                limitHeight={true}
                translate={translate}
                closeButtonLabel={translate('btn.close')}
                onClose={handleToggleTransactionsModal}
            >

                <TableCard addClass={'p-5'}>
                    <ResourceTable
                        data={getProp(selectedItem, 'Transactions', []).filter((_, i) => i < (transactionDialogLimit + transactionsQuery.offset) && i >= transactionsQuery.offset)}
                        fields={getTransactionFields()}

                        translate={translate}

                        limit={transactionDialogLimit}

                        tfoot={{
                            Account: translate('text.total_all_pages'),
                            Debit: getProp(selectedItem, 'Transactions', []).reduce((memo, it) => memo + (+it.Debit), 0),
                            Credit: getProp(selectedItem, 'Transactions', []).reduce((memo, it) => memo + (+it.Credit), 0),
                        }}
                    />

                    <div
                        className="bg-inverse px-4 py-3 flex items-center justify-between ring-1 ring-black ring-opacity-5 sm:px-6 h-16 rounded-b-lg"
                    >
                        <Pagination
                            count={getProp(selectedItem, 'Transactions', []).length}
                            handleQueryChange={(name, value, currentPage) => {
                                setTransactionsQuery((prevState) => ({
                                    ...prevState,
                                    offset: value,
                                    paginationPage: currentPage
                                }))
                            }}
                            pageOffset={transactionsQuery.offset}
                            pageLimit={transactionDialogLimit}
                            translate={translate}
                        />
                    </div>
                </TableCard>
            </ModalDefault>

            <ModalConfirm
                title={translate('text.Confirm')}
                show={confirmModalOpen}
                text={translate('message.confirm_delete_journal')}
                onClose={handleToggleConfirmModal}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={translate('btn.cancel')}
                translate={translate}
                onConfirm={() => {
                    deleteData({
                        query: {
                            [getPrimaryKey()]: selectedItem[getPrimaryKey()]
                        },
                        piggyQuery: getQuery(),
                        errorMessage: true,
                        successMessage: translate('message.journal_deleted'),
                        resource: getResourceName(),
                        piggyResource: getResourceName(),
                    })
                    handleToggleConfirmModal()
                }}
            />

            <AccountReportDialog
                AccountID={selectedItem?.AccountID}
                AccountName={selectedItem?.Account}
                StartDate={queryFilterFields.StartDate?.value}
                EndDate={queryFilterFields.EndDate?.value}
                onClose={() => handleToggleReceiptModal(false)}
                show={receiptModalOpen}
                translate={translate}
                downloadExcelReports={(query) => downloadExcelReports(query)}
                getTransactions={(query) => {
                    dispatch(getSecondResource({
                        user: LocalStorage.get('user'),
                        resource: Resources.AccountingAccountReport,
                        query: query
                    }))
                }}
                isLoading={getProp(secondResource, 'isLoading', false)}
                transactions={getProp(secondResource, 'data', [])}
                thirdResource={thirdResource}
                dialogResource={dialogResource}
                dispatch={dispatch}
            />

            {importExcelDataModalOpen && (
                <JournalCreateDialog
                    dispatch={dispatch}
                    activeTab={getResourceName()}
                    translate={translate}
                    defaults={defaults}
                    handleToggleViewDocument={handleOpenViewDocumentClick}
                    query={getQuery()}
                    onClose={handleToggleImportExcelDataModal}
                    isImportExcel={true}
                    previewDocumentResource={previewDocumentResource}
                />
            )}
        </div>


    )

}

export default JournalTab
