import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    longTableColumn,
    mergeDeep,
    processFloat,
    resourceIsCreated, resourceIsDeleted,
    returnOffsetAndPagination,
    saveTableColumns,
    verifyFilterFields
} from '../../../common/util/util-helpers'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {CreditCardIcon, EyeIcon, TrashIcon, PencilIcon, CheckCircleIcon} from '@heroicons/react/24/outline'
// import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import moment from 'moment-timezone'
import {numberWithCommasToBack} from '../../../util/util-formaters'
// import ReviewLoanModal from "/review-loan-modal";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import {calculateNextLoanPaymentPerDay} from "../../../common/util/util-accounting";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Button from '../../../common/components/button'
import {READ_PERM, UPDATE_PERM} from "../../../common/util/util-consts";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import ReviewLoanModal from "../../accounting/expense/review-loan-modal";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import {XMarkIcon} from "@heroicons/react/20/solid";
import Page from "../../../common/components/layout/layout-components/page";

class RentalNotesReceivable extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_notes_receivable'

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'ContactID',

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            selectedItem: {},
            isTableFullScreen: false,

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            // Dialogs
            isMonthlyBreakdownOpen: false,
            isSendPaymentDialogOpen: false,
            isSendDownPaymentDialogOpen: false,
            createModalOpen: false,
            editModalOpen: false,
            confirmDialog: false,
            isDetailsDialogVisible: false,
            breakpoint: {}
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour)
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))

        this.selects = {
            ContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.FirstName + " " + item.LastName,
                    value: item.ContactID
                })
            },
            OrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName,
                    value: item.OrganizationID
                })
            },
            RentalID: {
                api: 'api/' + Resources.Rentals,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.AutoCounter,
                    value: item.RentalID
                })
            },
            IsActive: {"": "All", 0: "No", 1: "Yes"},
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (resourceIsCreated(this.props.resource, prevProps.resource)) {
            this.handleCloseSendPaymentDialog()
        }

        if (prevState.breakpoint !== this.state.breakpoint && this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }

        if (resourceIsDeleted(this.props.dialogResource, prevProps.dialogResource) && this.props.dialogResource.resource === "work-orders/rental/note-receivable/payment") {
            this.fetchData();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_delete_entry')}`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {
                            NoteReceivableID: item.NoteReceivableID
                        }),
                        errorMessage: true,
                        successMessage: this.props.translate(`text.note_receivable_deleted_succesfully`),
                        resource: this.getResource(),
                        piggyQuery: this.getQuery(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'Date'
            sort = 'DESC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    handleOpenSendPaymentDialog = (item = {}) => {
        this.setState({
            isSendPaymentDialogOpen: true,
            selectedItem: item
        })
    }

    handleCloseSendPaymentDialog = () => {
        this.setState({
            isSendPaymentDialogOpen: false
        })
    }

    handleShowMonthlyBreakdownClick = (item = {}) => {
        this.setState({
            isMonthlyBreakdownOpen: !this.state.isMonthlyBreakdownOpen,
            selectedItem: item
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({createModalOpen: !this.state.createModalOpen})
    }

    handleToggleEditModal = (item = {}) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleInputChange = (fields, name, value) => {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value);

        if (name === 'ChargeTo') {
            if (value === 0) {
                fieldsUpdate.OrganizationID.disabled = true;
                fieldsUpdate.OrganizationID.value = null;
                fieldsUpdate.ContactID.disabled = false;
                fieldsUpdate.ContactID.validate = ['empty'];
                fieldsUpdate.OrganizationID.validate = [''];

            }

            if (value === 1) {
                fieldsUpdate.ContactID.disabled = true;
                fieldsUpdate.ContactID.value = null;
                fieldsUpdate.ContactID.validate = [''];
                fieldsUpdate.OrganizationID.disabled = false;
                fieldsUpdate.OrganizationID.validate = ['empty'];
            }
        }

        return fieldsUpdate;
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input', 'showArchived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                OrganizationID: this.state.queryFilterFields.OrganizationID.value?.value,
                ContactID: this.state.queryFilterFields.ContactID.value?.value,
                IsActive: this.state.queryFilterFields.IsActive?.value ? ['IsActive', '=', this.state.queryFilterFields.IsActive?.value.toString()] : "",
                ...(this.props.ID && {RentalID: this.props.ID}),
            })
        }
    }

    getFields = (item) => {
        let fieldTemplates = {
            RentalID: new Field('RentalID', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-full',
                render: (it) => {
                    return (
                        <CellLink
                            to={`/rentals/` + it.RentalID}
                            openInNewTab={true}
                            disabled={!checkPerm(Resources.RentalInfo, UPDATE_PERM)}
                        >
                            <div>{it.Rental}</div>
                        </CellLink>
                    )
                }, hideDialog: !!this.props.ID
            }),
            LoadName: new Field('LoadName', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            Description: new Field('Description', '', [], false, 'text', {
                addContainerClass: 'col-span-full',
                render: (item) => {
                    return (
                        <div>{longTableColumn(item.Description)}</div>
                    )
                }
            }),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {
                addContainerClass: 'col-span-full'
            }),
            IsActive: new Field('IsActive', '', [''], false, 'checkbox', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            ChargeTo: new Field('ChargeTo', 0, [''], false, item ? 'hidden' : 'button-group', {
                data: {
                    0: 'Contact',
                    1: 'Organization'
                },
                hideTable: true
            }),
            ContactID: new Field('ContactID', '', !item ? ['empty'] : item?.ContactID ? ['empty'] : [''], false, (item?.ContactID || !item) ? 'select-search' : 'hidden', {addContainerClass: 'col-span-full'}),
            OrganizationID: new Field('OrganizationID', '', !item ? [''] : item?.OrganizationID ? ['empty'] : [''], !item, (item?.OrganizationID || !item) ? 'select-search' : 'hidden', {addContainerClass: 'col-span-full'}),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {
                addContainerClass: 'col-span-full'
            }),
            ResidualValue: new Field('ResidualValue', 0, ['empty'], false, 'money', {
                addContainerClass: 'col-span-full'
            }),
            LoanTerm: new Field('LoanTerm', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full',
                render: (item) => (
                    <div>{item.LoanTerm} months</div>
                )
            }),
            InterestRate: new Field('InterestRate', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full',
                render: (item) => (
                    <div>{parseFloat(item.InterestRate).toFixed(4)} % yearly</div>
                )
            }),
            LastPaidDate: new Field('LastPaidDate', '', [''], false, 'date', {
                addContainerClass: 'col-span-full', hideDialog: true
            }),
            LastPaidAmount: new Field('LastPaidAmount', '', [], false, 'money', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            PaymentCounter: new Field('PaymentCounter', '', [], false, 'integer', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            UsedAmount: new Field('UsedAmount', '', [], false, 'money', {
                addContainerClass: 'col-span-full',
                label: 'principal_left',
                hideDialog: true
            }),
            UsedExpenseAmount: new Field('UsedExpenseAmount', '', [], false, 'money', {
                addContainerClass: 'col-span-full',
                label: 'interest_left',
                hideDialog: true
            }),
        }
        fieldTemplates = fillFieldsFromData(fieldTemplates, item)
        return fieldTemplates
    }

    getEditFields = (item) => {
        let fieldTemplates = {
            LoadName: new Field('LoadName', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            Description: new Field('Description', '', [], false, 'textarea', {
                addContainerClass: 'col-span-full'
            }),
            IsActive: new Field('IsActive', '', [''], false, 'checkbox', {
                addContainerClass: 'col-span-full'
            })
        }
        fieldTemplates = fillFieldsFromData(fieldTemplates, item)
        return fieldTemplates
    }

    getQueryFilterFields = () => {
        const CheckboxCell = ({value}) => {
            return value ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
                : <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>
        }

        return {
            query: new Field('query', '', [''], false, 'search'),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {}, {isClearable: true}),

            ContactID: new Field('ContactID', '', [''], false, 'select-search', {}, {isClearable: true}),
            IsActive: new Field('IsActive', '', [''], false, 'select', {
            }, {addClass: "min-w-[9rem] form-control"}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    getInterestForFields = (fields, selectedItem) => {
        const principalLeft = selectedItem.UsedAmount// Principal amount left
        const yearlyInterestRate = selectedItem.InterestRate
        const numberOfDays =
            !fields.NotFixed.value ? 30 : (
                moment(selectedItem.LastPaidDate).diff(fields.Date.value, 'days')
            ) // Payment made after a certain number of days

        return calculateNextLoanPaymentPerDay(
            principalLeft,
            yearlyInterestRate,
            numberWithCommasToBack(fields?.AmountTotal?.value ?? 0),
            Math.abs(numberOfDays),
            moment().year());
    }

    getSendPaymentFields = (item = {}) => {
        let fields = {
            NotFixed: new Field('NotFixed', '', [], false, 'checkbox', {
                label: 'customPayment',
                addContainerClass: 'col-span-full'
            }),
            CustomPrincipalInterest: new Field('CustomPrincipalInterest', '0', [], true, 'checkbox', {
                addContainerClass: 'col-span-full'
            }),
            NoteReceivableID: new Field('NoteReceivableID', item.NoteReceivableID, [], true, 'hidden'),
            AmountTotal: new Field('AmountTotal', item.MonthlyPayment ? item.MonthlyPayment.toFixed(4) : 0, ['empty'], true, 'money', {addContainerClass: 'col-span-full'}),
            AmountPrincipal: new Field('AmountPrincipal', 0, [''], true, 'money', {addContainerClass: 'col-span-full'}),
            AmountInterest: new Field('AmountInterest', 0, [''], true, 'money', {addContainerClass: 'col-span-full'}),
            Date: new Field('Date', item.PaymentCounter === 0 ? item.StartDate : moment(item.LastPaidDate).add(1, 'month').format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], true, 'date', {addContainerClass: 'col-span-full sm:col-span-2'}),
            Description: new Field('Description', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }

        const result = this.getInterestForFields(fields, item)
        fields.AmountPrincipal.value = result.principalPayment
        fields.AmountInterest.value = result.interestPayment
        return fields
    }
    getResource = () => {
        return Resources.RentalNoteReceivable
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = getProp(resource, 'isLoading', false)

        return (
            <React.Fragment>
                <PageHeader
                    title={this.props.ID ? "" : translate("page_title.rental-notes-receivable")}
                />

                <div className="flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className={'flex ml-auto mt-2'}>
                        <div className={'flex mr-4'}>
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                        <Button
                            hasPerm={checkPerm(Resources.ExpenseLiabilities, CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => {
                                this.handleToggleCreateModal()
                            }}
                        >
                            {translate('btn.create_new')}
                        </Button>

                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        onFullScreenToggle={this.handleFullScreenToggle}
                        isTableFullScreen={this.state.isTableFullScreen}
                        isLoading={isLoading}

                        selects={this.selects}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.state.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        // onRowClick={this.handleShowMonthlyBreakdownClick} // handleToggleShowDetails
                        saveTableOptions={this.setOptions}

                        actions={[
                            {
                                action: this.handleShowMonthlyBreakdownClick,
                                icon: EyeIcon,
                                visible: (item) => item.Amount > 0 && item.InterestRate >= 0 && item.LoanTerm > 0,
                                hasPerm: checkPerm(Resources.RentalNoteReceivable, READ_PERM),
                                title: translate('text.show_details'),
                            },
                            {
                                action: this.handleOpenSendPaymentDialog,
                                icon: CreditCardIcon,
                                visible: (item) => true,
                                hasPerm: checkPerm(Resources.RentalNoteReceivablePayment, CREATE_PERM),
                                title: translate('text.send_payment'),
                            },
                            {
                                action: this.handleToggleEditModal,
                                icon: PencilIcon, // make this a function
                                visible: (it) => true,
                                hasPerm: checkPerm(this.getResource(), UPDATE_PERM),
                                label: false, // make this a function
                                title: translate('btn.edit'),
                                order: 30
                            },
                            {
                                action: this.archiveResource,
                                icon: TrashIcon, // make this a function
                                visible: (it) => !it.ArchivedDate && it.PaymentCounter === 0,
                                hasPerm: checkPerm(this.getResource(), DELETE_PERM),
                                label: false, // make this a function
                                title: translate('btn.delete'),
                                order: 40
                            }
                        ]}

                        tableKey={'NoteReceivableID'}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            hideRowsPerPage={this.state.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <ReviewLoanModal
                    show={this.state.isMonthlyBreakdownOpen}
                    selectedItem={this.state.selectedItem}
                    cachedChangedFields={this.getFields(this.state.selectedItem)}
                    onClose={this.handleShowMonthlyBreakdownClick}
                    translate={translate}
                    isNote={true}
                />

                <ModalSaveResource
                    show={this.state.isSendPaymentDialogOpen}
                    title={translate('text.send_payment')}
                    widthClass="max-w-3xl"
                    onClose={this.handleCloseSendPaymentDialog}
                    fields={this.getSendPaymentFields(this.state.selectedItem)}
                    isLoading={isLoading}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                resource: Resources.RentalNoteReceivablePayment,
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: this.props.translate('text.payment_sent_successfully'),
                            }))
                        }
                    }}
                    metadata={this.selects}
                    translate={translate}

                    handleInputChange={(fields, name, value,) => {
                        let newFields = FieldsManager.updateField(fields, name, value)
                        if (name === 'NotFixed') {
                            newFields.AmountTotal.disabled = !value
                            newFields.Date.disabled = !value
                            newFields.CustomPrincipalInterest.disabled = !value
                            if (!value) {
                                newFields.CustomPrincipalInterest.value = '0'
                                newFields.AmountPrincipal.disabled = !value
                                newFields.AmountInterest.disabled = !value
                            }
                        }
                        if (name === 'CustomPrincipalInterest') {
                            newFields.AmountTotal.disabled = value
                            newFields.AmountPrincipal.disabled = !value
                            newFields.AmountInterest.disabled = !value
                        }
                        if (!this.state.selectedItem) {
                            return null
                        }
                        if (
                            fields.CustomPrincipalInterest.value != "1"
                        ) {
                            const result = this.getInterestForFields(newFields, this.state.selectedItem)

                            newFields.AmountPrincipal.value = result.principalPayment
                            newFields.AmountInterest.value = result.interestPayment
                        } else {
                            newFields.AmountTotal.value = (parseFloat(processFloat(
                                    newFields.AmountPrincipal.value
                                )) +
                                parseFloat(processFloat(
                                        newFields.AmountInterest.value
                                ))).toFixed(2)
                        }

                        return newFields
                    }}
                />

                <ModalSaveResource
                    title={translate('text.Create')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-6"
                    show={this.state.createModalOpen}
                    onClose={() => this.handleToggleCreateModal()}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            if (this.props.ID) {
                                params.RentalID = this.props.ID
                            }

                            params.IsActive = 1

                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: 'Note created',
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.selects}
                    handleInputChange={this.handleInputChange}
                />

                <ModalSaveResource
                    title={translate('text.Edit')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-6"
                    show={this.state.editModalOpen}
                    onClose={() => this.handleToggleEditModal()}
                    fields={this.getEditFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.NoteReceivableID = this.state.selectedItem.NoteReceivableID

                            if (this.props.ID) {
                                params.RentalID = this.props.ID
                            } else {
                                params.RentalID = this.state.selectedItem.RentalID
                            }

                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: 'Note updated.',
                            }))
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.selects}
                    handleInputChange={this.handleInputChange}
                />

                <ModalConfirm
                    title={translate('text.confirm')}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />
            </React.Fragment>
        )
    }
}

export default connect(state => state)(RentalNotesReceivable)
