import React, {Component} from 'react'
import {connect} from 'react-redux'
import Layout from "../../../common/components/layout";
import RentalNotesReceivable from "./index";
import Page from "../../../common/components/layout/layout-components/page";

class RentalNotesReceivableList extends Component {

    /** Lifecycle
     ================================================================= */

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        })
    }


    /** Render
     ================================================================= */
    render() {
        const {resource, translate, location, dispatch} = this.props

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <RentalNotesReceivable
                        location={location}
                        dispatch={dispatch}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(RentalNotesReceivableList)
